import { View } from 'react-native';
import { s, use } from '../../../../root';
import { Textline, Button } from '../../../../modules/basics/components';
import { ErrorTime } from '../../../../modules/error/components';
import Link from './Link.js';

const Page = ({ networkMessage, authMessage, message, children, hasHome }) => {

    const c = use.useColor();

    const style = {
        error:          [s.space.p16, s.col_cXcY, s.XYc, s.radius.r16, c.mainN1],
        description:    [{lineHeight:20}],
        buttons:        [s.row_cXcY, s.space.mY16],
    };

    return (
        <View style={style.error}>
            <Textline style={style.description} color={c.onMainN1} WL text={networkMessage}/>
            <Textline style={style.description} color={c.onMainN1} WL text={authMessage}/>
            <Textline style={style.description} color={c.onMainN1} WL text={message}/>
            <View style={style.buttons}>
                {hasHome ? <Link linkTo='/' text='WL_error_home'/> : null}
                {children}
            </View>
            <ErrorTime/>
        </View>
    );
};
export default Page;