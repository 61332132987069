import { Pressable } from 'react-native';
import { useNavigate } from '../../../platform/router';

const Link = ({ style=[], color, linkTo, children, isDisabled, onPress=()=>{} }) => {    

    const navigate = useNavigate();

    const onAction = () => {
        onPress();
        if(!isDisabled) navigate(linkTo)
    }

    return ( 
        <Pressable style={[...style, color]} onPress={onAction}>
            {children}
        </Pressable>
    );
};
export default Link;