import { useRecoilState } from 'recoil';
import { v4 as getUUID } from 'uuid';
import { store } from '../../../../root';
import { PopoverItem } from '../../../../app/book/suits.js';

const Arrange = ({ name, svg, main, second, isLine }) => {

    const [modals, setModals] = useRecoilState(store.modal.modals)
    const arrange = () => {
        const id = getUUID();
        setModals([...modals, { id, name }]);
    };
    return <PopoverItem onPress={arrange} svg={svg} main={main} second={second} isLine={isLine}/>
};
export default Arrange;