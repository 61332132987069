import { abs, api } from '../../../../root';
import { SelectorElement } from '../../../../app/book/suits.js';
import { ThrowErrorModal } from '../../../../modules/error/throw';

const Book = ({ modalId, params, navigate, book }) => {

    const onSelect = () => ()=>{};
    const onOpen = () => navigate({...params, typename:'Section'});
    
    const fetchItem = api.book.useFetch(book.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorModal name='bookSelector' data={{data:book.item, params, modalId}}/>

    const { typename } = params;
    const svg = abs.book.svg({typename});

    return (
        <SelectorElement 
            svg={svg} text={item.title} 
            onSelect={onSelect} onOpen={onOpen}
        />
    );
}; 
export default Book;