import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { abs, api, use, store } from '../../../../root';
import { AgentButton } from '../../../../app/book/suits.js'

const Update = ({ operationId, modalId }) => {

    const closeModal = use.modal.useCloseModal();
    const setPopover = useSetRecoilState(store.popover.popover);

    const {typename, itemId} = store.book.operation[operationId];
    const setItem = useSetRecoilState(api.book[typename](['item', itemId]))

    useEffect(()=> {

        const update = () => {
    
            const { data } = store.book.operation[operationId];
            const newItem = abs.book.clone[`${typename}Item`](data);
            store.book.cache.item[itemId] = newItem
            setItem(newItem);
        
            if (modalId) closeModal(modalId)
            setPopover({});
    
            abs.book.operation.remove(operationId);
        
        };
        update();
    });

    return <AgentButton label='WL_book_operation_ok'/>

};
export default Update;