import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const createPage = gql`

    ${fragment.page.hook}
    ${fragment.page.item}

    mutation createPage ($input: newElement!) {
        createPage(input: $input) {
            ...pageHookFields
            item {
                ...pageItemFields
            }       
        }
    }
`;

export default createPage;
