import { useRecoilState } from 'recoil';
import { View } from 'react-native';
import { s, use, store, alerts } from '../../../root';
import Header from './Header.js';

const Alert = () => {

    const c = use.useColor();

    const [alert, setAlert] = useRecoilState(store.alert.alert);
    const setMargins = use.useMargins();
    const margins = setMargins();

    const { name, header={}, isCloseDisabled } = alert;  
    const close = () => isCloseDisabled ? ()=>{} : setAlert({});

    const style = {
        overlay:    [s.row_eXsY, s.overlay, {zIndex:4}, c.overlayN1],
        modal:      [s.col, s.XYr, s.space.mY64, margins],
        layout:     [s.col, s.Yc],
        body:       [s.col, s.radius.b8, c.mainN1],
    };

    if (!name) return null
    return (
        <View style={style.overlay}>
            <View style={style.modal}>
                <View style={style.layout}>
                    <Header title={header.title} svg={header.svg} isDisabled={isCloseDisabled} close={close}/>
                    <View style={style.body}>{alerts[name]}</View>
                </View>
            </View>
        </View>
    );
}; 
export default Alert;