import { useEffect, useState } from 'react';
import { abs, api, store } from '../../../../root';
import { ThrowErrorModal, ThrowErrorRemove } from '../../../../modules/error/throw';
import { AgentInline, AgentButton } from '../../../../app/book/suits.js';
import { Cleanup } from '../../../../app/book/agents.js';

const Reorder = (props) => {

    console.log('inside reorder ', props)

    const { operationId, isRemove, errorType } = props;

    const { list } = store.book.operation[operationId];
    const [refetchAtom, setAtom] = api.book.useRefetch(list);

    const [isCleanup, setIsCleanup] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(()=> {
        const refetch = async () => {
            try {           
                const newAtomOne = await refetchAtom();
                if (newAtomOne==='error') abs.errorThrow('if (newAtomOne==="error")')
                else setAtom(newAtomOne);
                setIsCleanup(true);
            } catch (error) {
                abs.errorCatch('App > book > agents > refetch > Reorder', error)
                setIsError(true)
            };
        };
        if (!isCleanup) refetch();
    });

    if (isError) return isRemove ? <ThrowErrorRemove name='bookReorder' data={props}/> : <ThrowErrorModal name='bookReorder' data={props}/>
    if (isCleanup) return <Cleanup {...props}/>
    return isRemove && !errorType ? <AgentInline label='WL_book_removed'/> : <AgentButton label='WL_book_operation_ok'/>

};
export default Reorder;