import { useRecoilValue } from 'recoil';
import { StyleSheet } from 'react-native';
import { store } from '../root';
import * as c from './colors';

const useColor = () => {

    const isDark = useRecoilValue(store.layout.isDark);

    const color = StyleSheet.create({
    
        // BACKGROUNDS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

        // band (static)
        bandN1: { backgroundColor: c.grey128B(1) },

        // bar (static)
        barN1: { backgroundColor: c.grey128B(1) },

        // overlay (static)
        overlayN1: { backgroundColor: c.grey240b(0.4, isDark) },

        // main
        mainN1: { backgroundColor: c.grey192w(1, isDark) },
        mainN1_o: { backgroundColor: c.grey192w(0.6, isDark) },

        // divider
        dividerN1: { backgroundColor: c.grey160w(1, isDark) },
        dividerN2: { backgroundColor: c.grey128w(1, isDark) },

        // button
        buttonN1: { backgroundColor: c.grey(1, isDark) },
        buttonN1_d: { backgroundColor: c.grey(0.3, isDark) },
        buttonC1: { backgroundColor: c.blue(1, isDark) },
        buttonC1_d: { backgroundColor: c.blue(0.3, isDark) },
        buttonR1: { backgroundColor: c.red(1, isDark) },
        buttonR1_d: { backgroundColor: c.red(0.3, isDark) },

        // item with popover title and open/closed
        popoverN0: { backgroundColor: c.grey160w(1, isDark) },
        popoverN1: { backgroundColor: c.grey192w(1, isDark) },
        popoverN1_t: { backgroundColor: c.grey176w(1, isDark) },
        popoverC1: { backgroundColor: c.blue160w(1, isDark) },

        // toolbar 
        toolbarN1: { backgroundColor: c.grey176w(1, isDark) },
        toolbarN2: { backgroundColor: c.grey160w(1, isDark) },
        toolbarC1: { backgroundColor: c.blue160w(1, isDark) },

        // selector 
        selectorN1: { backgroundColor: c.grey160w(1, isDark) },
        selectorN1_d: { backgroundColor: c.grey160w(0.3, isDark) },
        selectorC1: { backgroundColor: c.blue(1, isDark) },
        selectorC1_d: { backgroundColor: c.blue(0.3, isDark) },
        selectorC2: { backgroundColor: c.blue160w(1, isDark) },
        selectorC2_d: { backgroundColor: c.blue160w(0.6, isDark) },

        // slider
        sliderN1: { backgroundColor: c.grey(1, isDark) },
        sliderC1: { backgroundColor: c.blue(1, isDark) },

        // FILL <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

        // band (static)
        onBandN1: { color: c.grey192W(1) },
        onBandN1_d: { color: c.grey192W(0.3) },
        onBandN1_bg: { backgroundColor: c.grey192W(1) },
        onBandN1_bg_d: { backgroundColor: c.grey192W(0.3) },
        onBandC1: { color: c.blue016W(1) },
        onBandC1_d: { color: c.blue016W(0.3) },

        // bar (static)
        onBarN1: { color: c.grey192W(1) },
        onBarN1_d: { color: c.grey192W(0.3) },
        onBarC1: { color: c.blue016W(1) },
        onBarC1_d: { color: c.blue016W(0.3) },

        // main
        onMainN1: { color: c.grey128b(1, isDark) },
        onMainN1_d: { color: c.grey128b(0.3, isDark) },
        onMainN1_1: { color: c.grey(1, isDark) },
        onMainC1: { color: c.blue(1, isDark) },
        onMainC1_d: { color: c.blue(0.3, isDark) },
        onMainC1_1: { color: c.blue080w(1, isDark) },

        // button
        onButtonN1: { color: c.grey192w(1) },
        onButtonN1_d: { color: c.grey192w(0.3) },

        // BORDER <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

        line: {

            // logo
            logoN1: { borderColor: c.grey128b(1) },
            logoC1: { borderColor: c.blue(1) },

            //input
            inputN1: { borderColor: c.grey160w(1) },
            inputN1_d: { borderColor: c.grey160w(0.3) },
            inputC1: { borderColor: c.blue(1) },
        },
    });
    return color;
}
export default useColor;