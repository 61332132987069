import { View } from 'react-native';
import { s } from '../../../../root';

const Item = ({ width, isFlip }) => {

    const c = s.colors;

    const row = isFlip ? s.rowR_sXcY : s.row_sXcY;

    const style = {
        line:       [row, s.Y40, s.space.mB2],
        icon:       [s.XY48, s.row_cXcY],
        circle:     [s.XY20, s.radius.r16, c.mainN1],
        container:  [row, s.Y40, s.Xr],
        content:    [s.Y16, { width }, c.mainN1 ],
    };
    
    return ( 
        <View style={style.line}>
            <View style={style.icon}>
                <View style={style.circle}></View>
            </View>
            <View style={style.container}>
                <View style={style.content}></View>
            </View>
        </View>
    );
};
export default Item;