import { View } from 'react-native';
import { s, use } from '../../../root';
import { Textline, Button, Logo, Icon } from '../../../modules/basics/components';
import guest from '../../../../assets/guest.png';
import user from '../../../../assets/user.png';

const Header = ({ action, close, username, isGuest }) => {

    const c = use.useColor();

    const onPress = () => {
        action()
        close()
    };

    const buttonText = isGuest ? 'WL_user_login' : 'WL_user_logout';
    const logo = isGuest ? guest : user;
    const color = isGuest ? c.line.logoN1 : c.line.logoC1;

    const style = {
        userbar:    [s.col],
        closer:     [s.row_eXcY, s.Y48],
        icon:       [s.XY48, s.radius.r32, c.bandN1],
        header:     [s.row_cXcY, c.bandN1],
        side:       [s.row_cXsY, s.X64, s.Yr],
        middle:     [s.col_cXcY, s.Xr, s.space.pY16],
        bigLogo:    [s.XY64],
        username:   [s.space.mY8, {fontWeight:'bold'}],
        button:     [s.button, s.Y32],
        text:       [{fontWeight:'bold'}],
    };

    return (
        <View style={style.userbar}>
            <View style={style.header}>
                <View style={style.side}></View>
                <View style={style.middle}>
                    <Logo style={style.bigLogo} color={color} src={logo}/>
                    <Textline style={style.username} color={c.onBandN1} text={username}/>
                    <Button style={style.button} color={c.buttonC1} onPress={onPress}>
                        <Textline style={style.text} color={c.onButtonN1} WL text={buttonText}/>
                    </Button>
                </View>
                <View style={style.side}>
                    <Icon style={style.icon} color={c.onBandN1} onPress={close} svg='confirmFalse_16'/>
                </View>
            </View>
        </View>
    );
}; 
export default Header;