import { v4 as getUUID } from 'uuid';
import { abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { PageElement } from '../../../../app/book/suits';

const Block = ({ svg, typename, params, block, blockPosition }) => {

    const fetchItem = api.book.useFetch(block.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorMain name='bookPage' data={block.item}/>

    const id = getUUID();
    const popover = abs.book.popover({
        header: {svg, title:item.value},
        popover: {id, typename, block, blockPosition, dataPosition:0, ...params, item},
        options: {name:'optionsBlock'},
        adder: {name:'adderBlock'},
    });

    return <PageElement id={id} svg={svg} text={item.value} options={popover.options} adder={popover.adder}/>
}; 
export default Block;