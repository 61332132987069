import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateSectionValue = gql`

    ${fragment.section.item}

    mutation updateSectionValue ($input: editValue!) {
        updateSectionValue(input: $input) {
            ...sectionItemFields
        }
    }
`;

export default updateSectionValue;