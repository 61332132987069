import { s, use } from '../../../../root';
import { Textline, Button as BasicButton } from '../../../../modules/basics/components';

const Button = ({ isMain, text, onPress=()=>{} }) => {

    const c = use.useColor();

    const style = {
        button:         [s.button, s.Y32],
        text:           [{fontWeight:'bold'}],
    };

    return (
        <BasicButton style={style.button} color={isMain ? c.buttonC1 : c.buttonN1} onPress={onPress}>
            <Textline style={style.text} color={c.onButtonN1} WL text={text}/>
        </BasicButton>
    );
};
export default Button;