import { Image } from 'expo-image';
import { View } from 'react-native';

const Picture = ({ src, placeholder='', style={} }) => {

    console.log(
        'XsXsX inside Picture',
        '\n src ', src,
        '\n placeholder ', placeholder,
        '\n style ', style
    );

    return (
        <View style={style}>
            <Image
                style={style}
                placeholder={placeholder ? placeholder:null}
                transition={{effect:'cross-dissolve', duration:500}}
                source={src}
                contentFit="cover"
            />
        </View>
  );
};
export default Picture;

