import gql from './gql/index.js';
import { apollo } from '../../../../clients';
import { abs, store } from '../../../../root';

const writeOperation = async (operationId) => {

    const { typename, operation, attribute, input } = store.book.operation[operationId];
    const operationName = operation+typename+(attribute||'');

    console.log(
        '\n writeOperation', 
        '\n operationId ', operationId, 
        '\n operationName ', operationName, 
        '\n store.book.operation ', store.book.operation, 
        '\n store.book.operation[operationId] ', store.book.operation[operationId], 
        '\n\n'
    );

    try {

        // check the connection and the token
        if (!(await abs.isNetwork())) abs.errorThrow('if (!(await abs.isNetwork()))');
        if (!(await abs.isAuth())) abs.errorThrow('if (!(await abs.isAuth()))');

        // launch the operation
        const response = await apollo.mutate ({mutation: gql[operationName], variables: {input}});
        store.book.operation[operationId].data = response.data[operationName];

        console.log(
            '\n writeOperation return', 
            '\n operationId ', operationId, 
            '\n operationName ', operationName, 
            '\n response.data[operationName] ', response.data[operationName], 
            '\n\n'
        );

        return 'success';
        
    } catch (error) {return abs.errorCatch(`App/app/book/api/writeOperation ${operationName}`, error)}
};
export default writeOperation;