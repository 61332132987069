import * as AuthSession from 'expo-auth-session';
import jwt_decode from "jwt-decode";
import { abs, store } from '../../../../root';
import * as localStorage from '../../../../platform/localStorage';

const fetchUser = async () => {

    console.log(' XXX inside fetchUser')

    try {

        const accessToken = await localStorage.get('accessToken');
        const refreshToken = await localStorage.get('refreshToken');

        if (accessToken) {

            const date = (Date.now()/1000) | 0;
            const accessTokenDecoded = jwt_decode(accessToken);
            const accessTokenTimeLeft = accessTokenDecoded.exp - date;

            if (accessTokenTimeLeft > 20) {

                store.user.accessToken = accessToken;
                store.user.refreshToken = refreshToken;

                return ({
                    id: accessTokenDecoded.sub,
                    email: accessTokenDecoded.email,
                })
            };
        };

        if (refreshToken) {

            const date = (Date.now()/1000) | 0;
            const refreshTokenDecoded = jwt_decode(refreshToken);
            const refreshTokenTimeLeft = refreshTokenDecoded.exp - date;

            if (refreshTokenTimeLeft > 20) {
                
                if (!(await abs.isNetwork())) abs.errorThrow('if (!(await abs.isNetwork()))');
                const newToken = await AuthSession.refreshAsync({refreshToken, ...store.user.params}, store.user.discovery);

                await localStorage.set('accessToken', newToken.accessToken);
                await localStorage.set('refreshToken', newToken.refreshToken);

                const newAccessTokenDecoded = jwt_decode(newToken.accessToken);

                store.user.accessToken = newToken.accessToken;
                store.user.refreshToken = newToken.refreshToken;
                
                return ({
                    id: newAccessTokenDecoded?.sub,
                    email: newAccessTokenDecoded?.email,
                });

            };
        };

        await localStorage.remove('accessToken');
        await localStorage.remove('refreshToken');

        store.user.accessToken = ''
        store.user.refreshToken = '';

        return 'guest';

    } catch (error) {
        abs.errorCatch('App/modules/user/api/fetch/fetchUser', error);
        return 'guest'
    };
};

export default fetchUser;