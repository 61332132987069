import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateDataType = gql`

    ${fragment.data.item}

    mutation updateDataType ($input: editType!) {
        updateDataType(input: $input) {
            ...dataItemFields
        }
    }
`;

export default updateDataType;