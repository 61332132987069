import { Suspense, useState } from 'react';
import { ThrowErrorModal } from '../../../../modules/error/throw';
import { AgentButton } from '../../../../app/book/suits.js';
import { Write, AgentRefetch } from '../../../../app/book/agents.js';

const AgentWrite = ({ operationId, action, isDisabled, refetch }) => {

    const [response, setResponse] = useState();
    const isWritten = response==='success';
  
    if (response==='error') return <ThrowErrorModal name='bookWrite' data={{operationId, refetch}}/>
    return (<>
        { operationId 
            ? <Suspense fallback={<AgentButton label='WL_book_loading'/>}>
                {isWritten 
                ? <AgentRefetch refetch={refetch}/>
                : <Write operationId={operationId} setResponse={setResponse}/>
                }
              </Suspense>
            : <AgentButton label='WL_book_save_and_close' action={action} isDisabled={isDisabled}/>
        }
    </>);
}; 
export default AgentWrite;