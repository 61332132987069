import { Open, Edit, Arrange, Gather, Expand, Remove } from '../../app/book/actions';

// --------------------------------------------------------- BOOK LIST 

export const optionsTitleBookList = <Edit name='newBook' main='WL_book_create' second='WL_book_a_new_Book' svg='plus_14_line_24'/>
export const adderTitleBookList = <Edit name='newBook' main='WL_book_create' second='WL_book_a_new_Book' svg='plus_14_line_24'/>

// --------------------------------------------------------- BOOK COVER

export const optionsTitleBookCover = <Remove name='removeBook' main='WL_book_remove' second='WL_book_this_Book' svg='trash_16' isNavigate/>
export const adderTitleBookCover = <>
        <Open main='WL_book_open' second='WL_book_the_BookIndex' isLine/>
        <Edit name='editBook' main='WL_book_edit' second='WL_book_this_Book' svg='edit_16' isLine/>
        <Edit name='newBook' main='WL_book_create' second='WL_book_a_new_Book' svg='plus_14_line_24'/>
    </>

// --------------------------------------------------------- BOOK INDEX

export const optionsTitleBookIndex = <Remove name='removeBook' main='WL_book_remove' second='WL_book_this_Book' svg='trash_16' isNavigate/>
export const adderTitleBookIndex = <Edit name='newBook' main='WL_book_create' second='WL_book_a_new_Book' svg='plus_14_line_24' isNavigate/>
export const optionsBookTitle = <>
        <Open main='WL_book_open' second='WL_book_the_BookCover' isLine/>
        <Edit name='editBook' main='WL_book_edit' second='WL_book_this_Book' svg='edit_16'/>
    </>

// --------------------------------------------------------- BOOK PAGE

export const optionsTitleBookPage = <Remove name='removePage' main='WL_book_remove' second='WL_book_this_Page' svg='trash_16' isNavigate/>
export const adderTitleBookPage = <>
        <Edit name='newPage' main='WL_book_create' second='WL_book_a_new_Page' svg='plus_14_line_24' isNavigate/>
        <Gather name='hookPageIn' main='WL_book_hook' second='WL_book_a_Page' svg='hook_16' isNavigate/>
        <Gather name='movePageIn' main='WL_book_move' second='WL_book_a_Page' svg='move_16' isNavigate/>
        <Gather name='copyPageIn' main='WL_book_copy' second='WL_book_a_Page' svg='duplicate_16' isNavigate/>
    </>
export const optionsPageTitle = <>
        <Edit name='editPage' main='WL_book_edit' second='WL_book_this_Page' svg='edit_16' isLine/>
        <Arrange name='hookPage' main='WL_book_hook' second='WL_book_this_Page' svg='hook_16'/>
        <Arrange name='movePage' main='WL_book_move' second='WL_book_this_Page' svg='move_16'/>
        <Arrange name='copyPage' main='WL_book_copy' second='WL_book_this_Page' svg='duplicate_16'/>
    </>


// --------------------------------------------------------- OPTIONS

export const optionsBookItem = <>
        <Open main='WL_book_open' second='WL_book_the_Book' isLine/>
        <Remove name='removeBook' main='WL_book_remove' second='WL_book_this_Book' svg='trash_16'/>
    </>
export const optionsBook = <>
        <Open main='WL_book_open' second='WL_book_the_BookCover' isLine/>
        <Edit name='editBook' main='WL_book_edit' second='WL_book_this_Book' svg='edit_16' isLine/>
        <Remove name='removeBook' main='WL_book_remove' second='WL_book_this_Book' svg='trash_16'/>
    </>
export const optionsSection = <>
        <Edit name='editSection' main='WL_book_edit' second='WL_book_this_Section' svg='edit_16' isLine/>
        <Arrange name='hookSection' main='WL_book_hook' second='WL_book_this_Section' svg='hook_16'/>
        <Arrange name='moveSection' main='WL_book_move' second='WL_book_this_Section' svg='move_16'/>
        <Arrange name='copySection' main='WL_book_copy' second='WL_book_this_Section' svg='duplicate_16' isLine/>
        <Remove name='removeSection' main='WL_book_remove' second='WL_book_this_Section' svg='trash_16'/>
    </>
export const optionsPage= <>
        <Open main='WL_book_open' second='WL_book_the_BookPage'/>
        <Expand  isLine/>
        <Edit name='editPage' main='WL_book_edit' second='WL_book_this_Page' svg='edit_16' isLine/>
        <Arrange name='hookPage' main='WL_book_hook' second='WL_book_this_Page' svg='hook_16'/>
        <Arrange name='movePage' main='WL_book_move' second='WL_book_this_Page' svg='move_16'/>
        <Arrange name='copyPage' main='WL_book_copy' second='WL_book_this_Page' svg='duplicate_16' isLine/>
        <Remove name='removePage' main='WL_book_remove' second='WL_book_this_Page' svg='trash_16'/>
    </>
export const optionsBlock = <>
        <Edit name='editBlock' main='WL_book_edit' second='WL_book_this_Block' svg='edit_16' isLine/>
        <Arrange name='hookBlock' main='WL_book_hook' second='WL_book_this_Block' svg='hook_16'/>
        <Arrange name='moveBlock' main='WL_book_move' second='WL_book_this_Block' svg='move_16'/>
        <Arrange name='copyBlock' main='WL_book_copy' second='WL_book_this_Block' svg='duplicate_16' isLine/>
        <Remove name='removeBlock' main='WL_book_remove' second='WL_book_this_Block' svg='trash_16'/>
    </>
export const optionsData = <>
        <Edit name='editData' main='WL_book_edit' second='WL_book_this_Data' svg='edit_16' isLine/>
        <Arrange name='hookData' main='WL_book_hook' second='WL_book_this_Data' svg='hook_16'/>
        <Arrange name='moveData' main='WL_book_move' second='WL_book_this_Data' svg='move_16'/>
        <Arrange name='copyData' main='WL_book_copy' second='WL_book_this_Data' svg='duplicate_16' isLine/>
        <Remove name='removeData' main='WL_book_remove' second='WL_book_this_Data' svg='trash_16'/>
    </>


// --------------------------------------------------------- ADDER

export const adderBook = <Edit name='newBook' main='WL_book_create' second='WL_book_a_new_Book' svg='plus_14_line_24'/>
export const adderBookSection = <>
        <Edit name='newBook' main='WL_book_create' second='WL_book_a_new_Book' svg='plus_14_line_24' isLine/>
        <Edit name='newSection' main='WL_book_create' second='WL_book_a_new_Section' svg='plus_14_line_24' isLine/>
        <Gather name='hookSectionIn' main='WL_book_hook' second='WL_book_a_Section' svg='hook_16'/>
        <Gather name='moveSectionIn' main='WL_book_move' second='WL_book_a_Section' svg='move_16'/>
        <Gather name='copySectionIn' main='WL_book_copy' second='WL_book_a_Section' svg='duplicate_16'/>
    </>
export const adderSection = <>
        <Edit name='newSection' main='WL_book_create' second='WL_book_a_new_Section' svg='plus_14_line_24'/>
        <Gather name='hookSectionIn' main='WL_book_hook' second='WL_book_a_Section' svg='hook_16'/>
        <Gather name='moveSectionIn' main='WL_book_move' second='WL_book_a_Section' svg='move_16'/>
        <Gather name='copySectionIn' main='WL_book_copy' second='WL_book_a_Section' svg='duplicate_16'/>
    </>
export const adderSectionPage = <>
        <Edit name='newSection' main='WL_book_create' second='WL_book_a_new_Section' svg='plus_14_line_24'/>
        <Gather name='hookSectionIn' main='WL_book_hook' second='WL_book_a_Section' svg='hook_16'/>
        <Gather name='moveSectionIn' main='WL_book_move' second='WL_book_a_Section' svg='move_16'/>
        <Gather name='copySectionIn' main='WL_book_copy' second='WL_book_a_Section' svg='duplicate_16' isLine/>
        <Edit name='newPage' main='WL_book_create' second='WL_book_a_new_Page' svg='plus_14_line_24'/>
        <Gather name='hookPageIn' main='WL_book_hook' second='WL_book_a_Page' svg='hook_16'/>
        <Gather name='movePageIn' main='WL_book_move' second='WL_book_a_Page' svg='move_16'/>
        <Gather name='copyPageIn' main='WL_book_copy' second='WL_book_a_Page' svg='duplicate_16'/>
    </>
export const adderPage = <>
        <Edit name='newPage' main='WL_book_create' second='WL_book_a_new_Page' svg='plus_14_line_24'/>
        <Gather name='hookPageIn' main='WL_book_hook' second='WL_book_a_Page' svg='hook_16'/>
        <Gather name='movePageIn' main='WL_book_move' second='WL_book_a_Page' svg='move_16'/>
        <Gather name='copyPageIn' main='WL_book_copy' second='WL_book_a_Page' svg='duplicate_16'/>
    </>
export const adderPageBlock = <>
        <Edit name='newPage' main='WL_book_create' second='WL_book_a_new_Page' svg='plus_14_line_24'/>
        <Gather name='hookPageIn' main='WL_book_hook' second='WL_book_a_Page' svg='hook_16'/>
        <Gather name='movePageIn' main='WL_book_move' second='WL_book_a_Page' svg='move_16'/>
        <Gather name='copyPageIn' main='WL_book_copy' second='WL_book_a_Page' svg='duplicate_16' isLine/>
        <Edit name='newBlock' main='WL_book_create' second='WL_book_a_new_Block' svg='plus_14_line_24'/>
        <Gather name='hookBlockIn' main='WL_book_hook' second='WL_book_a_Block' svg='hook_16'/>
        <Gather name='moveBlockIn' main='WL_book_move' second='WL_book_a_Block' svg='move_16'/>
        <Gather name='copyBlockIn' main='WL_book_copy' second='WL_book_a_Block' svg='duplicate_16'/>
    </>
export const adderBlock = <>
        <Edit name='newBlock' main='WL_book_create' second='WL_book_a_new_Block' svg='plus_14_line_24'/>
        <Gather name='hookBlockIn' main='WL_book_hook' second='WL_book_a_Block' svg='hook_16'/>
        <Gather name='moveBlockIn' main='WL_book_move' second='WL_book_a_Block' svg='move_16'/>
        <Gather name='copyBlockIn' main='WL_book_copy' second='WL_book_a_Block' svg='duplicate_16'/>
    </>
export const adderBlockData = <>
        <Edit name='newBlock' main='WL_book_create' second='WL_book_a_new_Block' svg='plus_14_line_24'/>
        <Gather name='hookBlockIn' main='WL_book_hook' second='WL_book_a_Block' svg='hook_16'/>
        <Gather name='moveBlockIn' main='WL_book_move' second='WL_book_a_Block' svg='move_16'/>
        <Gather name='copyBlockIn' main='WL_book_copy' second='WL_book_a_Block' svg='duplicate_16' isLine/>
        <Edit name='newData' main='WL_book_create' second='WL_book_a_new_Data' svg='plus_14_line_24'/>
        <Gather name='hookDataIn' main='WL_book_hook' second='WL_book_a_Data' svg='hook_16'/>
        <Gather name='moveDataIn' main='WL_book_move' second='WL_book_a_Data' svg='move_16'/>
        <Gather name='copyDataIn' main='WL_book_copy' second='WL_book_a_Data' svg='duplicate_16'/>
    </>
export const adderData = <>
        <Edit name='newData' main='WL_book_create' second='WL_book_a_new_Data' svg='plus_14_line_24'/>
        <Gather name='hookDataIn' main='WL_book_hook' second='WL_book_a_Data' svg='hook_16'/>
        <Gather name='moveDataIn' main='WL_book_move' second='WL_book_a_Data' svg='move_16'/>
        <Gather name='copyDataIn' main='WL_book_copy' second='WL_book_a_Data' svg='duplicate_16'/>
    </>
