import { Element, Icon, Textline } from '../../../../modules/basics/components';
import { s, use } from '../../../../root';

const LoadingList = ({ svg, typename, isOptions, isAdder }) => {

    const c = use.useColor();

    const style = {
        logo:   [s.X48Y40],
        icon:   [s.X32Y40],
        text:   [s.textPod],
    };

    const logo = <Icon style={style.logo} color={c.onMainN1_d} svg={svg}/>
    const options = <Icon style={style.icon} color={c.onMainN1_d} svg='optionsVertical_16'/>
    const adder = <Icon style={style.icon} color={c.onMainN1_d} svg='plus_14_line_24'/>

    return (
        <Element logo={logo} options={isOptions ? options:null} adder={isAdder ? adder:null}>
            <Textline style={style.text} color={c.onMainN1_d} WL text={`WL_book_${typename}ListLoading`}/>
        </Element>
    );       
};
export default LoadingList;