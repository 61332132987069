import { useWindowDimensions } from 'react-native';
import { useLayoutEffect } from 'react';
import { useRecoilState } from 'recoil';
import { store } from '../../../root';

const useSetResize = () => {

    const {height, width} = useWindowDimensions();
    const [isOversize, setIsOversize] = useRecoilState(store.layout.isOversize);
    const [size, setSize] = useRecoilState(store.layout.size);
    const [isSideOneOn, setIsSideOneOn] = useRecoilState(store.layout.isSideOneOn);
    const [isSideTwoOn, setIsSideTwoOn] = useRecoilState(store.layout.isSideTwoOn);

    const setResize = useLayoutEffect(() => {        
        
        const resize = () => {
            
            console.log('inside SetSize')
            const isOneColumn = size==='1';
            const isTwoColumn = size==='2';
            const isThreeColumn = size==='3';
        
            if (width > 1616) {
                if (!isThreeColumn) setSize('3');
                if (!isSideOneOn) setIsSideOneOn(true);
                if (!isSideTwoOn) setIsSideTwoOn(true);
                if (!isOversize) setIsOversize(true);
            }
            else if (width > 1304 && width <= 1616) {
                if (!isThreeColumn) setSize('3');
                if (!isSideOneOn) setIsSideOneOn(true);
                if (!isSideTwoOn) setIsSideTwoOn(true);
                if (isOversize) setIsOversize(false);
            }
            else if (width > 976 && width <= 1304) {
                if (!isTwoColumn) setSize('2');
                if (!isSideOneOn) setIsSideOneOn(true);
                if (isSideTwoOn) setIsSideTwoOn(false);
                if (isOversize) setIsOversize(false);
            }
            else {
                if (!isOneColumn) setSize('1');
                if (isSideOneOn) setIsSideOneOn(false);
                if (isSideTwoOn) setIsSideTwoOn(false); 
                if (isOversize) setIsOversize(false);     
            };
        };
        
        resize();
        
    }, [size, isOversize, setIsOversize, setSize, height, width]);

    return setResize;
};
export default useSetResize;