import { View } from 'react-native';
import { s } from '../../../root';
import { Textline } from '../../../modules/basics/components';

const MissingPage = () => {

    const style = {
        page: [s.col, s.XYc]
    };

    return (
        <View style={style.page}>
            <Textline text='WL_settingsPage'/>
        </View>
    );       
};
export default MissingPage;