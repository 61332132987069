// import { useRecoilValue } from 'recoil';
// import { store } from '../../../../root';
// import * as atom from './atom.js';

const useWrite = ({data}) => {

    const write = () => data
    return write;

};
export default useWrite;