import { s, use } from '../../../../root';
import { Element, Icon, TextBox } from '../../../../modules/basics/components';
import { Pop } from '../../../../modules/popover/components';

const BookElement = ({ id, svg, text, options, adder}) => {

    const c = use.useColor();

    const style = {
        text:   [s.textPod],
        icon:   [s.X48Y40],
        pop:    [s.X32Y40],
    };

    const logo = <Icon style={style.icon} color={c.onMainN1} svg={svg}/>
    const popOptions = <Pop style={style.pop} color={c.onMainN1} svg='optionsVertical_16' popover={options}/>
    const popAdder = <Pop style={style.pop} color={c.onMainN1} svg='plus_14_line_24' popover={adder}/>

    return (
        <Element id={id} logo={logo} options={popOptions} adder={popAdder}>
            <TextBox style={style.text} color={c.onMainN1} text={text}/>
        </Element>
    );
}; 
export default BookElement;

