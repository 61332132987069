import { s, use } from '../../../../root';
import { Element, Link, Icon, Textline } from '../../../../modules/basics/components';
import { Pop } from '../../../../modules/popover/components';

const BookLink = ({ id, svg, linkTo, options, text }) => {

    const c = use.useColor();

    const style = {
        link:   [s.row_sXcY, s.Xr, s.Y40], 
        text:   [s.space.mY8, {lineHeight:24, fontWeight:'bold'}],
        icon:   [s.X48Y40],
        pop:    [s.X32Y40],
    };

    const logo = <Icon style={style.icon} color={c.onMainN1} svg={svg}/>
    const popover = <Pop style={style.pop} color={c.onMainN1} svg='optionsVertical_16' popover={options}/>
    
    return (
        <Element id={id} logo={logo} options={popover}>
            <Link style={style.link} linkTo={linkTo}>
                <Textline style={style.text} color={c.onMainN1} text={text}/>
            </Link>
        </Element>
    );
};
export default BookLink;