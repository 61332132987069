import { useSetRecoilState } from 'recoil';
import { abs } from '../../../../root';
import * as atom from './atom.js';
import { fetchSet, fetchBook, fetchSection, fetchPage, fetchBlock, fetchData } from '../../api'

const useRefetch = (data) => {

    const { typename, type, id, fetchId } = data;

    const setAtom = useSetRecoilState(atom[typename]([type, id]));

    const operations = {
        Set: async () => await fetchSet(type, id, fetchId, true),
        Book: async () => await fetchBook(type, id, fetchId, true),
        Section: async () => await fetchSection(type, id, fetchId, true),
        Page: async () => await fetchPage(type, id, fetchId, true),
        Block: async () => await fetchBlock(type, id, fetchId, true),
        Data: async () => await fetchData(type, id, fetchId, true),
    };

    const isList = type==='list';
    const operation = isList ? abs.book.parentOf(typename) : typename;
    const refetchAtom = operations[operation]

    console.log('useRefetch > ', 'data ', data, 'typename ', typename, 'operation ', operation)

    return [refetchAtom, setAtom];

};
export default useRefetch;