import { Element, Icon, Textline } from '../../../../modules/basics/components';
import { s, abs, use } from '../../../../root';

const LoadingCover = () => {

    const c = use.useColor();

    const style = {
        logo:   [s.X48Y40],
        icon:   [s.X32Y40],
        text:   [s.textPod],
    };

    const bookLogo = <Icon style={style.logo} color={c.onMainN1_d} svg={abs.book.svg({typename:'Book'})}/>
    const options = <Icon style={style.icon} color={c.onMainN1_d} svg='optionsVertical_16'/>
    const adder = <Icon style={style.icon} color={c.onMainN1_d} svg='plus_14_line_24'/>

    return (
        <>
        <Element logo={bookLogo} options={options} adder={adder} isMain>
            <Textline style={style.text} color={c.onMainN1_d} WL text='WL_book_BookLoading'/>
        </Element>
        </>
    );       
};
export default LoadingCover;