import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { View, ScrollView } from 'react-native';
import { v4 as getUUID } from 'uuid';
import { s, abs, use, store } from '../../../../root';
import { PageTitlebar, LoadingList } from '../../../../app/book/suits';
import Books from './Books';

const BookList = ({ filter }) => {

    const t = use.useText();

    const typename = 'Book';
    const svg = abs.book.svg({typename});

    const url = useRecoilValue(store.router.url);
    const { setId } = abs.book.params(url);
    const params = { setId };
    const bookList = { typename, type:'list', id:setId, fetchId:setId };

    const id = getUUID();
    const popover = abs.book.popover({
        header: {svg, title:t(`WL_book_books_${filter}`)},
        popover: {id, typename, ...params},
        adder: {name: 'adderTitleBookList'},
    })

    if (!setId) return null
    return (
        <View style={[s.scrollView]}>
            <PageTitlebar 
                id={id} 
                title={`WL_book_books_${filter}`} 
                backTo={abs.book.link('overview', params)} 
                adder={popover.adder}
            />
            <View style={[s.scrollView]}>
            <ScrollView style={[s.scrollView]}>
                <Suspense fallback={<LoadingList svg={svg} typename={typename}/>}>
                    <Books 
                        svg={svg}
                        typename={typename} 
                        params={params} 
                        book={{list:bookList}}
                        key={setId}
                    />
                </Suspense>
            </ScrollView>
            </View>
        </View>
    );       
};
export default BookList;