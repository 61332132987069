import { atom, selector } from 'recoil';
import * as localStorage from '../../platform/localStorage';

const store = {

    consent: atom({
        key: 'consent',
        default: selector({
            key: 'getConsent',
            get: async () => {
                let result = await localStorage.get('consent');
                if (!result) result = await localStorage.set('consent', 'false');
                console.log('result inside consent selector', result)
                return result === 'true' ? true : false;
            }
        }),
    }),

    necessary: atom({
        key: 'necessary',
        default: selector({
            key: 'getNecessary',
            get: async () => {
                let result = await localStorage.get('necessary');
                if (!result) result = await localStorage.set('necessary', 'true');
                console.log('result inside necessary selector', result)
                return result === 'true' ? true : false;
            }
        }),
    }),

    analytics: atom({
        key: 'analytics',
        default: selector({
            key: 'getAnalytics',
            get: async () => {
                let result = await localStorage.get('analytics');
                if (!result) result = await localStorage.set('analytics', 'false');
                console.log('result inside analytics selector', result)
                return result === 'true' ? true : false;
            }
        }),
    }),

    marketing: atom({
        key: 'marketing',
        default: selector({
            key: 'getMarketing',
            get: async () => {
                let result = await localStorage.get('marketing');
                console.log('result 1 inside marketing selector', result)
                if (!result) result = await localStorage.set('marketing', 'false');
                console.log('result 2 inside marketing selector', result)
                return result === 'true' ? true : false;
            }
        }),
    }),

    tracking: atom({
        key: 'tracking',
        default: selector({
            key: 'getTracking',
            get: async () => {
                let result = await localStorage.get('tracking');
                if (!result) result = await localStorage.set('tracking', 'false');
                console.log('result inside tracking selector', result)
                return result === 'true' ? true : false;
            }
        }),
    }),
};
export default store;