import { useEffect, Suspense } from 'react'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { Routes, Route, useLocation } from '../../../platform/router';
import { api, routes, store, use } from '../../../root';
import { Layout } from '../../../modules/layout/components';
import { MissingPage, SettingsPage } from '../../../modules/router/pages';

const Router = () => { 

    const user = useRecoilValue(api.user.user)
    const setUrl = useSetRecoilState(store.router.url)
    const url = useLocation().pathname
    store.router.path = url;
    useEffect(()=> setUrl(url), [url])
 
    return (
        <Routes>
            <Route  path='' element={<Layout/>}>
                {user?.id ? routes.private : null}
                {routes.public}
                <Route path='/settings' element={<SettingsPage/>}/>
                <Route path='/*' element={<MissingPage/>}/>
            </Route>
        </Routes>
    );
};
export default Router;