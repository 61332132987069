import { useRecoilState } from 'recoil';
import { v4 as getUUID } from 'uuid';
import { store } from '../../../../root';
import { TriggerItem } from '../../../../modules/popover/suits';

const New = ({ name, svg, main, second, isLine, isNavigate }) => {

    const [modals, setModals] = useRecoilState(store.modal.modals)
    const onNew = () => {
        const id = getUUID();
        setModals([...modals, { id, name, isNavigate }]);
    };
    return <TriggerItem onPress={onNew} svg={svg} main={main} second={second} isLine={isLine}/>
};
export default New;