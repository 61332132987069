import { View, Text } from 'react-native';
import { useRecoilValue } from 'recoil';
import { abs, api } from '../../../root';
import { Titlebar, Card } from '../../../modules/basics/components';

const PictureOverview = () => {

    const user = useRecoilValue(api.user.user);
    const userId = user?.id;

    return (
        <View>
            <Titlebar 
                backTo='/creator'
                title='WL_book_PicturesOverview'
            />
            <Card 
                isGuest={!userId}
                linkTo={`/set/${userId}/pictures/list`} 
                svg='book_16' 
                title='WL_book_myPictures' 
                description='WL_book_myPictures_description' 
            />
            <Card 
                isGuest={!userId}
                linkTo={`/set/${userId}/pictures/publications`} 
                svg='book_16' 
                title='WL_book_myPublications' 
                description='WL_book_myPublications_description' 
            />
            <Card 
                isGuest={!userId}
                linkTo={`/set/${userId}/pictures/bookmarks`} 
                svg='book_16' 
                title='WL_book_myBookmarks' 
                description='WL_book_myBookmarks_description' 
            />
        </View>
    );
};
export default PictureOverview;

// import { Image } from 'expo-image';
// import { StyleSheet, View } from 'react-native';

// const blurhash =
//   '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[';

// export default function App() {
//   return (
//     <View style={styles.container}>
//       <Image
//         style={styles.image}
//         source="https://picsum.photos/seed/696/3000/2000"
//         placeholder={blurhash}
//         contentFit="cover"
//         transition={1000}
//       />
//     </View>
//   );
// }

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     backgroundColor: '#fff',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   image: {
//     flex: 1,
//     width: '100%',
//     backgroundColor: '#0553',
//   },
// });