import { RefetchArrange, RefetchReorder, RefetchUpdate } from '../../../../app/book/agents.js';

const AgentRefetch = ({ errorType, refetch={} }) => {

    const render = {
        arrange: <RefetchArrange errorType={errorType} {...refetch}/>,
        reorder: <RefetchReorder errorType={errorType} {...refetch}/>,
        update: <RefetchUpdate errorType={errorType} {...refetch}/>,
    };
    return render[refetch.refetch];
    
}; 
export default AgentRefetch;