import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as getUUID } from 'uuid';
import { View } from 'react-native';
import * as localStorage from '../../../platform/localStorage';
import { s, use, store } from '../../../root';
import { Modal } from '../../../modules/modal/components';
import { Textline, Slider, Icon, Button } from '../../../modules/basics/components';
// import Actions from './Actions';

const Welcome = ({
    toggleCookiesNecessary,
    toggleCookiesAnalytics,
    toggleCookiesMarketing,
    toggleCookiesTracking,
    onSetLanguage,
    selectAll,
    deselectAll,
    language,
    necessary,
    analytics,
    marketing,
    tracking,
    c,
}) => {

    // const t = use.useText();
    // const c = use.useColor();

    // const [modals, setModals] = useRecoilState(store.modal.modals);
    // const closeModal = use.modal.useCloseModal();

    // const language = useRecoilValue(store.layout.language);
    
    // const [analytics, setAnalytics] = useRecoilState(store.cookies.analytics);
    // const [marketing, setMarketing] = useRecoilState(store.cookies.marketing);

    // const setConsent = useSetRecoilState(store.cookies.consent);
    // const necessary = useRecoilValue(store.cookies.necessary);
    // const tracking = useRecoilValue(store.cookies.tracking);

    // const onSetLanguage = () => {
    //     const id = getUUID();
    //     setModals([...modals, {id, name:'language'}]);
    // };

    // const toggleCookiesNecessary = () => {};

    // const toggleCookiesAnalytics = () => {
    //     setAnalytics(!analytics)
    // };
    // const toggleCookiesMarketing = () => {
    //     setMarketing(!marketing)
    // };
    // const toggleCookiesTracking = () => {};

    // const selectAll = () => {
    //     setAnalytics(true)
    //     setMarketing(true)
    // };

    // const deselectAll = () => {
    //     setAnalytics(false)
    //     setMarketing(false)
    // };

    // const save = async () => {
    //     setConsent(true)
    //     localStorage.set('consent', 'true');
    //     localStorage.set('necessary', necessary);
    //     localStorage.set('analytics', analytics);
    //     localStorage.set('marketing', marketing);
    //     localStorage.set('tracking', tracking);
    //     closeModal(id);
    // };

    // const title = 'WL_cookies_welcome'
    // const svg = 'placeholder_16'
    // const actions = <Actions save={save}/>

    const style = {
        body:           [s.col, s.XYc, s.space.pT8],
        group:          [s.col],
        line:           [s.row_sXcY, s.Y48, s.Xc, s.space.pS16, {justifyContent:'space-between'}],
        title:          [s.row_sXcY, {fontWeight:'bold', fontSize:24}],
        text:           [s.row_sXcY, {fontWeight:'bold'}],
        description:    [s.row_sXcY, s.Xr, {width:1, flexWrap:'wrap'}],
        languageBox:    [s.row_sXcY, s.Y48],
        textLine:       [s.row_sXcY, s.Xc, s.space.pX16],
        buttonLine:     [s.row_sXcY, s.Y48, s.space.mT4, s.space.pX16],
        button:         [s.row_cXcY, s.space.mE8, s.space.pX12, s.radius.r8, s.Y32, {alignSelf:'center'}],
    };

    return (
        <View style={style.body}>

            <View style={style.line}>
                <Textline style={style.title} color={c.onMainN1} WL text='WL_cookies_language'/>
                <View style={style.languageBox}>
                    <Textline style={style.text} color={c.onMainN1} WL text={store.basics.languages[language]?.name} />
                    <Icon style={[s.XY48]} color={c.onMainN1} svg='placeholder_16' onPress={onSetLanguage}/>
                </View>
            </View>

            <View style={style.group}>
                <View style={style.line}>
                    <Textline style={style.title} WL text='WL_cookies_cookies' />
                </View>
                <View style={style.textLine}>
                    <Textline style={style.description} WL text='WL_cookies_description' />
                </View>
                <View style={style.buttonLine}>
                    <Button style={style.button} color={c.buttonN1} onPress={selectAll}>
                        <Textline style={style.text} color={c.onButtonN1} WL text='WL_cookies_selectAll' />
                    </Button>
                    <Button style={style.button} color={c.buttonN1} onPress={deselectAll}>
                        <Textline style={style.text} color={c.onButtonN1} WL text='WL_cookies_deselectAll' />  
                    </Button>
                </View>
            </View>

            <View style={style.group}>
                <View style={style.line}>
                    <Textline style={style.text} color={c.onMainN1} WL text='WL_cookies_necessary'/>
                    <Slider isOn={necessary} onPress={toggleCookiesNecessary}/>
                </View>
                <View style={style.textLine}>
                    <Textline style={style.description} WL text='WL_cookies_necessary_description'/>
                </View>
            </View>

            <View style={style.group}>
                <View style={style.line}>
                    <Textline style={style.text} color={c.onMainN1} WL text='WL_cookies_analytics' />
                    <Slider isOn={analytics} onPress={toggleCookiesAnalytics}/>
                </View>
                <View style={style.textLine}>
                    <Textline style={style.description} color={c.onMainN1} WL text='WL_cookies_analytics_description'/>
                </View>
            </View>

            <View style={style.group}>
                <View style={style.line}>
                    <Textline style={style.text} color={c.onMainN1} WL text='WL_cookies_marketing'/>
                    <Slider isOn={marketing} onPress={toggleCookiesMarketing}/>
                </View>
                <View style={style.textLine}>
                    <Textline style={style.description} color={c.onMainN1} WL text='WL_cookies_marketing_description'/>
                </View>
            </View>

            <View style={style.group}>
                <View style={style.line}>
                    <Textline style={style.text} color={c.onMainN1} WL text='WL_cookies_behavioral'/>
                    <Slider isOn={tracking} onPress={toggleCookiesTracking}/>
                </View>
                <View style={style.textLine}>
                    <Textline style={style.description} color={c.onMainN1} WL text='WL_cookies_behavioral_description'/>
                </View>
            </View>

        </View>
    );
}; 
export default Welcome;