import { View, Text } from 'react-native';
import { useRecoilValue } from 'recoil';
import { abs, api } from '../../../root';
import { Titlebar, Card } from '../../../modules/basics/components';

const BookOverview = () => {

    const user = useRecoilValue(api.user.user);
    const userId = user?.id;

    return (
        <View>
            <Titlebar 
                backTo='/creator'
                title='WL_book_BooksOverview'
            />
            <Card 
                isGuest={!userId}
                linkTo={`/set/${userId}/books/list`} 
                svg='book_16' 
                title='WL_book_myBooks' 
                description='WL_book_myBooks_description' 
            />
            <Card 
                isGuest={!userId}
                linkTo={`/set/${userId}/books/publications`} 
                svg='book_16' 
                title='WL_book_myPublications' 
                description='WL_book_myPublications_description' 
            />
            <Card 
                isGuest={!userId}
                linkTo={`/set/${userId}/books/bookmarks`} 
                svg='book_16' 
                title='WL_book_myBookmarks' 
                description='WL_book_myBookmarks_description' 
            />
        </View>
    );
};
export default BookOverview;