import { View, ScrollView } from 'react-native';
import { v4 as getUUID } from 'uuid';
import { s, use, abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { PageTitlebar, PageAttribute } from '../../../../app/book/suits';

const Book = ({ svg, typename, params, book }) => {

    const t = use.useText()

    const fetchItem = api.book.useFetch(book.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorMain name='bookPage' data={book.item}/>

    const { title, description, cover, logo } = item;
    const modify = { name:`optionsBookCover`, typename, ids:params, item };

    const id = getUUID();
    const popover = abs.book.popover({
        header: {svg, title:t('WL_book_cover')},
        popover: {id, typename, ...params, item},
        options: {name:'optionsTitleBookCover', linkTo:abs.book.link('index', params), navigateTo:abs.book.link('books', params)},
        adder: {name:'adderTitleBookCover'},
    });

    return (
        <>
            <PageTitlebar 
                id={id} 
                title={`WL_book_cover`} 
                backTo={abs.book.link('index', params)} 
                options={popover.options} adder={popover.adder}
            />
            <View style={[s.scrollView]}>
            <ScrollView style={[s.scrollView]}>
                <PageAttribute attribute='title' 
                    svg={'text_16'} text={title} 
                    name={'modifyText'} modify={modify} 
                />
                <PageAttribute attribute='description' 
                    svg={'text_16'} text={description ? description:'WL_book_noDescription'} 
                    name={'modifyText'} modify={modify} 
                    isDisabled={!description}
                />
                <PageAttribute attribute='cover' 
                    svg={'picture_16'} text={cover ? cover:'WL_book_noCover'} 
                    name={'modifyText'} modify={modify} 
                    isDisabled={!cover}
                />
                <PageAttribute attribute='logo' 
                    svg={'picture_16'} text={logo ? logo:'WL_book_noLogo'} 
                    name={'modifyText'} modify={modify} 
                    isDisabled={!logo}
                />
            </ScrollView>
            </View>
        </>
    );
}; 
export default Book;