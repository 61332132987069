import { s, use } from '../../../../root';
import { Button, Textline } from '../../../../modules/basics/components';

const AgentButton = ({ label, action, isDisabled, isRed }) => {
 
    const c = use.useColor();

    const disabled = isDisabled ? {opacity:0.5}:null
    const onPress = isDisabled ? ()=>{}:action

    const color = isRed ? c.buttonR1 : c.buttonC1

    const style = {
        button: [s.button, s.Y32, disabled],
        text:   [{lineHeight:24}, {fontWeight:'bold'}],
    }

    return (
        <Button style={style.button} color={color} onPress={onPress}>
            <Textline style={style.text} color={c.onButtonN1} WL text={label}/>
        </Button>
    );
}; 
export default AgentButton;