import flags from './flags/index.js';

const languages = {

    en: {
        flag: flags.EN,
        code:'en',
        isRTL:false,
        name:'WL_english',
    },

    it: {
        flag: flags.IT,
        code:'it',
        isRTL:false,
        name:'WL_italian',
    },
};
export default languages;
