import { View, Text } from 'react-native';
import { Toolbar } from '../../../modules/basics/components';

const LandingPage = () => {

    return (
        <View>
            <Toolbar/>
            <Text>This is a landing page</Text>
        </View>
    );
};
export default LandingPage;