import Svg, { Path } from 'react-native-svg';
import { svgs } from '../../../root';

const SvgComponent = ({ svg, color }) => {
    
    const name = svg ? svg : 'placeholder_16';

    return (
        <Svg 
            style={{ height:32, width:32 }}
            fill={color}
            background='rgb(128, 128, 128)'
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path d={svgs[name]}  fillRule="evenodd" clipRule="evenodd"/>
        </Svg>
    );
};
export default SvgComponent;