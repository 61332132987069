import * as layout from '../modules/layout/modals.js';
import * as cookies from '../modules/cookies/modals.js';

import * as book from '../app/book/modals.js';
import * as picture from '../app/picture/modals.js';

const modals = {

    ...layout,
    ...cookies,
    
    ...book,
    ...picture,
    
};
export default modals;