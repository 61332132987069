import gql from './gql/index.js';
import { apollo } from '../../../../clients';
import { abs, store } from '../../../../root';

const fetchSet = async (type, id, setId, isRefetch) => {

        // // DELAYER START
        //     const delayer = async () => {const start = Date.now(); while (Date.now() - start < 2000 ) {}; return 'error'}; 
        //     return await delayer()
        // // DELAYER END

    console.log(
        '\n fetchSet', 
        '\n type ', type, 
        '\n id ', id, 
        '\n setId ', setId, 
        '\n isRefetch ', isRefetch,
        '\n\n'
    );
    // return 'error'
    try {

        // if the cache does not contain the element or contains an invalid value, we need to fetch it
        if (isRefetch || abs.isInvalid(store.book.cache[type][id])) {

            // check the connection, the token and if the fetchId is present
            if (!(await abs.isNetwork())) abs.errorThrow('if (!(await abs.isNetwork()))');
            if (!(await abs.isAuth())) abs.errorThrow('if (!(await abs.isAuth()))');
            if (!setId) abs.errorThrow('if (!setId)');

            // fetch the set 
            const response = await apollo.query ({
                query: gql.fetchSet, 
                variables: {input: {id: setId}}
            });

            // if there is an error throw an error
            if (response?.errors?.length) abs.errorThrow('if (response.errors?.length)', response.errors);

            // extract the data in a variable
            const set = response.data.fetchSet;

            // create an empty list for the set
            store.book.cache.list[setId] = [];

            for (const book of set) {        

                // push itemId of the book into the set list
                store.book.cache.list[setId].push({itemId: book.id});   
                
                // clone the item
                store.book.cache.item[book.id] = abs.book.clone.BookItem(book);

            };
        };

        // cleanup the operation in any case
        store.router.params = { };
        store.router.operation = '';
        store.router.operationId = '';

        console.log(
            '\n fetchSet return',
            '\n type ', type,
            '\n id ', id,
            '\n store.book.cache ', store.book.cache,
            '\n store.book.cache[type][id] ', store.book.cache[type][id],
            '\n\n'
        )

        // at this point data are in the cache in both cases
        return store.book.cache[type][id];

    } catch (error) {return abs.errorCatch('App/app/book/api/fetch/fetchSet', error)}

};

export default fetchSet;