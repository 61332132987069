import { abs, api } from '../../../../root';
import { SelectorElement } from '../../../../app/book/suits.js';

const Data = ({ modalId, selection, params, position, data}) => {
    
    const { typename, blockHookId, blockItemId } = params;
    const { initial, selectable, select, deselect, setSelected, selected } = selection;

    const id = `${blockItemId}_${position}`;
    const invalidId = initial.isMove && [`${initial.itemId}_${initial.position}`, `${initial.itemId}_${initial.position-1}`].includes(id);
    const isSelectable = selectable === 'Data' && !invalidId;
    const isSelected = selected === id;

    const onSelect = () => { 
        if (isSelectable) {
            if (isSelected) deselect()
            else {
                select({ start:position+1, destination_id:blockItemId, destinationHookId:blockHookId })
                setSelected(id)
            }
        };
    };

    const fetchItem = api.book.useFetch(data.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorModal name='bookSelector' data={{data:data.item, params, modalId}}/>

    const svg = abs.book.svg({typename, type:item.type});

    return (
        <SelectorElement 
            svg={svg} text={item.key ? `${item.key}: ${item.value}` : item.value} 
            onSelect={onSelect}
            isSelectable={isSelectable} isSelected={isSelected}
        />
    );
}; 
export default Data;