import gql from './gql/index.js';
import { apollo } from '../../../../clients';
import { abs, store } from '../../../../root';

const fetchSection = async (type, id, sectionHookId, isRefetch) => { 
    
    console.log(
        '\n fetchSection', 
        '\n type ', type, 
        '\n id ', id, 
        '\n sectionHookId ', sectionHookId, 
        '\n isRefetch ', isRefetch,
        '\n\n'
    );

    try { 

        // if the cache does not contain the element or contains an invalid value, we need to fetch it
        if (isRefetch || abs.isInvalid(store.book.cache[type][id])) {

            // check the connection, the token and if the fetchId is present
            if (!(await abs.isNetwork())) abs.errorThrow('if (!(await abs.isNetwork()))');
            if (!(await abs.isAuth())) abs.errorThrow('if (!(await abs.isAuth()))');
            if (!sectionHookId) abs.errorThrow('if (!sectionHookId)');
    
            // fetch the section
            const response = await apollo.query ({
                query: gql.fetchSection, 
                variables: {input: {id: sectionHookId}}
            });

            // if there is an error throw an error
            if (response?.errors?.length) abs.errorThrow('if (response.errors?.length)', response.errors);

            // extract the data in a variable
            const section = response.data.fetchSection;

            // clone the section hook and item, add a bond between item and hook
            store.book.cache.hook[section.id] = abs.book.clone.SectionHook(section);
            store.book.cache.item[section.item.id] = abs.book.clone.SectionItem(section.item);
            abs.book.bond.add(section.item.id, section.id);

            // create an empty list for the pages
            store.book.cache.list[section.item.id] = [];
            
            // take one by one each page of the section
            for (const page of section.item.childHooks) {

                // push hookId and itemId of the page into the section list
                store.book.cache.list[section.item.id].push({hookId: page.id, itemId: page.item.id});

                // clone the page hook and item, add a bond between item and hook
                store.book.cache.hook[page.id] = abs.book.clone.PageHook(page);
                store.book.cache.item[page.item.id] = abs.book.clone.PageItem(page.item)
                abs.book.bond.add(page.item.id, page.id);
            
            };
        };

        // cleanup the operation in any case
        store.router.params = { };
        store.router.operation = '';
        store.router.operationId = '';

        console.log(
            '\n fetchSection return',
            '\n type ', type,
            '\n id ', id,
            '\n store.book.cache ', store.book.cache,
            '\n store.book.cache[type][id] ', store.book.cache[type][id],
            '\n\n'
        )

        // at this point data are in the cache in both cases
        return store.book.cache[type][id];

    } catch (error) {return abs.errorCatch('App/app/book/api/fetch/fetchSection', error)}

};
export default fetchSection;