import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateBlock = gql`

    ${fragment.block.item}

    mutation updateBlock ($input: editElement!) {
        updateBlock(input: $input) {
            ...blockItemFields
        }
    }
`;

export default updateBlock;