const wordlist = {

    'WL_home_home' : {
        en:'Home',
        it:'Home',
    },

    'WL_home_creator' : {
        en:'Creator',
        it:'Creator',
    },

    'WL_home_creator_description' : {
        en:'All your creations like books or pictures are here.',
        it:'Tutte le tue creazioni come libri ed immagini sono qua.',
    },

    'WL_home_domino' : {
        en:'Domino',
        it:'Domino',
    },

    'WL_home_domino_description' : {
        en:'All your pending and accepted invitations are here.',
        it:'Tutti i tuoi inviti in sospeso ed accettati sono qua.',
    },

    'WL_home_language' : {
        en:'Language',
        it:'Lingua',
    },

    'WL_home_language_description' : {
        en:'Here you can change the language.',
        it:'Qua puoi cambiare la lingua.',
    },

    'WL_home_preferences' : {
        en:'Preferences',
        it:'Preferenze',
    },
    'WL_home_preferences_description' : {
        en:'Here you can change the dark and light mode.',
        it:'Qua puoi cambiare la modalitá chiara e scura.',
    },

    'WL_home_books' : {
        en:'Books',
        it:'Libri',
    },

    'WL_home_books_description' : {
        en:'All your books are here.',
        it:'Tutti i tuoi libri sono qua.',
    },

    'WL_home_pictures' : {
        en:'Pictures',
        it:'Immagini',
    },

    'WL_home_pictures_description' : {
        en:'All your pictures are here.',
        it:'Tutte le tue immagini sono qua.',
    },

    'WL_home_guest_description' : {
        en:'Login to see this content',
        it:'Accedi per vedere questo contenuto',
    },

};
export default wordlist;