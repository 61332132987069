import { View, ScrollView } from 'react-native';
import { s, abs, api } from '../../../../root';
import { SelectorToolbar, SelectorEmpty } from '../../../../app/book/suits.js';
import { ThrowErrorModal } from '../../../../modules/error/throw';
import Page from './Page.js';

const Pages = ({ modalId, selection, params, navigate }) => {

    const { typename, setId, bookItemId, sectionHookId, sectionItemId } = params;
    const { deselect } = selection

    const onBack = () => {
        deselect()
        navigate({ 
            typename:'Section', setId, bookItemId
        })
    };

    const toolbar = <SelectorToolbar onBack={onBack} typename='Section' selection={selection}/>

    const pageList = {typename, type:'list', id:sectionItemId, fetchId:bookItemId};
    const fetchList = api.book.useFetch(pageList);
    const list = fetchList();

    if (abs.isError(list)) return <ThrowErrorModal name='bookSelector' data={{data:pageList, params, modalId}} />
    if (abs.isEmpty(list)) return <SelectorEmpty typename='Page' toolbar={toolbar}/>
    if (!abs.isArray(list)) return null;

    return (<>
        {toolbar}
        <View style={[s.scrollView]}>
        <ScrollView style={[s.scrollView]}>
            {list.map((current, index) => {
                const pageHookId = current.hookId;
                const pageItemId = current.itemId;
                const hook = {typename, type:'hook', id:pageHookId, fetchId:bookItemId};
                const item = {typename, type:'item', id:pageItemId, fetchId:bookItemId};
                return (
                    <Page
                        modalId={modalId}
                        selection={selection}
                        params={{...params, pageHookId, pageItemId}}
                        page={{list:pageList, hook, item}}
                        navigate={navigate}
                        position={index+1}
                        key={current.hookId} 
                    />
                );
            })}
        </ScrollView>
        </View>
    </>);
}; 
export default Pages;