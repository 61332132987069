import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as getUUID } from 'uuid';
import { store } from '../../../root';

const useWelcome = () => {

    const [modals, setModals] = useRecoilState(store.modal.modals)
    const consent = useRecoilValue(store.cookies.consent);

    const welcome = useEffect(() => {     
        
        if (!consent) {
            const id = getUUID();
            setModals([...modals, {id, name:'welcome'}]);
        }

    }, [consent]);

    return welcome;
};
export default useWelcome; 