import { atom, selector } from 'recoil';
import { fetchUser } from './api/fetch';

export const user = atom({
    key: 'user',
    default: selector({
        key: 'getUser',
        get: async () => await fetchUser()
    }),
    // user doesn't need the retry logic
    // if something fails returns guest
});
