import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from '../../../platform/router';
import { abs, use, store } from '../../../root';
import { AgentInline, AgentButton } from '../../../app/picture/suits.js'

const Cleanup = ({ operationId, modalId, errorType='main', isRemove, navigateTo=null }) => {
    
    const closeModal = use.modal.useCloseModal();
    const setPopover = useSetRecoilState(store.popover.popover);
    const setError = useSetRecoilState(store.error[errorType]);
    const navigate = useNavigate();

    useEffect(() => {
        if (modalId) closeModal(modalId)
        if (errorType) setError({});
        setPopover({});
        abs.picture.operation.remove(operationId);
        return () => {if (navigateTo) navigate(navigateTo)};
    });

    return isRemove ? <AgentInline label='WL_picture_operation_ok'/> : <AgentButton label='WL_picture_operation_ok'/>

};
export default Cleanup;