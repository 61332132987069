const wordlist = {

    'WL_language' : {
        en:'Language',
        it:'Lingua',
    },
    'WL_english' : {
        en:'english',
        it:'inglese',
    },
    'WL_italian' : {
        en:'italian',
        it:'italiano',
    },
    
    'WL_country' : {
        en:'country',
        it:'nazione',
    },
    'WL_greatBritain' : {
        en:'Great Britain',
        it:'Gran Bretagna',
    },
    'WL_italy' : {
        en:'Italy',
        it:'Italia',
    },

    'WL_welcome' : {
        en:'Welcome',
        it:'Benvenuto',
    },

    'WL_language_intro' : {
        en:'INTRO LANG en Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit orci posuere, sollicitudin dui vitae, auctor lacus. Ut eleifend euismod quam eu varius. Quisque feugiat diam tincidunt rhoncus porttitor. Nullam interdum, est ut efficitur dictum, felis est consectetur purus, non maximus turpis magna luctus elit.',
        it:'INTRO LANG it Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit orci posuere, sollicitudin dui vitae, auctor lacus. Ut eleifend euismod quam eu varius. Quisque feugiat diam tincidunt rhoncus porttitor. Nullam interdum, est ut efficitur dictum, felis est consectetur purus, non maximus turpis magna luctus elit.',
    },

    'WL_cookies_intro' : {
        en:'INTRO COOKIES en Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit orci posuere, sollicitudin dui vitae, auctor lacus. Ut eleifend euismod quam eu varius. Quisque feugiat diam tincidunt rhoncus porttitor. Nullam interdum, est ut efficitur dictum, felis est consectetur purus, non maximus turpis magna luctus elit.',
        it:'INTRO COOKIES it Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit orci posuere, sollicitudin dui vitae, auctor lacus. Ut eleifend euismod quam eu varius. Quisque feugiat diam tincidunt rhoncus porttitor. Nullam interdum, est ut efficitur dictum, felis est consectetur purus, non maximus turpis magna luctus elit.',
    },

    'WL_cookies_0' : {
        en:'Only necessary cookies',
        it:'Solo i cookies necessari',
    },
    'WL_cookies_0_description' : {
        en:'0 en Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit orci posuere, sollicitudin dui vitae, auctor lacus. Ut eleifend euismod quam eu varius. Quisque feugiat diam tincidunt rhoncus porttitor. Nullam interdum, est ut efficitur dictum, felis est consectetur purus, non maximus turpis magna luctus elit.',
        it:'0 it Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit orci posuere, sollicitudin dui vitae, auctor lacus. Ut eleifend euismod quam eu varius. Quisque feugiat diam tincidunt rhoncus porttitor. Nullam interdum, est ut efficitur dictum, felis est consectetur purus, non maximus turpis magna luctus elit.',
    },

    'WL_cookies_1' : {
        en:'Necessary and Analytics cookies',
        it:'Cookies necessari e statistiche di utilizzo',
    },
    'WL_cookies_1_description' : {
        en:'1 en Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit orci posuere, sollicitudin dui vitae, auctor lacus. Ut eleifend euismod quam eu varius. Quisque feugiat diam tincidunt rhoncus porttitor. Nullam interdum, est ut efficitur dictum, felis est consectetur purus, non maximus turpis magna luctus elit.',
        it:'1 it Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit orci posuere, sollicitudin dui vitae, auctor lacus. Ut eleifend euismod quam eu varius. Quisque feugiat diam tincidunt rhoncus porttitor. Nullam interdum, est ut efficitur dictum, felis est consectetur purus, non maximus turpis magna luctus elit.',
    },

    'WL_cookies_2' : {
        en:'Accept all Cookies',
        it:'Accetta tutti i Cookies',
    },
    'WL_cookies_2_description' : {
        en:'2 en Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit orci posuere, sollicitudin dui vitae, auctor lacus. Ut eleifend euismod quam eu varius. Quisque feugiat diam tincidunt rhoncus porttitor. Nullam interdum, est ut efficitur dictum, felis est consectetur purus, non maximus turpis magna luctus elit.',
        it:'2 it Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit orci posuere, sollicitudin dui vitae, auctor lacus. Ut eleifend euismod quam eu varius. Quisque feugiat diam tincidunt rhoncus porttitor. Nullam interdum, est ut efficitur dictum, felis est consectetur purus, non maximus turpis magna luctus elit.',
    },

    'WL_cookies_details' : {
        en:'Custom selection of cookies',
        it:'Gestione dei cookies personalizzata',
    },
    'WL_cookies_details_description' : {
        en:'3 en Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit orci posuere, sollicitudin dui vitae, auctor lacus. Ut eleifend euismod quam eu varius. Quisque feugiat diam tincidunt rhoncus porttitor. Nullam interdum, est ut efficitur dictum, felis est consectetur purus, non maximus turpis magna luctus elit.',
        it:'3 it Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit orci posuere, sollicitudin dui vitae, auctor lacus. Ut eleifend euismod quam eu varius. Quisque feugiat diam tincidunt rhoncus porttitor. Nullam interdum, est ut efficitur dictum, felis est consectetur purus, non maximus turpis magna luctus elit.',
    },

}; 
export default wordlist;


