const wordlist = {

    'WL_user_login' : {
        en:'LOGIN',
        it:'ACCEDI',
    },
    'WL_user_backHome' : {
        en:'HOME PAGE',
        it:'HOME PAGE',
    },

    'WL_user_logout' : {
        en:'LOGOUT',
        it:'ESCI',
    },
    'WL_user_close' : {
        en:'CLOSE',
        it:'CHIUDI',
    },
    'WL_user_createAccount' : {
        en:'Create Account',
        it:'Crea Account',
    },
    'WL_user_manageAccount' : {
        en:'Manage Account',
        it:'Gestisci Account',
    },
    'WL_user_preferences' : {
        en:'Preferences',
        it:'Preferenze',
    },
    'WL_user_language' : {
        en:'Language',
        it:'Lingua',
    },
    'WL_user_cookies' : {
        en:'Cookies',
        it:'Cookies',
    },
    'WL_user_cookiePolicy' : {
        en:'Cookie Policy',
        it:'Cookie Policy',
    },
    'WL_user_privacyPolicy' : {
        en:'Privacy',
        it:'Privacy',
    },
    'WL_user_termsOfService' : {
        en:'Terms of Service',
        it:'Termini del servizio',
    },

};
export default wordlist;