import { abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import Data from './Data.js';

const Datas = ({ typename, params={}, data={} }) => {

    const fetch = api.book.useFetch(data.list);
    const list = fetch();

    if (abs.isError(list)) return <ThrowErrorMain name='bookPage' data={data.list}/>
    if (!abs.isArray(list)) return null;

    return (<>
        {list.map((current, index) => {
            const { pageHookId } = params;
            const dataHookId = current.hookId;
            const dataItemId = current.itemId;
            const hook = {typename, type:'hook', id:dataHookId, fetchId:pageHookId};
            const item = {typename, type:'item', id:dataItemId, fetchId:pageHookId};
            return (
                <Data
                    typename={typename}
                    params={{...params, dataHookId, dataItemId}}
                    data={{list:data.list, hook, item}}
                    dataPosition={index+1}
                    key={current.hookId} 
                />
            );
        })}
    </>);
}; 
export default Datas;