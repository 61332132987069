import { atom } from 'recoil';

const router = {

    url: atom({key:'url', default:''}),
    path:'',
    params:{},

    operation:'',
    operationId:'',

};
export default router;
