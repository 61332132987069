import { useSetRecoilState } from 'recoil';
import * as atom from './atom.js';
import { fetchSet } from '../../api'

const useRefetch = (data) => {

    const { type, id, fetchId } = data;

    const setAtom = useSetRecoilState(atom.Picture([type, id]));
    const refetchAtom = async () => await fetchSet(type, id, fetchId, true)

    return [refetchAtom, setAtom];

};
export default useRefetch;