import { View } from 'react-native';
import { useRecoilValue } from 'recoil';
import { s, use, store } from '../../../root';

const Toolbar = ({ id, one, middle, two }) => {

    const c = use.useColor();

    const popover = useRecoilValue(store.popover.popover);
    const isPopover = popover.id && id === popover.id;

    const style = {
        toolbar:    [s.col, s.Y42, s.radius.t16, isPopover ? c.toolbarC1 : c.toolbarN1],
        body:       [s.row, s.Y40],
        one:        [s.row],
        middle:     [s.row_sXcY, s.Xr],
        two:        [s.row, s.space.mE8],
        line:       [s.Y2, s.Xc, isPopover? c.selectorC1 : c.popoverN1],
    };

    return (
        <View style={style.toolbar}>
            <View style={style.body}>
                <View style={style.one}>{one}</View>
                <View style={style.middle}>{middle}</View>
                <View style={style.two}>{two}</View>
            </View>
            <View style={style.line}></View>
        </View>
    );       
};
export default Toolbar;