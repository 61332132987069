import { useRecoilValue } from 'recoil';
import { store, use, modals as modalsList } from '../../../root';

const Modals = () => {

    // resize the modal 
    use.modal.useSetResize();

    // get the modals
    const modals = useRecoilValue(store.modal.modals);

    // if there are no modals return null
    if (!modals.length) return null

    // if there are modals map them 
    return <>{modals.map(modal => modalsList[modal?.name](modal))}</>

};
export default Modals;