import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateDataValue = gql`

    ${fragment.data.item}

    mutation updateDataValue ($input: editValue!) {
        updateDataValue(input: $input) {
            ...dataItemFields
        }
    }
`;

export default updateDataValue;