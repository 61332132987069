import useColor from '../use/useColor.js';
import useMargins from '../use/useMargins.js';
import useText from '../use/useText.js';

import * as alert from '../modules/alert/use.js';
import * as cookies from '../modules/cookies/use.js';
import * as error from '../modules/error/use.js';
import * as layout from '../modules/layout/use.js';
import * as modal from '../modules/modal/use.js';
import * as popover from '../modules/popover/use.js';
import * as user from '../modules/user/use.js';

const use = {

    alert,
    cookies,
    error,
    layout,
    modal,
    popover,
    user,

    useColor, 
    useMargins,
    useText,

};
export default use;