import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const fetchBlock = gql`

    ${fragment.block.hook}
    ${fragment.block.item}
    ${fragment.data.hook}
    ${fragment.data.item}

    query fetchBlock ($input: id!) {
        fetchBlock(input: $input) {
            ...blockHookFields
            item {
                ...blockItemFields
                childHooks {
                    ...dataHookFields 
                    item {
                        ...dataItemFields
                    } 
                }
            }
        }
    }
`;
export default fetchBlock;