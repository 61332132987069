import { useSetRecoilState } from 'recoil';
import { api, store } from '../../../root';
import * as localStorage from '../../../platform/localStorage';

const useLogout = () => {

    const setUser = useSetRecoilState(api.user.user);

    const logout = async () => {

        await localStorage.remove('accessToken');
        await localStorage.remove('refreshToken');

        store.user.accessToken = '';
        store.user.refreshToken = '';

        setUser('guest');

    };

    return logout;
};
export default useLogout;