import countries from './store/countries/index.js';
import languages from './store/languages/index.js';
import presets from './store/presets/index.js';

const store = {

    countries,
    languages,
    presets,

};
export default store;