import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateSectionMetadata = gql`

    ${fragment.section.item}

    mutation updateSectionMetadata ($input: editMetadata!) {
        updateSectionMetadata(input: $input) {
            ...sectionItemFields
        }
    }
`;

export default updateSectionMetadata;