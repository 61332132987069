import gql from './gql/index.js';
import { apollo } from '../../../../clients';
import { abs, store } from '../../../../root';

const fetchBook = async (type, id, bookItemId, isRefetch) => {

    console.log(
        '\n fetchBook', 
        '\n type ', type, 
        '\n id ', id, 
        '\n bookItemId ', bookItemId, 
        '\n isRefetch ', isRefetch,
        '\n\n'
    );

    try { 

        // if the cache does not contain the element or contains an invalid value, we need to fetch it
        if (isRefetch || abs.isInvalid(store.book.cache[type][id])) {

            // check the connection, the token and if the fetchId is present
            if (!(await abs.isNetwork())) abs.errorThrow('if (!(await abs.isNetwork()))');
            if (!(await abs.isAuth())) abs.errorThrow('if (!(await abs.isAuth()))');
            if (!bookItemId) abs.errorThrow('if (!bookItemId)');

            // fetch the book
            const response = await apollo.query ({
                query: gql.fetchBook, 
                variables: {input: {id: bookItemId}}
            }); 

            // if there is an error throw an error
            if (response?.errors?.length) abs.errorThrow('if (response.errors?.length)', response.errors);

            // extract the data in a variable
            const book = response.data.fetchBook;

            // clone the book item inside the cache and create an empty list for the sections
            store.book.cache.item[book.id] = abs.book.clone.BookItem(book);

            // create an empty list for the sections
            store.book.cache.list[book.id] = []

            // take one by one each section of the book
            for (const section of book.childHooks) {

                // push hookId and itemId of the section into the book list
                store.book.cache.list[book.id].push({hookId: section.id, itemId: section.item.id});
                
                // clone the section hook and item, add a bond between item and hook
                store.book.cache.hook[section.id] = abs.book.clone.SectionHook(section);
                store.book.cache.item[section.item.id] = abs.book.clone.SectionItem(section.item);
                abs.book.bond.add(section.item.id, section.id);

                // create an empty list for the pages
                store.book.cache.list[section.item.id] = [];
                
                // take one by one each page of the section
                for (const page of section.item.childHooks) {

                    // push hookId and itemId of the page into the section list
                    store.book.cache.list[section.item.id].push({hookId: page.id, itemId: page.item.id});

                    // clone the page hook and item, add a bond between item and hook
                    store.book.cache.hook[page.id] = abs.book.clone.PageHook(page);
                    store.book.cache.item[page.item.id] = abs.book.clone.PageItem(page.item);
                    abs.book.bond.add(page.item.id, page.id);
                };                  
            };
        };

        // cleanup the operation in any case
        store.router.params = { };
        store.router.operation = '';
        store.router.operationId = '';

        console.log(
            '\n fetchBook return',
            '\n type ', type,
            '\n id ', id,
            '\n store.book.cache ', store.book.cache,
            '\n store.book.cache[type][id] ', store.book.cache[type][id],
            '\n\n'
        )

        // at this point data are in the cache in both cases
        return store.book.cache[type][id];

    } catch (error) {return abs.errorCatch('App/app/book/api/fetch/fetchBook', error)}
};
export default fetchBook;
