import { View, ScrollView } from 'react-native';
import { s, abs, api } from '../../../../root';
import { SelectorToolbar, SelectorEmpty, SelectorFirst } from '../../../../app/book/suits.js';
import { ThrowErrorModal } from '../../../../modules/error/throw';
import Data from './Data.js';

const Datas = ({ modalId, selection, params, navigate }) => {

    const { typename, setId, bookItemId, sectionHookId, sectionItemId, pageHookId, pageItemId, blockHookId, blockItemId } = params;
    const { deselect } = selection

    const onBack = () => {
        deselect()
        navigate({ 
            typename:'Block', setId, bookItemId, sectionHookId, sectionItemId, pageHookId, pageItemId
        })
    }

    const toolbar = <SelectorToolbar onBack={onBack} typename='Block' selection={selection}/>
    const first = <SelectorFirst typename={typename} itemId={blockItemId} hookId={blockHookId} selection={selection}/>

    const dataList = {typename, type:'list', id:blockItemId, fetchId:pageHookId};
    const fetchList = api.book.useFetch(dataList);
    const list = fetchList();

    if (abs.isError(list)) return <ThrowErrorModal name='bookSelector' data={{data:dataList, params, modalId}} />
    if (abs.isEmpty(list)) return <SelectorEmpty typename={typename} toolbar={toolbar} first={first}/>
    if (!abs.isArray(list)) return null;

    return (<>
        {toolbar}
        <View style={[s.scrollView]}>
        <ScrollView style={[s.scrollView]}>
            {first}
            {list.map((current, index) => {
                const dataHookId = current.hookId;
                const dataItemId = current.itemId;
                const hook = {typename, type:'hook', id:dataHookId, fetchId:pageHookId};
                const item = {typename, type:'item', id:dataItemId, fetchId:pageHookId};
                return (
                    <Data
                        modalId={modalId}
                        selection={selection}
                        params={{...params, dataHookId, dataItemId}}
                        data={{list:dataList, hook, item}}
                        navigate={navigate}
                        position={index+1}
                        key={current.hookId} 
                    />
                );
            })}
        </ScrollView>
        </View>
    </>);
}; 
export default Datas;