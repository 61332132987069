import { useRecoilState, useSetRecoilState } from 'recoil';
import { store } from '../../../root';

const useCloseModal = () => {

    const [modals, setModals] = useRecoilState(store.modal.modals);
    const [error={}, setError] = useRecoilState(store.error.modal);
    const setUrl = useSetRecoilState(store.modal.url);

    const closeModal = (modalId) => {
        // always set modal url as empty when close
        setUrl('');
        // always set the modal error as empty when close
        if (error.name) setError({})
        // filter out the modal to be closed and set the others (or empty list) as modals
        const nextModals = modals.filter(current => current.id !== modalId);
        setModals(nextModals);
    };

    return closeModal;
};
export default useCloseModal;