import { View, Pressable } from 'react-native';
import { Icon, Textline } from '../../../../modules/basics/components';
import { s, use } from '../../../../root';

const Item = ({ svg, text, onOpen=()=>{}, onSelect=()=>{}, isSelectable, isSelected }) => {
    
    const c = use.useColor();

    const iconColor = isSelected ? c.onMainC1 : isSelectable ? c.onMainC1_d : c.onMainN1_d;
    const elementColor = isSelected ? c.selectorC2 : isSelectable ? c.selectorC2_d : c.selectorN1_d;

    const style = {
        element:    [s.row, {minHeight:42}, elementColor],
        item:       [s.row_sXcY, s.Xr, { minHeight:42 }],
        data:       [s.Xr, { width:0 }],
        icon:       [s.X48Y40],
        text:       [s.textPod],
        selector:   [s.X48Y40],
    };

    return (
        <View>
            <View style={style.element}>
                <Pressable style={style.item} onPress={isSelectable ? onSelect : onOpen}>
                        <Icon style={style.icon} color={c.onMainN1} svg={svg}/>
                        <View style={style.data}>
                            <Textline style={style.text} color={c.onMainN1} text={text}/>
                        </View>
                </Pressable>
                <View style={style.icon}>
                    <Icon style={style.selector} color={iconColor} svg='arrowChevronLeft_16' onPress={onSelect}/>
                </View>
            </View>
        </View>
    );
}; 
export default Item;