import { useRecoilValue } from 'recoil';
import { store, errors } from '../../../root';

const ErrorMain = () => {

    const error = useRecoilValue(store.error.main);
    const { name } = error;

    console.log('inside error name:', name)
    
    if (!name) return null
    return errors[name];

}; 
export default ErrorMain;