import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { abs, store } from '../../../../root';
import { AgentRemove } from '../../../../app/book/agents.js';

const Remove = ({ isNavigate }) => {

    // get the data needed from the popover
    const popover = useRecoilValue(store.popover.popover)
    const { typename, sectionHookId, sectionItemId, pageHookId, pageItemId, navigateTo } = popover;

    // if is navigate provide the link where to navigate after removal
    const path = isNavigate ? navigateTo : null;

    // operationId, when set, triggers the Agent and run the operation
    const [operationId, setOperationId] = useState('');

    // onPress, create the operation and set the operationId to render the Agent
    const remove = () => {
        const ids = [pageHookId];
        const input = { ids, parent_id:sectionItemId };
        const source = { name:'popover' }
        const operation = 'remove';
        const list = {typename, type:'list', id:sectionItemId, fetchId:sectionHookId};
        const generatedId = abs.book.operation.add({typename, operation, input, list, source});
        setOperationId(generatedId);
    };

    const refetch = {refetch:'reorder', operationId, isRemove:true};

    return <AgentRemove operationId={operationId} action={remove} refetch={refetch} typename={typename}/>

};
export default Remove;