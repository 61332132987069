import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const copyBlock = gql`

    ${fragment.block.hook}
    ${fragment.block.item}
    ${fragment.data.hook}
    ${fragment.data.item}

    mutation copyBlock ($input: arrange!) {
        copyBlock(input: $input) {
            ...blockHookFields
            item {
                ...blockItemFields
                childHooks {
                    ...dataHookFields 
                    item {
                        ...dataItemFields
                    } 
                }
            }
        }
    }
`;

export default copyBlock;