import { atomFamily, selectorFamily } from 'recoil';
import writeOperation from './writeOperation';

export const Write = atomFamily ({
    key: 'pictureWrite',
    default: selectorFamily ({
        key: 'getPictureWrite',
        get: (operationId) => async () => {
            return await writeOperation(operationId);
        }
    }),
    effects: operationId => [
        ({onSet, setSelf}) => {
            onSet(async(newValue) => {
                if (newValue==='retry') {
                    const value = await writeOperation(operationId);
                    setSelf(value)
                }
            })
        },
    ]
});
