import { useRecoilState } from 'recoil';
import { v4 as getUUID } from 'uuid';
import { store } from '../../../../root';
import { PopoverItem } from '../../../../app/book/suits.js';

const Edit = ({ name, svg, main, second, isLine, isNavigate }) => {

    const [modals, setModals] = useRecoilState(store.modal.modals)
    const edit = () => {
        const id = getUUID();
        setModals([...modals, { id, name, isNavigate }]);
    };
    return <PopoverItem onPress={edit} svg={svg} main={main} second={second} isLine={isLine}/>
};
export default Edit;