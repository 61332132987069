import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateBookTitle = gql`

    ${fragment.book.item}

    mutation updateBookTitle ($input: editTitle!) {
        updateBookTitle(input: $input) {
            ...bookItemFields
        }
    }
`;

export default updateBookTitle;