import { View, ScrollView } from 'react-native';
import { s, abs, api } from '../../../../root';
import { SelectorToolbar, SelectorEmpty, SelectorFirst } from '../../../../app/book/suits.js';
import { ThrowErrorModal } from '../../../../modules/error/throw';
import Block from './Block.js';

const Blocks = ({ modalId, selection, params, navigate }) => {

    const { typename, setId, bookItemId, sectionHookId, sectionItemId, pageHookId, pageItemId } = params;
    const { deselect } = selection

    const onBack = () => {
        deselect()
        navigate({ 
            typename:'Page', setId, bookItemId, sectionHookId, sectionItemId
        });
    };

    const toolbar = <SelectorToolbar onBack={onBack} typename='Page' selection={selection}/>
    const first = <SelectorFirst typename={typename} itemId={pageItemId} hookId={pageHookId} selection={selection}/>

    const blockList = {typename, type:'list', id:pageItemId, fetchId:pageHookId};
    const fetchList = api.book.useFetch(blockList);
    const list = fetchList();

    if (abs.isError(list)) return <ThrowErrorModal name='bookSelector' data={{data:blockList, params, modalId}}/>
    if (abs.isEmpty(list)) return <SelectorEmpty typename={typename} toolbar={toolbar} first={first}/>
    if (!abs.isArray(list)) return null;

    return (<>
        {toolbar}
        <View style={[s.scrollView]}>
        <ScrollView style={[s.scrollView]}>
            {first}
            {list.map((current, index) => {
                const blockHookId = current.hookId;
                const blockItemId = current.itemId;
                const hook = {typename, type:'hook', id:blockHookId, fetchId:pageHookId};
                const item = {typename, type:'item', id:blockItemId, fetchId:pageHookId};
                return (
                    <Block
                        modalId={modalId}
                        selection={selection}
                        params={{...params, blockHookId, blockItemId}}
                        block={{list:blockList, hook, item}}
                        navigate={navigate}
                        position={index+1}
                        key={current.hookId} 
                    />
                );
            })}
        </ScrollView>
        </View>
    </>);
}; 
export default Blocks;