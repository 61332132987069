import { View, ScrollView } from 'react-native';
import { s, abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { MenuLink } from '../../../../modules/basics/menus';
import Sections from './Sections.js';

const Book = ({ url, typename, params, book }) => {

    const fetchItem = api.book.useFetch(book.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorMain name='bookPage' data={book.item}/>

    const { bookItemId } = params;
    const childTypename = abs.book.childOf(typename);
    const childList = {typename:childTypename, type:'list', id:bookItemId, fetchId:bookItemId};

    return (
        <>
            <MenuLink 
                linkTo={abs.book.link('overview', params)} 
                svg='back_16' 
                WL text='WL_book_myBooks' 
            />
            <MenuLink 
                linkTo={abs.book.link('cover', params)} 
                isActive={abs.book.isUrlCover(url)}
                svg='book_16' 
                WL text='WL_book_cover' 
            />
            <MenuLink 
                linkTo={abs.book.link('index', params)} 
                isActive={abs.book.isUrlIndex(url)}
                svg='index_16' 
                WL text='WL_book_index' 
            />
            <View style={s.scrollView}>
            <ScrollView>
                <Sections
                    url={url}
                    typename={childTypename}
                    params={params}
                    section={{list:childList}}
                />
            </ScrollView>
            </View>
        </>
    );
}; 
export default Book;