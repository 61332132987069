import { useState } from 'react';
import { Loader } from './modules/loader/components';
import { Router } from './modules/router/components';

const Initial = () => {

    const start = Date.now();
    const [isLoaded, setIsLoaded] = useState(false);

    if (isLoaded) return <Router/>
    return <Loader setIsLoaded={setIsLoaded} limit={start+400}/>
}
export default Initial;