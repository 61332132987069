import { View } from 'react-native';
import { s, use } from '../../../root';
import { Link, Icon, Textline } from '../../../modules/basics/components';

const Card = ({ linkTo, title, description, isGuest }) => {

    const c = use.useColor();

    const guestText = isGuest ? 'WL_home_guest_description' : title;
    const linkColor = isGuest ? c.onMainN1_d : c.onMainN1;

    const style = {
        card:           [s.col_sXcY, s.space.mT16, s.space.mX16, s.radius.r8, c.toolbarN1],
        icon:           [s.X48Y40],
        textBox:        [s.col_sXcY, s.space.pX16, s.Xc],  
        link:           [s.row_sXcY, s.Xc, s.space.pS16, s.space.pE8, s.radius.b8, c.toolbarN2],  
        title:          [s.space.mY8, s.Xc, {lineHeight:24, fontWeight:'bold'}],
        description:    [s.space.mB8, s.Xc, {lineHeight:24}],
        linkTitle:      [s.space.mY8, s.Xr, {lineHeight:24, fontWeight:'bold'}],
        guest:          [s.space.mB8, {lineHeight:24, fontWeight:'bold'}],
    };
    
    return (
        <View >
            <View style={style.card}>
                <View style={style.textBox}>
                    <Textline style={style.title} color={c.onMainN1} WL text={title}/>
                    <Textline style={style.description} color={c.onMainN1} lines={10} WL text={description}/>
                </View>     
                <Link style={style.link} linkTo={linkTo} isDisabled={isGuest}>
                    <Textline style={style.linkTitle} color={linkColor} WL text={guestText}/>
                    <Icon style={style.icon} color={linkColor} svg='open_16'/>
                </Link>
            </View>
        </View>
    );
};
export default Card;