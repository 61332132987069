import gql from './gql/index.js';
import { apollo } from '../../../../clients';
import { abs, store } from '../../../../root';

const fetchData = async (type, id, dataHookId, isRefetch) => {

    console.log(
        '\n fetchData', 
        '\n type ', type, 
        '\n id ', id, 
        '\n dataHookId ', dataHookId, 
        '\n isRefetch ', isRefetch,
        '\n\n'
    );

    try { 

        // if the cache does not contain the element or contains an invalid value, we need to fetch it
        if (isRefetch || abs.isInvalid(store.book.cache[type][id])) {

            // check the connection, the token and if the fetchId is present
            if (!(await abs.isNetwork())) abs.errorThrow('if (!(await abs.isNetwork()))');
            if (!(await abs.isAuth())) abs.errorThrow('if (!(await abs.isAuth()))');
            if (!dataHookId) abs.errorThrow('if (!dataHookId)');
    
            // fetch the block
            const response = await apollo.query ({
                query: gql.fetchData, 
                variables: {input: {id: dataHookId}}
            });

            // if there is an error throw an error
            if (response?.errors?.length) abs.errorThrow('if (response.errors?.length)', response.errors);

            // extract the data in a variable
            const data = response.data.fetchData;

            // clone the data hook and item, add a bond between item and hook
            store.book.cache.hook[data.id] = abs.book.clone.DataHook(data);
            store.book.cache.item[data.item.id] = abs.book.clone.DataItem(data.item);
            abs.book.bond.add(data.item.id, data.id);

        };

        // cleanup the operation in any case
        store.router.params = { };
        store.router.operation = '';
        store.router.operationId = '';

        console.log(
            '\n fetchData return',
            '\n type ', type,
            '\n id ', id,
            '\n store.book.cache ', store.book.cache,
            '\n store.book.cache[type][id] ', store.book.cache[type][id],
            '\n\n'
        )

        // at this point data are in the cache in both cases
        return store.book.cache[type][id];

    } catch (error) {return abs.errorCatch('App/app/book/api/fetch/fetchData', error)}
};
export default fetchData;
