import { Suspense } from 'react';
import { Page, Button } from './pods';

const ErrorPageMissing = ({ auth, network, isNetworkOk, isAuthOk, source }) => {

    console.log(
        'ErrorPageMissing source', source,
        'isNetworkOk ', isNetworkOk, 
        'isAuthOk ', isAuthOk, 
    )

    const mainButton = !isNetworkOk ? network : !isAuthOk ? auth : null;
    
    const networkMessage = !isNetworkOk || isAuthOk==null ? 'WL_error_network_no' : 'WL_error_pageMissing_network_ok';
    const authMessage = !isNetworkOk || isAuthOk==null ? 'WL_error_auth_pending' : !isAuthOk ? 'WL_error_auth_no' : 'WL_error_auth_ok';
    const message = !isNetworkOk || isAuthOk==null ? 'WL_error_network_message' : !isAuthOk ? 'WL_error_pageMissing_auth' : 'WL_error_pageMissing';

    return (
        <Page
            hasHome
            networkMessage={networkMessage}
            authMessage={authMessage}
            message={message}
        >
            <Suspense fallback={<Button text='WL_error_loading' isMain/>}>
                {mainButton}
            </Suspense>
        </Page>
    );
};
export default ErrorPageMissing;