import { Pressable } from 'react-native';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useNavigate } from '../../../platform/router';
import { Textline, Icon } from '../../../modules/basics/components';
import { s, use, store } from '../../../root';

const MenuLink = ({ linkTo, WL, text, svg, textual, isActive, isFlip }) => {

    const c = use.useColor();

    const navigate = useNavigate();

    const error = useRecoilValue(store.error.main);
    const isError = error?.name;

    const size = useRecoilValue(store.layout.size);
    const [isSideOneOn, setIsSideOneOn] = useRecoilState(store.layout.isSideOneOn);
    const [isSideTwoOn, setIsSideTwoOn] = useRecoilState(store.layout.isSideTwoOn);

    const isOneColumn   = size === '1';
    const isThreeColumn = size === '3';

    const onPress = () => {
        if(isSideOneOn && isOneColumn) setIsSideOneOn(false)
        if(isSideTwoOn && !isThreeColumn) setIsSideTwoOn(false)
        if(!isError) navigate(linkTo)
    };

    const row = isFlip ? s.rowR_sXcY : s.row_sXcY;
    const color = isActive ? c.onBarC1 : c.onBarN1;
    const textualColor = isActive ? c.onBarC1 : c.onBarN1;

    const style = {
        link:       {textDecoration:'none'},
        line:       [row, s.Y40, s.space.mB2, isError ? {opacity:0.3} : null],
        icon:       [s.X48Y40],
        textual:    [s.row_sXcY, s.Y40, s.space.mS48, s.space.pE8, {fontWeight:'bold', fontSize:12}],
        text:       [s.space.pS4, s.space.pE16,, {lineHeight:24, fontWeight:'bold'}],
    };
    
    return ( 
        <Pressable style={style.line} onPress={onPress}>
            { svg 
            ? <Icon style={style.icon} color={color} svg={svg}/> 
            : <Textline style={style.textual} color={textualColor} text={textual}/>
            }
            <Textline style={style.text} color={color} WL={WL} text={text}/>
        </Pressable>
    );
};
export default MenuLink;