import jwt_decode from "jwt-decode";
import { useSetRecoilState } from 'recoil';
import { api, store } from '../../../root';
import * as localStorage from '../../../platform/localStorage';

const useLogin = () => {

    const setUser = useSetRecoilState(api.user.user);

    const login = async (token) => {

        const { accessToken, refreshToken } = token;
        const tokenDecoded = jwt_decode(accessToken);

        await localStorage.set('accessToken', accessToken);
        await localStorage.set('refreshToken', refreshToken);

        store.user.accessToken = accessToken;
        store.user.refreshToken = refreshToken;

        setUser({
            id:tokenDecoded?.sub,
            email:tokenDecoded?.email,
        });

    };

    return login;
};
export default useLogin;