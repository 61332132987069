import { View, Pressable } from 'react-native';
import { s, use } from '../../../root';

const Slider = ({ onPress, isOn }) => {

    const c = use.useColor();

    const style = {
        slider: [s.row_cXcY, s.XY48],
        box: isOn ? [s.row_eXcY, s.X32Y20, s.radius.r16, c.sliderC1] : [s.row_sXcY, s.X32Y20, s.radius.r16, c.sliderN1],
        circle: [s.XY16, s.space.mX2, s.radius.r16, c.mainN1],
    };

    return (
        <View style={style.slider}>
            <Pressable onPress={onPress}>
                <View style={style.box}>
                    <View style={style.circle}></View>
                </View>
            </Pressable>
        </View>
    );
};
export default Slider;