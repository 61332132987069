import { useRecoilValue } from 'recoil';
import { store } from '../../../../root';
import * as atom from './atom.js';

const useFetch = (data) => {

    const { typename, type, id } = data;
    store.router.params = data;
    const value = useRecoilValue(atom[typename]([type, id]));
    const fetch = () => value
    return fetch;

};
export default useFetch;