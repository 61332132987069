import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateBookMetadata = gql`

    ${fragment.book.item}

    mutation updateBookMetadata ($input: editMetadata!) {
        updateBookMetadata(input: $input) {
            ...bookItemFields
        }
    }
`;

export default updateBookMetadata;