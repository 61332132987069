import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { store, abs } from '../../../../root';
import { Modal } from '../../../../modules/modal/components';
import { AgentWrite } from '../../../../app/book/agents.js';
import { ModalTextField } from '../../../../app/book/suits.js';

const NewBook = ({ id, isNavigate }) => {

    const typename = 'Book';

    const popover = useRecoilValue(store.popover.popover)
    const { setId, navigateTo } = popover;

    const path = isNavigate ? navigateTo : null;

    const [operationId, setOperationId] = useState('');

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [metadata, setMetadata] = useState('');
    const [cover, setCover] = useState('');
    const [logo, setLogo] = useState('');

    const save = () => {
        const input = { cover, description, logo, metadata, title };
        const operation = 'create';
        const list = {typename, type:'list', id:setId, fetchId:setId};
        const generatedId = abs.book.operation.add({typename, operation, input, list});
        setOperationId(generatedId);
    };

    const modalTitle = 'WL_book_BookCreate';
    const modalSvg = 'book_16_fill_24';

    const refetch = {refetch:'reorder', operationId, modalId:id, navigateTo:path};

    const actions = <AgentWrite operationId={operationId} action={save} refetch={refetch} isDisabled={!title}/>
    
    return (
        <Modal id={id} title={modalTitle} svg={modalSvg} actions={actions}>
            <ModalTextField typename={typename} field={'title'} value={title} setValue={setTitle}/>
            <ModalTextField typename={typename} field={'description'} value={description} setValue={setDescription}/>
            <ModalTextField typename={typename} field={'metadata'} value={metadata} setValue={setMetadata}/>
            <ModalTextField typename={typename} field={'cover'} value={cover} setValue={setCover}/>
            <ModalTextField typename={typename} field={'logo'} value={logo} setValue={setLogo}/>
        </Modal>
    );
}; 
export default NewBook;