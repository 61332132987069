import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const fetchSet = gql`

    ${fragment.book.item}

    query fetchSet ($input: list) {
        fetchSet(input: $input) {
            ...bookItemFields
        }
    }
`;
export default fetchSet;