import { store } from '../../../root';

const generate = {

    newElement: (operationId) => { // >> problema con lo userId

        // // only the user can create something
        // const author_id = store.user.userId;

        // // get the operationIds using operationId
        // const {element, data, typename} = store.book.operation[operationId];

        // // get the data and ids needed to generate the hook and the item
        // const {type, value, key} = data;
        // const {hookId, itemId, parentItemId} = element;

        // const hook = {
        //     id: hookId, 
        //     item_id: itemId, 
        //     parent_id: parentItemId, 
        //     display:true, 
        //     author_id, 
        //     operationId,
        //     isNew:true
        // };

        // const item = {
        //     id: itemId, 
        //     value: value ? value : '',
        //     type,
        //     author_id, 
        //     operationId,
        //     isNew:true
        // };

        // // if is Data add the key property
        // if (typename === 'Data') item.key = key ? key : '';
        
        // return {typename, hook, item};
    },
};
export default generate;