import popover from './abs/popover';
import * as operation from './abs/operation';
import * as clone from './abs/clone';

export { popover, operation, clone };

export const link = (type, params) => {

    const { 
        setId, 
        pictureId,
        // bookItemId, 
        // sectionHookId, 
        // sectionItemId,
        // pageHookId, 
        // pageItemId,
        // blockHookId, 
        // blockItemId,
        // dataHookId, 
        // dataItemId,
    } = params;

    const links = {
        overview: `/set/${setId}/pictures/overview`, 
        pictures: `/set/${setId}/pictures/list`, 
        publications: `/set/${setId}/pictures/publications`, 
        bookmarks: `/set/${setId}/pictures/bookmarks`, 
        picture: `/set/${setId}/picture/${pictureId}`, 
        // cover: `/set/${setId}/picture/${pictureItemId}/cover`,
        // index: `/set/${setId}/picture/${pictureItemId}/index`,
        // page: `/set/${setId}/picture/${pictureItemId}/section/${sectionHookId}/${sectionItemId}/page/${pageHookId}/${pageItemId}`,
    };

    return links[type];

};


export const isUrlPictures = (url) => url.split('/')[3] === 'pictures'; 
export const isUrlPicturesOverview = (url) => url.split('/')[4] === 'overview';
export const isUrlPicturesList= (url) => url.split('/')[4] === 'list';
export const isUrlPicturesPublications = (url) => url.split('/')[4] === 'publications';
export const isUrlPicturesBookmarks = (url) => url.split('/')[4] === 'bookmarks';

export const params = (url) => {

    const urlSplit = url.split('/');
    
    const result = {
        url,
        isPictures: urlSplit[3] === 'pictures',
        isPicture: urlSplit[3] === 'picture',
    };
    
    result.setId = urlSplit[2];
    
    if (result.isPicture) {
        result.pictureId = urlSplit[4];
    };
    return result;
};