import { atomFamily, selectorFamily } from 'recoil';
import { fetchSet } from '../../api.js';
import { store } from '../../../../root';

export const Picture = atomFamily({
    key: 'picture', 
    default: selectorFamily ({ 
        key: 'getPicture',
        get: ([type, id]) => async () => {
            const { fetchId } = store.router.params
            return await fetchSet(type, id, fetchId);
        }
    }),
});


    


    // picture: {

    //     list: atomFamily ({
    //         key: 'mediaList',
    //         default: selectorFamily ({
    //             key: 'getMediaList',
    //             get: (catalogId) => async () => await endpoint.list('picture', catalogId)
    //         }),
    //     }), 

    //     create: atomFamily ({
    //         key: 'mediaCreate',
    //         default: selectorFamily ({
    //             key: 'getMediaCreate',
    //             get: (operationId) => async () => await endpoint.create('picture', operationId)
    //         }),
    //     }),

    // },

    // pictures: atomFamily ({
    //     key: 'pictures',
    //     default: selectorFamily ({
    //         key: 'getPictures',
    //         get: (userId) => async () => await read.getPictures(userId)
    //     }),
    // }),

    // newPicture: atomFamily ({
    //     key: 'newPicture',
    //     default: selectorFamily ({
    //         key: 'getNewPicture',
    //         get: (file) => async () => {
    //             return await write (file);
    //         }
    //     }),
    // }),