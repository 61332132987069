import { v4 as getUUID } from 'uuid';
import { abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { PageElement } from '../../../../app/book/suits';
import Pages from './Pages.js';

const Section = ({ svg, typename, params={}, section={}, sectionPosition }) => {

    const fetchItem = api.book.useFetch(section.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorMain name='bookPage' data={section.item}/>

    const { bookItemId, sectionItemId } = params;

    const childTypename = abs.book.childOf(typename);
    const childSvg = abs.book.svg({typename, isChild:true});
    const childList = {typename:childTypename, type:'list', id:sectionItemId, fetchId:bookItemId};

    const id = getUUID();
    const popover = abs.book.popover({
        header: {svg, title:item.value},
        popover: {id, typename, section, sectionPosition, pagePosition:0, ...params, item},
        options: {name:'optionsSection'},
        adder: {name:'adderSectionPage'},
    });

    return (
        <>
            <PageElement 
                id={id} 
                svg={svg} 
                text={item.value} 
                options={popover.options} 
                adder={popover.adder}
            />
            <Pages
                svg={childSvg}
                typename={childTypename}
                params={params}
                page={{list:childList}}
            />
        </>
    );
}; 
export default Section;

