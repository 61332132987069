import { abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { PageEmpty } from '../../../../app/book/suits';
import Section from './Section.js';

const Sections = ({ svg, typename, params, section }) => {
 
    const fetchList = api.book.useFetch(section.list);
    const list = fetchList();

    if (abs.isError(list)) return <ThrowErrorMain name='bookPage' data={section.list}/>
    if (abs.isEmpty(list)) return <PageEmpty typename={typename} svg={svg} isOptions isAdder/>
    if (!abs.isArray(list)) return null;

    return (<>
        {list.map((current, index) => {
            const { bookItemId } = params;
            const sectionHookId = current.hookId;
            const sectionItemId = current.itemId;
            const hook = {typename, type:'hook', id:sectionHookId, fetchId:bookItemId};
            const item = {typename, type:'item', id:sectionItemId, fetchId:bookItemId};
            return (
                <Section
                    svg={svg}
                    typename={typename}
                    params={{...params, sectionHookId, sectionItemId}}
                    section={{list:section.list, hook, item}}
                    sectionPosition={index+1}
                    key={current.hookId} 
                />
            );
        })}
    </>);
}; 
export default Sections;