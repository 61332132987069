import { useState } from 'react';
import { TriggerItem } from '../../../../modules/popover/suits';

import { RemovePicture } from '../../../../app/picture/operations.js';

const Remove = ({ name, svg, main, second, isNavigate, isLine }) => {

    const [isRemove, setIsRemove] = useState(false)
    const onRemove = () => setIsRemove(true)

    if (isRemove) return <RemovePicture isNavigate={isNavigate}/>
    else return <TriggerItem onPress={onRemove} svg={svg} main={main} second={second} isLine={isLine}/>
};
export default Remove;