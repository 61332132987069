import Constants from 'expo-constants';
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Image, View, Platform } from 'react-native';

import { env, store, abs, use, s } from '../../../../root';
import { Modal } from '../../../../modules/modal/components';
import { Icon } from '../../../../modules/basics/components';
import { PictureFull } from '../../../../app//picture/suits';
import { AgentWrite } from '../../../../app/picture/agents.js';
import * as ImagePicker from 'expo-image-picker';

// import { ModalTextField } from '../../../../app/book/suits.js';

const NewPicture = ({ id, isNavigate }) => {

    const c = use.useColor()

    const { MEDIA_API_BASE_URL } = Constants.expoConfig.extra

    const [file, setFile] = useState(null);
    const [blob, setBlob] = useState(null);
    const [aspect, setAspect] = useState(null);

    const popover = useRecoilValue(store.popover.popover)
    const { picture, setId, navigateTo } = popover;

    const path = isNavigate ? navigateTo : null;

    const [operationId, setOperationId] = useState('');

    // const [title, setTitle] = useState('');
    // const [description, setDescription] = useState('');
    // const [metadata, setMetadata] = useState('');
    // const [cover, setCover] = useState('');
    // const [logo, setLogo] = useState('');

    // const created = await image.wordImageCreator(word,fullName);
	// 		const imageCreated = fs.readFileSync(created);
	// 		const data = new FormData();
	// 		data.append("file", imageCreated, name);
	// 		// post the image to the media server
	// 		const headers={
	// 			withCredentials: true,
	// 			headers: {
	// 				'Authorization':`${request.headers.authorization}`,
	// 				'Content-Type': 'multipart/form-data',
	// 			}
	// 		}

	// 		const mediaResponse = await axios.post(this.mediaServerUrl, data,headers);

    const save = () => {

        console.log('file > ', file)

        const data = new FormData();
        data.append('file', blob, 'picture');
        
        const input = {
            method:'POST',
            url:`${MEDIA_API_BASE_URL}/picture/create`,
            data,
        };
        const headers = {'Content-Type': 'multipart/form-data'};

        const operation = 'create';
        const generatedId = abs.picture.operation.add({operation, input, headers, list:picture.list});
        setOperationId(generatedId);

        console.log('input > ', input)
    };

    const modalTitle = 'WL_picture_Picture_create';
    const modalSvg = 'picture_16';

    const refetch = {refetch:'reorder', operationId, modalId:id, navigateTo:path};

    const actions = <AgentWrite operationId={operationId} action={save} refetch={refetch} isDisabled={!file}/>

    const pickImage = async () => {
      // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            // allowsEditing: true,
            // aspect: [4, 3],
            quality: 1,
        });
  
        console.log('result ', result);

        if (!result.canceled){ 

            const { width, height, uri } = result.assets[0];

            const image = await fetch(uri);
            const blob = await image.blob();
    
            setFile(uri); 
            setBlob(blob);
            setAspect(width/height);
        }
      
    };

    const discardImage = () => {setFile(null); setBlob(null), setAspect(null)}

    const style = {width:128, height:128, ...s.row_cXcY}
      
    return (
        <Modal id={id} title={modalTitle} svg={modalSvg} actions={actions}>
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                { file 
                ? <PictureFull src={{uri:file}} aspect={aspect} onPress={discardImage}/> 
                : <Icon style={[style]} color={c.onMainN1} svg='fileUpload_16' onPress={pickImage}/>
                }
            </View>
        </Modal>
    );
}; 
export default NewPicture;