const wordlist = {

    'WL_book_BooksOverview' : {
        en:'Books',
        it:'Libri',
    },

    'WL_book_deselect' : {
        en:'DESELECT',
        it:'DESELEZIONA',
    },

    'WL_book_books_list' : {
        en:'Books List',
        it:'Lista dei Libri',
    },

    'WL_book_books_publications' : {
        en:'Publications',
        it:'Pubblicazioni',
    },


    'WL_book_books_bookmarks' : {
        en:'Bookmarks',
        it:'Segnalibri',
    },

    'WL_book_save_and_close' : {
        en:'SAVE AND CLOSE',
        it:'SALVA E CHIUDI',
    },

    'WL_book_remove_and_close' : {
        en:'REMOVE AND CLOSE',
        it:'RIMUOVI E CHIUDI',
    },

    'WL_book_cancel' : {
        en:'CANCEL',
        it:'ANNULLA',
    },

    'WL_book_loading' : {
        en:'OPERATION IN PROCESS',
        it:'OPERAZIONE IN CORSO',
    },

    'WL_book_operationCompleted' : {
        en:'OPERATION COMPLETED',
        it:'OPERAZIONE COMPLETATA',
    },

    'WL_book_operationCompleted_remove': {
        en:'Operation Completed',
        it:'Operazione Completata',
    },

    'WL_book_title' : {
        en:'Title',
        it:'Titolo',
    },

    'WL_book_cover' : {
        en:'Cover',
        it:'Copertina',
    },

    'WL_book_title_placeholder' : {
        en:'Title...',
        it:'Titolo...',
    },

    'WL_book_description' : {
        en:'Description',
        it:'Descrizione',
    },

    'WL_book_description_placeholder' : {
        en:'Description...',
        it:'Descrizione...',
    },

    'WL_book_metadata' : {
        en:'Metadata',
        it:'Metadata',
    },

    'WL_book_metadata_placeholder' : {
        en:'Metadata...',
        it:'Metadata...',
    },

    'WL_book_cover' : {
        en:'Cover',
        it:'Cover',
    },

    'WL_book_cover_placeholder' : {
        en:'Cover...',
        it:'Cover...',
    },

    'WL_book_logo' : {
        en:'Logo',
        it:'Logo',
    },

    'WL_book_logo_placeholder' : {
        en:'Logo...',
        it:'Logo...',
    },

    'WL_book_open' : {
        en:'Open',
        it:'Apri',
    },
    
    'WL_book_index' : {
        en:'Index',
        it:'Indice',
    },

    'WL_book_booksOverview' : {
        en:'Overview',
        it:'Panoramica',
    },

    'WL_book_creator_trail' : {
        en:'Creator',
        it:'Creator',
    },

    'WL_book_myBooks' : {
        en:'My Books',
        it:'I miei Libri',
    },
    'WL_book_myBooks_description' : {
        en:'All your books are here',
        it:'Tutti i tuoi libri sono qua',
    },

    'WL_book_myPublications' : {
        en:'My Publications',
        it:'Le mie pubblicazioni',
    },
    'WL_book_myPublications_description' : {
        en:'All your published books are here',
        it:'Tutti i tuoi libri pubblicati sono qua',
    },

    'WL_book_myBookmarks' : {
        en:'My Bookmarks',
        it:'I miei bookmarks',
    },
    'WL_book_myBookmarks_description' : {
        en:'All your bookmarks are here',
        it:'Tutti i tuoi segnalibri sono qua',
    },

    'WL_book_BookCreate' : {
        en:'Create Book',
        it:'Crea Libro',
    },

    'WL_book_SectionCreate' : {
        en:'Create Section',
        it:'Crea Sezione',
    },

    'WL_book_PageCreate' : {
        en:'Create Page',
        it:'Crea Page',
    },

    'WL_book_BlockCreate' : {
        en:'Create Block',
        it:'Crea Blocco',
    },

    'WL_book_DataCreate' : {
        en:'Create Data',
        it:'Crea Dato',
    },



    'WL_book_BookRemove' : {
        en:'Remove this Book',
        it:'Rimuovi questo Libro',
    },
    'WL_book_SectionRemove' : {
        en:'Remove this Section',
        it:'Rimuovi questa Sezione',
    },
    'WL_book_PageRemove' : {
        en:'Remove this Page',
        it:'Rimuovi questa Pagina',
    },
    'WL_book_BlockRemove' : {
        en:'Remove this Block',
        it:'Rimuovi questo Blocco',
    },
    'WL_book_DataRemove' : {
        en:'Remove this Data',
        it:'Rimuovi questo Dato',
    },

    'WL_book_BookRemoveLoading' : {
        en:'Removing the Book',
        it:'Rimuovendo il Libro',
    },
    'WL_book_SectionRemoveLoading' : {
        en:'Removing the Section',
        it:'Rimuovendo la Sezione',
    },
    'WL_book_PageRemoveLoading' : {
        en:'Removing the Page',
        it:'Rimuovendo la Pagina',
    },
    'WL_book_BlockRemoveLoading' : {
        en:'Removing the Block',
        it:'Rimuovendo il Blocco',
    },
    'WL_book_DataRemoveLoading' : {
        en:'Removing the Data',
        it:'Rimuovendo il Dato',
    },



    'WL_book_BookEdit' : {
        en:'Edit this Book',
        it:'Modifica questo Libro',
    },

    'WL_book_SectionEdit' : {
        en:'Edit this Section',
        it:'Modifica questa Sezione',
    },

    'WL_book_PageEdit' : {
        en:'Edit this Page',
        it:'Modifica questa Page',
    },

    'WL_book_BlockEdit' : {
        en:'Edit this Block',
        it:'Modifica questo Blocco',
    },

    'WL_book_DataEdit' : {
        en:'Edit this Data',
        it:'Modifica questo Dato',
    },


    
    'WL_book_SetBack' : { 
        en:'Home',
        it:'Home',
    },
    'WL_book_BookBack' : { 
        en:'Back to Set',
        it:'Indietro a Catalogo',
    },
    'WL_book_SectionBack' : { 
        en:'Back to Sections',
        it:'Indietro a Sezioni',
    },
    'WL_book_PageBack' : { 
        en:'Back to Pages',
        it:'Indietro a Pagine',
    },
    'WL_book_BlockBack' : { 
        en:'Back to Blocks',
        it:'Indietro a Blocchi',
    },
    'WL_book_DataBack' : { 
        en:'Back to Datas',
        it:'Indietro a Dati',
    },


    'WL_book_BookNew' : { 
        en:'Create Book',
        it:'Crea Libro',
    },
    'WL_book_SectionNew' : { 
        en:'Create Section',
        it:'Crea Sezione',
    },
    'WL_book_PageNew' : { 
        en:'Create Page',
        it:'Crea Pagina',
    },
    'WL_book_BlockNew' : { 
        en:'Create Block',
        it:'Crea Blocco',
    },
    'WL_book_DataNew' : { 
        en:'Create Data',
        it:'Crea Dato',
    },



    'WL_book_SectionHook' : { 
        en:'Hook this Section',
        it:'Hook questa Sezione',
    },
    'WL_book_PageHook' : { 
        en:'Hook this Page',
        it:'Hook questa Pagina',
    },
    'WL_book_BlockHook' : { 
        en:'Hook this Block',
        it:'Hook questo Blocco',
    },
    'WL_book_DataHook' : { 
        en:'Hook this Data',
        it:'Hook questo Dato',
    },



    'WL_book_SectionMove' : { 
        en:'Move this Section',
        it:'Sposta questa Sezione',
    },
    'WL_book_PageMove' : { 
        en:'Move this Page',
        it:'Sposta questa Pagina',
    },
    'WL_book_BlockMove' : { 
        en:'Move this Block',
        it:'Sposta questo Blocco',
    },
    'WL_book_DataMove' : { 
        en:'Move this Data',
        it:'Sposta questo Dato',
    },



    'WL_book_SectionCopy' : { 
        en:'Copy this Section',
        it:'Duplica questa Sezione',
    },
    'WL_book_PageCopy' : { 
        en:'Copy this Page',
        it:'Duplica questa Pagina',
    },
    'WL_book_BlockCopy' : { 
        en:'Copy this Block',
        it:'Duplica questa Blocco',
    },
    'WL_book_DataCopy' : { 
        en:'Copy this Data',
        it:'Duplica questa Dato',
    },




    'WL_book_SectionHookIn' : { 
        en:'Hook a Section',
        it:'Hook una Sezione',
    },
    'WL_book_PageHookIn' : { 
        en:'Hook a Page',
        it:'Hook una Pagina',
    },
    'WL_book_BlockHookIn' : { 
        en:'Hook a Block',
        it:'Hook un Blocco',
    },
    'WL_book_DataHookIn' : { 
        en:'Hook a Data',
        it:'Hook un Data',
    },



    'WL_book_SectionMoveIn' : { 
        en:'Move a Section',
        it:'Sposta una Sezione',
    },
    'WL_book_PageMoveIn' : { 
        en:'Move a Page',
        it:'Sposta una Pagina',
    },
    'WL_book_BlockMoveIn' : { 
        en:'Move a Block',
        it:'Sposta un Blocco',
    },
    'WL_book_DataMoveIn' : { 
        en:'Move a Data',
        it:'Sposta un Data',
    },



    'WL_book_SectionCopyIn' : { 
        en:'Copy a Section',
        it:'Duplica una Sezione',
    },
    'WL_book_PageCopyIn' : { 
        en:'Copy a Page',
        it:'Duplica una Pagina',
    },
    'WL_book_BlockCopyIn' : { 
        en:'Copy a Block',
        it:'Duplica un Blocco',
    },
    'WL_book_DataCopyIn' : { 
        en:'Copy a Data',
        it:'Duplica un Dato',
    },





    'WL_book_BookListEmpty' : {
        en:'The Books list is empty',
        it:'La lista dei Libri é vuota',
    },
    'WL_book_SectionListEmpty' : {
        en:'The Sections list is empty',
        it:'La lista delle Sezioni é vuota',
    },
    'WL_book_PageListEmpty' : {
        en:'The Pages list is empty',
        it:'La lista delle Pagine é vuota',
    },
    'WL_book_BlockListEmpty' : {
        en:'The Blocks list is empty',
        it:'La lista dei Blocchi é vuota',
    },
    'WL_book_DataListEmpty' : {
        en:'The Data list is empty',
        it:'La lista dei Dati é vuota',
    },



    'WL_book_BookLoading' : {
        en:'The Book is loading..',
        it:'Il libro sta caricando',
    },
    'WL_book_SectionLoading' : {
        en:'The Section is loading..',
        it:'La Sezione sta caricando',
    },
    'WL_book_PageLoading' : {
        en:'The Page is loading..',
        it:'La Pagina sta caricando',
    },
    'WL_book_BlockLoading' : {
        en:'The Block is loading..',
        it:'Il Blocco sta caricando',
    },
    'WL_book_DataLoading' : {
        en:'The Data is loading..',
        it:'Il Dato sta caricando',
    },




    'WL_book_BookListLoading' : {
        en:'The Books list is loading..',
        it:'La lista di Libri sta caricando',
    },
    'WL_book_SectionListLoading' : {
        en:'The Sections list is loading..',
        it:'La lista di Sezioni sta caricando',
    },
    'WL_book_PageListLoading' : {
        en:'The Pages list is loading..',
        it:'La lista di Pagine sta caricando',
    },
    'WL_book_BlockListLoading' : {
        en:'The Blocks list is loading..',
        it:'La lista di Blocchi sta caricando',
    },
    'WL_book_DataListLoading' : {
        en:'The Data list is loading..',
        it:'La lista di Dati sta caricando',
    },



    'WL_book_BookCreate' : {
        en:'Create Book',
        it:'Crea Libro',
    },
    'WL_book_BookUpdate' : {
        en:'Edit Book',
        it:'Modifica Libro',
    },
    'WL_book_Book_title' : {
        en:'Book Title',
        it:'Titolo del Libro',
    },
    'WL_book_Book_titlePlaceholder' : {
        en:'Title...',
        it:'Titolo...',
    },
    'WL_book_Book_description' : {
        en:'Book Description',
        it:'Descrizione del Libro',
    },
    'WL_book_Book_descriptionPlaceholder' : {
        en:'Description...',
        it:'Descrizione...',
    },
    'WL_book_Book_metadata' : {
        en:'Book Metadata',
        it:'Metadati del Libro',
    },
    'WL_book_Book_metadataPlaceholder' : {
        en:'Metadata...',
        it:'Metadati...',
    },
    'WL_book_Book_cover' : {
        en:'Book Cover',
        it:'Cover del Libro',
    },
    'WL_book_Book_coverPlaceholder' : {
        en:'Cover...',
        it:'Cover...',
    },
    'WL_book_Book_logo' : {
        en:'Book Logo',
        it:'Logo del Libro',
    },
    'WL_book_Book_logoPlaceholder' : {
        en:'Logo...',
        it:'Logo...',
    },




    'WL_book_SectionCreate' : {
        en:'Create Section',
        it:'Crea Sezione',
    },
    'WL_book_SectionUpdate' : {
        en:'Edit Section',
        it:'Modifica Sezione',
    },
    'WL_book_Section_value' : {
        en:'Section Title',
        it:'Titolo della Sezione',
    },
    'WL_book_Section_valuePlaceholder' : {
        en:'Title...',
        it:'Titolo...',
    },
    'WL_book_Section_metadata' : {
        en:'Section Metadata',
        it:'Metadati della Sezione',
    },
    'WL_book_Section_metadataPlaceholder' : {
        en:'Metadata...',
        it:'Metadati...',
    },




    'WL_book_PageCreate' : {
        en:'Create Page',
        it:'Crea Pagina',
    },
    'WL_book_PageUpdate' : {
        en:'Edit Page',
        it:'Modifica Pagina',
    },
    'WL_book_Page_value' : {
        en:'Page Title',
        it:'Titolo della Pagina',
    },
    'WL_book_Page_valuePlaceholder' : {
        en:'Title...',
        it:'Titolo...',
    },
    'WL_book_Page_metadata' : {
        en:'Page Metadata',
        it:'Metadati della Pagina',
    },
    'WL_book_Page_metadataPlaceholder' : {
        en:'Metadata...',
        it:'Metadati...',
    },




    'WL_book_BlockCreate' : {
        en:'Create Block',
        it:'Crea Blocco',
    },
    'WL_book_BlockUpdate' : {
        en:'Edit Block',
        it:'Modifica Blocco',
    },
    'WL_book_Block_value' : {
        en:'Block Title',
        it:'Titolo del Blocco',
    },
    'WL_book_Block_valuePlaceholder' : {
        en:'Title...',
        it:'Titolo...',
    },
    'WL_book_Block_metadata' : {
        en:'Block Metadata',
        it:'Metadati del Blocco',
    },
    'WL_book_Block_metadataPlaceholder' : {
        en:'Metadata...',
        it:'Metadati...',
    },



    'WL_book_DataCreate' : {
        en:'Create Data',
        it:'Crea Dati',
    },
    'WL_book_DataUpdate' : {
        en:'Edit Data',
        it:'Modifica Dati',
    },
    'WL_book_Data_type' : {
        en:'Type',
        it:'Tipo',
    },
    'WL_book_Data_typePlaceholder' : {
        en:'Type...',
        it:'Tipo...',
    },
    'WL_book_Data_key' : {
        en:'Key',
        it:'Chiave',
    },
    'WL_book_Data_keyPlaceholder' : {
        en:'Key...',
        it:'Chiave...',
    },
    'WL_book_Data_value' : {
        en:'Value',
        it:'Valore',
    },
    'WL_book_Data_valuePlaceholder' : {
        en:'Value...',
        it:'Valore...',
    },
    'WL_book_Data_metadata' : {
        en:'Metadata',
        it:'Metadati',
    },
    'WL_book_Data_metadataPlaceholder' : {
        en:'Metadata...',
        it:'Metadati...',
    },


    'WL_book_create' : {
        en:'Create',
        it:'Crea',
    },
    'WL_book_remove' : {
        en:'Remove',
        it:'Rimuovi',
    },
    'WL_book_to_remove' : {
        en:'to remove',
        it:'per rimuovere',
    },

    'WL_book_confirm' : {
        en:'CONFIRM',
        it:'CONFERMA',
    },
    'WL_book_hook' : {
        en:'Hook',
        it:'Collega',
    },
    'WL_book_move' : {
        en:'Move',
        it:'Sposta',
    },
    'WL_book_copy' : {
        en:'Copy',
        it:'Copia',
    },
    'WL_book_open' : {
        en:'Open',
        it:'Apri',
    },
    'WL_book_expand' : {
        en:'Expand',
        it:'Espandi',
    },
    'WL_book_edit' : {
        en:'Edit',
        it:'Modifica',
    },
    'WL_book_collapse' : {
        en:'Collapse',
        it:'Contrai',
    },

    'WL_book_a_Book' : {
        en:'a book',
        it:'un libro',
    },
    'WL_book_a_Section' : {
        en:'a section',
        it:'una sezione',
    },
    'WL_book_a_Page' : {
        en:'a page',
        it:'una pagina',
    },
    'WL_book_a_Block' : {
        en:'a block',
        it:'un blocco',
    },
    'WL_book_a_Data' : {
        en:'a data',
        it:'un dato',
    },

    
    'WL_book_a_new_Book' : {
        en:'a new book',
        it:'un nuovo libro',
    },
    'WL_book_a_new_Section' : {
        en:'a new section',
        it:'una nuova sezione',
    },
    'WL_book_a_new_Page' : {
        en:'a new page',
        it:'una nuova pagina',
    },
    'WL_book_a_new_Block' : {
        en:'a new block',
        it:'un nuovo blocco',
    },
    'WL_book_a_new_Data' : {
        en:'a new data',
        it:'un nuovo dato',
    },


    'WL_book_this_Book' : {
        en:'this book',
        it:'questo libro',
    },
    'WL_book_this_Section' : {
        en:'this section',
        it:'questa sezione',
    },
    'WL_book_this_Page' : {
        en:'this page',
        it:'questa pagina',
    },
    'WL_book_this_Block' : {
        en:'this block',
        it:'questo blocco',
    },
    'WL_book_this_Data' : {
        en:'this data',
        it:'questo dato',
    },

    'WL_book_Book' : {
        en:'Book',
        it:'Libro',
    },
    'WL_book_Section' : {
        en:'Section',
        it:'Sezione',
    },
    'WL_book_Page' : {
        en:'Page',
        it:'Pagina',
    },
    'WL_book_Block' : {
        en:'Block',
        it:'Blocco',
    },
    'WL_book_Data' : {
        en:'Data',
        it:'Dato',
    },

    'WL_book_the_BlockList' : {
        en:'the blocks',
        it:'i blocchi',
    },

    'WL_book_the_BookCover' : {
        en:'the book cover',
        it:'la cover del libro',
    },

    'WL_book_the_BookIndex' : {
        en:'the book index',
        it:"l'indice del libro",
    },

    'WL_book_the_BookPage' : {
        en:'the page',
        it:"la pagina",
    },

    'WL_book_the_Book' : {
        en:'the book',
        it:"il libro",
    },

    'WL_book_operationError' : {
        en:'Operation failed',
        it:'Operazione fallita',
    },

    // 'WL_book_SectionHookIn': {
    //     en:'Select the Section to be Hooked',
    //     it:`Selezione la Sezione di cui fare l'hook`,
    // },
    // 'WL_book_PageHookIn': {
    //     en:'Select the Page to be Hooked',
    //     it:`Selezione la Pagina di cui fare l'hook`,
    // },
    // 'WL_book_BlockHookIn': {
    //     en:'Select the Block to be Hooked',
    //     it:`Selezione il Blocco di cui fare l'hook`,
    // },
    // 'WL_book_DataHookIn': {
    //     en:'Select the Data to be Hooked',
    //     it:`Selezione il Data di cui fare l'hook`,
    // },


    // 'WL_book_SectionMoveIn': {
    //     en:'Select the Section to be Moved',
    //     it:`Selezione la Sezione da Spostare`,
    // },
    // 'WL_book_PageMoveIn': {
    //     en:'Select the Page to be Moved',
    //     it:`Selezione la Pagina da Spostare`,
    // },
    // 'WL_book_BlockMoveIn': {
    //     en:'Select the Block to be Moved',
    //     it:`Selezione il Blocco da Spostare`,
    // },
    // 'WL_book_DataMoveIn': {
    //     en:'Select the Data to be Moved',
    //     it:`Selezione il Data da Spostare`,
    // },



    // 'WL_book_SectionCopyIn': {
    //     en:'Select the Section to be Copied',
    //     it:`Selezione la Sezione da Copiare`,
    // },
    // 'WL_book_PageCopyIn': {
    //     en:'Select the Page to be Copied',
    //     it:`Selezione la Pagina da Copiare`,
    // },
    // 'WL_book_BlockCopyIn': {
    //     en:'Select the Block to be Copied',
    //     it:`Selezione il Blocco da Copiare`,
    // },
    // 'WL_book_DataCopyIn': {
    //     en:'Select the Data to be Copied',
    //     it:`Selezione il Data da Copiare`,
    // },





    'WL_book_removed' : {
        en:'Removed!',
        it:'Rimosso!',
    },

    'WL_book_operation_ok' : {
        en:'COMPLETED',
        it:'COMPLETATO',
    },




    'WL_book_mySet' : { 
        en:'My Set',
        it:'Il mio Catalogo',
    },



    'WL_book_createFirstBook' : { 
        en:'Create the First Book',
        it:'Crea il Primo Libro',
    },

    'WL_book_retry' : { 
        en:'An error occurred, please Retry',
        it:"C'é stato un errore, per favore Riprova",
    },













    'WL_book_PageCollapse' : {
        en:'Hide Blocks',
        it:'Nascondi Blocchi',
    },

    'WL_book_PageExpand' : {
        en:'Show Blocks',
        it:'Mostra Blocchi',
    },


    'WL_book_open_cover' : {
        en:'Open Book Cover',
        it:'Apri la Copertina',
    },

    'WL_book_open_index' : {
        en:'Open Book Index',
        it:"Apri l'Indice",
    },

    'WL_book_open_page' : {
        en:'Open Book Page',
        it:"Apri la pagina",
    },

    'WL_book_open_book' : {
        en:'Open Book',
        it:"Apri il libro",
    },

};
export default wordlist;