import { Pressable, View } from 'react-native';
import { TextTwin, Icon } from '../../../../modules/basics/components';
import { s, use } from '../../../../root';

const Item = ({ onPress, svg, main, second, isLine }) => {

    const c = use.useColor();

    const style = {
        item: [s.col],
        body: [s.row_sXcY, s.Y40],
        line: [s.Y2, s.Xc, isLine ? c.dividerN1 : null],
        icon: [s.X48Y40],
        text: [s.row_sXcY, {fontWeight:'bold'}],
        twin: [s.row_sXcY, s.Xr],
    };

    const text = {style:style.text, color:c.onMainN1, WL:true, text:main};
    const twin = {style:style.twin, color:c.onMainN1_1, WL:true, text:second};

    return ( 
        <Pressable style={style.item} onPress={onPress}>
            <View style={style.body}>
                <Icon style={style.icon} color={c.onMainN1} svg={svg}/>
                <TextTwin text={text} twin={twin}/>
            </View>
            <View style={style.line}></View>
        </Pressable>
    );
};
export default Item;