import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const fetchData = gql`

    ${fragment.data.hook}
    ${fragment.data.item}

    query fetchData ($input: id!) {
        fetchData(input: $input) {
            ...dataHookFields 
            item {
                ...dataItemFields
            } 
        }
    }
`;
export default fetchData;