const wordlist = {

    'WL_error_message' : {
        en:'The error occourred at',
        it:"L'errore si é verificato alle ore",
    },

    'WL_error_retry' : {
        en:'RETRY',
        it:'RIPROVA',
    },

    'WL_error_network_no' : {
        en:'You are offline',
        it:'Sei Offline',
    },

    'WL_error_network_ok' : {
        en:'You are online!',
        it:"Sei online!",
    },

    'WL_error_auth_pending' : {
        en:'Waiting for connection...',
        it:"In attesa della connessione...",
    },

    'WL_error_auth_no' : {
        en:'You are not autenticathed',
        it:"Non sei autenticato",
    },

    'WL_error_auth_ok' : {
        en:'You are authenticated',
        it:"Sei autenticato",
    },

    'WL_error_home' : {
        en:'HOME',
        it:"HOME",
    }, 

    'WL_error_close' : {
        en:'CLOSE',
        it:"CLOSE",
    }, 

    'WL_error_network' : {
        en:'CHECK',
        it:"PROVA",
    }, 

    'WL_error_auth' : {
        en:'LOGIN',
        it:"LOGIN",
    }, 

    'WL_error_network_message' : {
        en:'Please connect to the network',
        it:"Per favore connettiti alla rete",
    }, 

    'WL_error_auth_message' : {
        en:'Please login',
        it:"Per favore effettua il login",
    }, 

    'WL_error_action' : {
        en:'Please retry now',
        it:"Per favore riprova ora",
    }, 

    'WL_error_cleanup' : {
        en:'Problem solved',
        it:"Problema risolto",
    }, 

    'WL_error_loading' : {
        en:'RETRYING',
        it:"STO RIPROVANDO",
    },

    'WL_error_refetch' : {
        en:'RETRY',
        it:"RIPROVA",
    },

    'WL_error_pageMissing_auth' : {
        en:"This page doesn't exist or is not public",
        it:"Questa pagina non esiste o non é pubblica",
    },

    'WL_error_pageMissing' : {
        en:"This page doesn't exist",
        it:"Questa pagina non esiste",
    },

    'WL_error_pageMissing_network_ok' : {
        en:'You are online',
        it:"Sei online",
    },

};
export default wordlist;