import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { abs, store } from '../../../../root';
import { Modal } from '../../../../modules/modal/components';
import { SelectorArrange } from '../../../../app/book/selectors';
import { AgentWrite } from '../../../../app/book/agents.js';

const ArrangeSection = ({ id, arrange, navParams={} }) => {

    const typename = 'Section';
    const popover = useRecoilValue(store.popover.popover)
    const { 
        sectionPosition,
        setId, bookItemId, 
        sectionHookId 
    } = popover;

    const [operationId, setOperationId] = useState('');

    const [start, setStart] = useState();
    const [destination_id, setDestination_id] = useState();

    const [selected, setSelected] = useState('');

    const isMove = arrange==='move';

    const save = () => {
        const input = { ids:[sectionHookId], origin_id:bookItemId, start, destination_id };
        const typename = 'Section';
        const operation = arrange;
        const origin = {typename, type:'list', id:bookItemId, fetchId:bookItemId};
        const destination = {typename, type:'list', id:destination_id, fetchId:destination_id};
        const generatedId = abs.book.operation.add({typename, operation, input, origin, destination});
        setOperationId(generatedId);
    };

    const isDisabled = !start || !destination_id; 
    const title = 'WL_book_SectionHook';
    const svg = 'S_16_line_24';

    const deselect = () => {
        setStart('');
        setDestination_id('');
        setSelected('');
    };

    const refetch = {refetch:'arrange', operationId, modalId:id};

    const actions = <AgentWrite operationId={operationId} action={save} refetch={refetch} isDisabled={isDisabled}/>

    const initial = { isMove, itemId:bookItemId, position:sectionPosition };
    const initParams = navParams.typename ? navParams:{typename, setId, bookItemId};

    const select = (selected={}) => {
        setStart(selected.start);
        setDestination_id(selected.destination_id);
    };

    const selection = { selectable:'Section', initial, select, deselect, selected, setSelected }; 

    return (
        <Modal id={id} title={title} svg={svg} actions={actions}>
            <SelectorArrange selection={selection} initParams={initParams} modalId={id}/>
        </Modal>
    );
}; 
export default ArrangeSection;