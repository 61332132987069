export const SectionHook = (hook) => ({

    id: hook.id,
    parent_id: hook.parent_id,
    item_id: hook.item_id,
    display: hook.display,
    author_id: hook.author_id,
    signature: hook.signature,
    created: hook.created,
    updated: hook.updated,

});

export const PageHook = (hook) => ({

    id: hook.id,
    parent_id: hook.parent_id,
    item_id: hook.item_id,
    display: hook.display,
    author_id: hook.author_id,
    signature: hook.signature,
    created: hook.created,
    updated: hook.updated,

});

export const BlockHook = (hook) => ({

    id: hook.id,
    parent_id: hook.parent_id,
    item_id: hook.item_id,
    display: hook.display,
    author_id: hook.author_id,
    signature: hook.signature,
    created: hook.created,
    updated: hook.updated,
    
});

export const DataHook = (hook) => ({

    id: hook.id,
    parent_id: hook.parent_id,
    item_id: hook.item_id,
    display: hook.display,
    author_id: hook.author_id,
    signature: hook.signature,
    created: hook.created,
    updated: hook.updated

});
    
export const BookItem = (item) => ({

    id: item.id, 
    title: item.title, 
    description: item.description || '', 
    cover: item.cover, 
    logo: item.logo, 
    metadata: item.metadata || '',
    author_id: item.author_id, 
    signature: item.signature, 
    created: item.created, 
    updated: item.updated
    
});

export const SectionItem = (item) => ({

    id: item.id,
    value: item.value,
    type: 'text',
    metadata: item.metadata || '',
    author_id: item.author_id,
    signature: item.signature,
    created: item.created,
    updated: item.updated, 

});

export const PageItem = (item) => ({        

    id: item.id,
    value: item.value,
    type: 'text',
    metadata: item.metadata || '',
    author_id: item.author_id,
    signature: item.signature,
    created: item.created,
    updated: item.updated,

});

export const BlockItem = (item) => ({

    id: item.id,
    value: item.value,
    type: 'text',
    metadata: item.metadata || '',
    author_id: item.author_id,
    signature: item.signature,
    created: item.created,
    updated: item.updated,

});

export const DataItem = (item) => ({

    id: item.id,
    key: item.key,
    value: item.value,
    type: item.type,
    metadata: item.metadata || '',
    author_id: item.author_id,
    signature: item.signature,
    created: item.created,
    updated: item.updated,   

});
