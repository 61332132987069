import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { abs, store } from '../../../../root';
import { Modal } from '../../../../modules/modal/components';
import { SelectorGather } from '../../../../app/book/selectors';
import { AgentWrite } from '../../../../app/book/agents.js';

const ArrangeBlock = ({ id, gather, navParams={} }) => {

    const typename = 'Block';
    const popover = useRecoilValue(store.popover.popover)
    const { 
        blockPosition,
        setId, bookItemId, 
        sectionHookId, sectionItemId, 
        pageHookId, pageItemId, 
        blockHookId,
    } = popover;

    const [operationId, setOperationId] = useState('');

    const [ids, setIds] = useState([]);
    const [origin_id, setOrigin_id] = useState();
    const [originHookId, setOriginHookId] = useState();

    const [selected, setSelected] = useState('');
 
    const isMove = gather==='move';

    const save = () => {
        const input = { ids, origin_id, start:blockPosition+1, destination_id:pageItemId };
        const typename = 'Block';
        const operation = gather;
        const origin = {typename, type:'list', id:pageItemId, fetchId:pageHookId};
        const destination = {typename, type:'list', id:origin_id, fetchId:originHookId};
        const generatedId = abs.book.operation.add({typename, operation, input, origin, destination});
        setOperationId(generatedId);
    };

    const isDisabled = !ids.length || !origin_id; 
    const title = 'WL_book_BlockHookIn';
    const svg = 'B_16_line_24';

    const deselect = () => {
        setIds('');
        setOrigin_id('');
        setSelected('');
    };

    const refetch = {refetch:'arrange', operationId, modalId:id, isGather:true};

    const actions = <AgentWrite operationId={operationId} action={save} refetch={refetch} isDisabled={isDisabled}/>

    const initial = { isMove, hookId:blockHookId };
    const initParams = navParams.typename ? navParams:{typename, setId, bookItemId, sectionHookId, sectionItemId, pageHookId, pageItemId};

    const select = (selected={}) => {
        setIds(selected.ids);
        setOrigin_id(selected.origin_id);
        setOriginHookId(selected.originHookId);
    };

    const selection = { selectable:'Block', initial, select, deselect, selected, setSelected }; 

    return (
        <Modal id={id} title={title} svg={svg} actions={actions}>
            <SelectorGather selection={selection} initParams={initParams} modalId={id}/>
        </Modal>
    );
}; 
export default ArrangeBlock;