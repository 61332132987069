import { s, use } from '../../../../root';
import { TextTwin } from '../../../../modules/basics/components';
import { AgentButton } from '../../../../app/book/suits.js';

const AgentConfirm = ({ operation, typename, action }) => {

    const c = use.useColor();

    const style = { 
        item:       [s.col],
        body:       [s.row_sXcY, s.Y40],
        line:       [s.Y2, s.Xc],
        loading:    [s.row_sXcY, s.Xr, {fontWeight:'bold'}],
        text:       [s.row_sXcY],
        twin:       [s.row_sXcY, s.Xr],
        icon:       [s.X48Y40], 
        confirm:    [s.confirm, s.Y32, {fontWeight:'bold'}, c.buttonR1]
    };

    const text = {style:style.text, color:c.onMainN1, WL:true, text:`WL_book_to_${operation}`};
    const twin = {style:style.twin, color:c.onMainN1, WL:true, text:`WL_book_this_${typename}`};

    return ( 
        <>
            <AgentButton label='WL_book_confirm' action={action} isRed/>
            <TextTwin text={text} twin={twin}/>                  
        </>
    );
}; 
export default AgentConfirm;