import { useRecoilState } from 'recoil';
import { View, FlatList } from 'react-native';
import { s, use, store } from '../../../root';
import { Textline, Slider } from '../../../modules/basics/components';
import * as localStorage from '../../../platform/localStorage';

const PreferencesPage = () => {

    const c = use.useColor();

    const [isRTL, setIsRTL] = useRecoilState(store.layout.isRTL);
    const [isDark, setIsDark] = useRecoilState(store.layout.isDark);
    const [isFixed, setIsFixed] = useRecoilState(store.layout.isFixed);

    const toggleIsRTL = () => {
        const string = !isRTL ? 'true' : 'false';
        localStorage.set('isRTL', string);
        setIsRTL(!isRTL);
    };

    const toggleIsDark = () => {
        const string = !isDark ? 'true' : 'false';
        localStorage.set('isDark', string);
        setIsDark(!isDark);
    };

    const toggleIsFixed = () => {
        const string = !isFixed ? 'true' : 'false';
        localStorage.set('isFixed', string);
        setIsFixed(!isFixed);
    };

    const style = {
        page: [s.col, s.Yc],
        item: [s.row, s.Y48, s.space.pS16, s.space.between],
        text: [s.row_sXcY, {fontWeight:'bold'}],
    };

    const data = [
        <View style={style.item}>
            <Textline style={style.text} color={c.onMainN1} WL text='WL_layout_isDark'/>
            <Slider isOn={isDark} onPress={toggleIsDark}/>
        </View>,
        <View style={style.item}>
            <Textline style={style.text} color={c.onMainN1} WL text='WL_layout_isFixed'/>
            <Slider isOn={isFixed} onPress={toggleIsFixed}/>
        </View>,
        <View style={style.item}>
            <Textline style={style.text} color={c.onMainN1} WL text='WL_layout_isRTL'/>
            <Slider isOn={isRTL} onPress={toggleIsRTL}/>
        </View>
   ];

    const renderItem = ({item}) => item

    return <FlatList style={style.page} data={data} renderItem={renderItem}/>
}; 
export default PreferencesPage;