import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateBlockMetadata = gql`

    ${fragment.block.item}

    mutation updateBlockMetadata ($input: editMetadata!) {
        updateBlockMetadata(input: $input) {
            ...blockItemFields
        }
    }
`;

export default updateBlockMetadata;