import { useState, Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { View, ScrollView } from 'react-native';
import { v4 as getUUID } from 'uuid';
import { s, abs, use, store } from '../../../../root';
import { Titlebar, LoadingList } from '../pods';
import Pictures from './Pictures';

const PictureList = ({ filter }) => {

    const t = use.useText();

    const typename = 'Picture';
    const svg = 'picture_16'

    const url = useRecoilValue(store.router.url);
    const { setId } = abs.picture.params(url);
    const params = { setId };
    const pictureList = { type:'list', id:setId, fetchId:setId };

    const id = getUUID();
    const popover = abs.picture.popover({
        header: {svg, title:t(`WL_picture_pictures_${filter}`)},
        popover: {id, typename, picture:{list:pictureList}, ...params},
        adder: {name: 'adderTitlePictureList'},
    })

    const [columns, setColums] = useState()
    const onLayout = (event) => {
        const {x, y, height, width} = event.nativeEvent.layout;
        const number = Math.ceil(width/200)
        setColums(number)

        console.log(
            'x ', x,
            'y ', y,
            'height ', height,
            'width ', width,
            'columns ', columns
        )
    }

    if (!setId) return null
    return (
        <View style={[s.scrollView]} onLayout={onLayout}>
            <Titlebar 
                id={id} 
                title={`WL_picture_pictures_${filter}`} 
                backTo={abs.picture.link('overview', params)} 
                adder={popover.adder}
            />
            {!columns ? null : 
                <Suspense fallback={<div>loading...</div>}>
                    <Pictures 
                        svg={svg}
                        typename={typename} 
                        params={params} 
                        picture={{list:pictureList}}
                        columns={columns}
                        key={setId}
                    />
                </Suspense>
            }
        </View>
    );       
};
export default PictureList;