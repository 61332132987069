import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const createData = gql`

    ${fragment.data.hook}
    ${fragment.data.item}

    mutation createData ($input: newData!) {
        createData(input: $input) {
            ...dataHookFields
            item {
                ...dataItemFields
            }       
        }
    }
`;

export default createData;
