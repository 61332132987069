import { useEffect, useState } from 'react';
import { abs, api, store } from '../../../../root';
import { ThrowErrorModal } from '../../../../modules/error/throw';
import { AgentButton } from '../../../../app/book/suits.js'
import { Cleanup } from '../../../../app/book/agents.js';

const Arrange = (props) => {

    const { operationId, isGather } = props;

    const { operation, destination, origin } = store.book.operation[operationId];
    const atomOne = isGather ? origin : destination;
    const atomTwo = isGather ? destination : origin;
    const [refetchAtomOne, setAtomOne] = api.book.useRefetch(atomOne);
    const [refetchAtomTwo, setAtomTwo] = api.book.useRefetch(atomTwo);

    const [isCleanup, setIsCleanup] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(()=> {
        const refetch = async () => {
            try {           
                const newAtomOne = await refetchAtomOne();
                if (newAtomOne==='error') abs.errorThrow('if (newAtomOne==="error")')
                else setAtomOne(newAtomOne);
                if (operation === 'move') {
                    const newAtomTwo = await refetchAtomTwo();
                    if (newAtomTwo==='error') abs.errorThrow('if (newAtomTwo==="error")')
                    else setAtomTwo(newAtomTwo);
                };
                setIsCleanup(true);
            } catch (error) {
                abs.errorCatch('App > book > agents > refetch > Arrange', error)
                setIsError(true)
            };
        };
        if (!isCleanup) refetch();
    });

    if (isError) return <ThrowErrorModal name='bookArrange' data={props}/>
    if (isCleanup) return <Cleanup {...props}/>
    return <AgentButton label='WL_book_operation_ok'/>

};
export default Arrange;