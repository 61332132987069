import { View } from 'react-native';
import { s, abs, api } from '../../../root';
import { MenuLink } from '../../../modules/basics/menus';

const Home = ({ url }) => {

    const style = { menu: [s.col, s.Yc, s.X] };
    
    return (
        <View style={style.menu}>
            <MenuLink 
                svg='home_16' 
                WL text='WL_home_home'
                linkTo={`/`} 
                isActive={abs.home.isUrlHome(url)}
            />
            <MenuLink 
                svg='creator_16' 
                WL text='WL_home_creator' 
                linkTo={'/creator'} 
                isActive={abs.home.isUrlCreator(url)}
            />
            <MenuLink 
                svg='domino_16' 
                WL text='WL_home_domino' 
                linkTo={'/domino'} 
                isActive={abs.home.isUrlDomino(url)}
            />
            <MenuLink 
                svg='language_16' 
                WL text='WL_home_language'
                linkTo={`/language`} 
                isActive={abs.home.isUrlLanguage(url)}
            />
            <MenuLink 
                svg='settings_16' 
                WL text='WL_home_preferences'
                linkTo={`/preferences`} 
                isActive={abs.home.isUrlPreferences(url)}
            />
        </View>
    );
}; 
export default Home;