import { useRecoilState } from 'recoil';
import { View } from 'react-native';
import { s, use, store, popovers } from '../../../root';
import Header from './Header.js';

const Popover = () => {

    const c = use.useColor();

    const setMargins = use.useMargins();
    const margins = setMargins();

    const [popover, setPopover] = useRecoilState(store.popover.popover)
    const close = () => setPopover({});

    const { name, header } = popover;

    const style = {
        overlay:    [s.row_eXsY, s.overlay, {zIndex:2}, c.overlayN1],
        modal:      [s.col,  s.XYr, s.space.mY64, margins],
        layout:     [s.col, s.Yc],
        body:       [s.col, s.radius.b8, c.mainN1],
    };

    if (!name) return null
    return (
        <View style={style.overlay}>
            <View style={style.modal}>
                <View style={style.layout}>
                    <Header title={header.title} svg={header.svg} close={close}/>
                    <View style={style.body}>{popovers[name]}</View>
                </View>
            </View>
        </View>
    );
}; 
export default Popover;