import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { View } from 'react-native';
import { s, abs, store } from '../../../../root';
import { LoadingPage } from '../../../../app/book/suits';
import Page from './Page.js';

const BookPage = () => {

    const typename = 'Page';
    const svg = abs.book.svg({typename});

    const url = useRecoilValue(store.router.url);
    const { setId, bookItemId, sectionHookId, sectionItemId, pageHookId, pageItemId } = abs.book.params(url);
    const params = { setId, bookItemId, sectionHookId, sectionItemId, pageHookId, pageItemId };

    const page = {
        list: {typename, type:'list', id:sectionItemId, fetchId:bookItemId},
        item: {typename, type:'item', id:pageItemId, fetchId:pageHookId, isPage:true}
    };

    if (!pageHookId) return null;

    return (
        <View style={[s.scrollView]}>
            <Suspense fallback={<LoadingPage params={params}/>}>
                <Page
                    svg={svg}
                    typename={typename}
                    params={params}
                    page={page}
                    key={`page${pageHookId}`}
                />
            </Suspense>
        </View>
    );
};
export default BookPage;