import { useLayoutEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { store } from '../../../root';

const useSetResize = () => {

    const size = useRecoilValue(store.layout.size);
    const [isSideOneOn, setIsSideOneOn] = useRecoilState(store.modal.isSideOneOn);
    const [isSideTwoOn, setIsSideTwoOn] = useRecoilState(store.modal.isSideTwoOn);

    const setResize = useLayoutEffect(() => {        
        
        const resize = () => {

            console.log('inside Modal SetSize')
            
            const isTwoColumn = size==='2';
            const isThreeColumn = size==='3';
        
            if (isThreeColumn) {
                if (!isSideOneOn) setIsSideOneOn(true);
                if (!isSideTwoOn) setIsSideTwoOn(true);
            }
            else if (isTwoColumn) {
                if (!isSideOneOn) setIsSideOneOn(true);
                if (isSideTwoOn) setIsSideTwoOn(false);
            }
            else {
                if (isSideOneOn) setIsSideOneOn(false);
                if (isSideTwoOn) setIsSideTwoOn(false); 
            };
        };
        
        resize();
        window.addEventListener('resizeModal', resize);
        return () => window.removeEventListener('resizeModal', resize);
        
    }, [size]);

    return setResize;
};
export default useSetResize;