import { View, Pressable } from 'react-native';
import { Icon } from '../../../../modules/basics/components';
import { s, use } from '../../../../root';

const First = ({ typename, itemId, hookId, selection }) => {

    const c = use.useColor();

    const { initial, selectable, select, setSelected, selected } = selection;

    const id = `${itemId}_0`;
    const invalidId = initial.isMove && [`${initial.itemId}_${initial.position}`, `${initial.itemId}_${initial.position-1}`].includes(id);
    const isSelectable = selectable === typename && !invalidId;
    const isSelected = selected === id;

    const onSelect = () => { 
        if (isSelectable) {
            select({ start:1, destination_id:itemId, destinationHookId:hookId });
            setSelected(id)
        }
    };

    const iconColor = isSelected ? c.onMainC1 : isSelectable ? c.onMainC1_d : c.onMainN1_d;
    const lineColor = isSelected ? c.selectorC1 : isSelectable ? c.selectorC1_d : c.selectorN1_d;

    const style = {
        element:    [s.row, {minHeight:24}],
        item:       [s.col_eY, s.Xr, {minHeight:24}],
        line:       [s.Xr, s.Y2, s.space.mX48, {zIndex:1}, lineColor],
        icon:       [s.X48Y24],
        selector:   [s.X48Y40, {position:'absolute', top:3}],
        top:        [{position:'absolute', width:'100%', top:-19, height:40}],
    };

    return (
        <>
            <View style={style.element}>
                <View style={style.item}>
                </View>
                <View style={style.icon}>
                    <Icon style={style.selector} color={iconColor} svg='arrowChevronLeft_16' onPress={onSelect}/>
                </View>
            </View>
            <View style={style.line}>
                {isSelectable ? <Pressable style={style.top} onPress={onSelect}/> : null}
            </View>
        </>
    );
}; 
export default First;