export const PictureItem = (item) => ({

    id: item.id,
    path: item.path,
    blurhash: item.blurhash,
    size:item.size,
    width:item.width,
    height:item.height,
    origin:item.origin,
    author_id: item.author_id,
    published: item.published,
    created: item.created,
    updated: item.updated, 

});