import { Titlebar, Element, Icon, Textline } from '../../../../modules/basics/components';
import { s, abs, use } from '../../../../root';

const LoadingIndex = ({ params }) => {

    const c = use.useColor();

    const style = {
        logo:   [s.X48Y40],
        icon:   [s.X32Y40],
        text:   [s.textPod],
    };

    const bookLogo = <Icon style={style.logo} color={c.onMainN1_d} svg={abs.book.svg({typename:'Book'})}/>
    const sectionLogo = <Icon style={style.logo} color={c.onMainN1_d} svg={abs.book.svg({typename:'Section'})}/>
    const options = <Icon style={style.icon} color={c.onMainN1_d} svg='optionsVertical_16'/>
    const adder = <Icon style={style.icon} color={c.onMainN1_d} svg='plus_14_line_24'/>

    return (
        <>
        <Titlebar
            title={`WL_book_index`} 
            backTo={abs.book.link('list', params)} 
            actions={<>{options}{adder}</>}
        />
        <Element logo={bookLogo} options={options} adder={adder} isMain>
            <Textline style={style.text} color={c.onMainN1_d} WL text='WL_book_BookLoading'/>
        </Element>
        <Element logo={sectionLogo} options={options} adder={adder}>
            <Textline style={style.text} color={c.onMainN1_d} WL text='WL_book_SectionListLoading'/>
        </Element>
        </>
    );       
};
export default LoadingIndex;