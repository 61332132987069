import { View } from 'react-native';
import { Textline } from '../../../modules/basics/components';
import { s, use } from '../../../root';

const Label = ({ title }) => {

    const c = use.useColor();

    const style = {
        label:  [s.Y32],
        text:   [{lineHeight:32, fontWeight:'bold'}],
    };

    return (
        <View style={style.label}>
            <Textline style={style.text} color={c.onMainN1} WL text={title}/>
        </View>
    );
}; 
export default Label;