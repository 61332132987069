import { abs, api } from '../../../../root';
import { SelectorElement } from '../../../../app/book/suits.js';
import { ThrowErrorModal } from '../../../../modules/error/throw';

const Block = ({ modalId, selection, params, navigate, position, block }) => {

    const { typename, pageHookId, pageItemId } = params;
    const { initial, selectable, select, deselect, setSelected, selected } = selection;

    const svg = abs.book.svg({typename});

    const id = `${pageItemId}_${position}`;
    const invalidId = initial.isMove && [`${initial.itemId}_${initial.position}`, `${initial.itemId}_${initial.position-1}`].includes(id);
    const isSelectable = selectable === 'Block' && !invalidId;
    const isSelected = selected === id;

    const onSelect = () => { 
        if (isSelectable) { 
            if (isSelected) deselect()
            else {
                select({ start:position+1, destination_id:pageItemId, destinationHookId:pageHookId })
                setSelected(id)
            }
        };
    };
    
    const onOpen = () => {
        if (selectable === 'Data') navigate({...params, typename:'Data'})
    };

    const fetchItem = api.book.useFetch(block.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorModal name='bookSelector' data={{data:block.item, params, modalId}}/>

    return (
        <SelectorElement 
            svg={svg} text={item.value} 
            onSelect={onSelect} onOpen={onOpen}
            isSelectable={isSelectable} isSelected={isSelected}
        />
    );
}; 
export default Block;