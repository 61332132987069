import {
    NewBook, NewSection, NewPage, NewBlock, NewData,
    EditBook, EditSection, EditPage, EditBlock, EditData,
    ArrangeSection, ArrangePage, ArrangeBlock, ArrangeData,
    GatherSection, GatherPage, GatherBlock, GatherData,
    ModifyText
} from '../../app/book/operations.js';

export const newBook = (modal) => <NewBook key={modal?.id} {...modal}/>;
export const newSection = (modal) => <NewSection key={modal?.id} {...modal}/>;
export const newPage = (modal) => <NewPage key={modal?.id} {...modal}/>;
export const newBlock = (modal) => <NewBlock key={modal?.id} {...modal}/>;
export const newData = (modal) => <NewData key={modal?.id} {...modal}/>;

export const editBook = (modal) => <EditBook key={modal?.id} {...modal}/>;
export const editSection = (modal) => <EditSection key={modal?.id} {...modal}/>;
export const editPage = (modal) => <EditPage key={modal?.id} {...modal}/>;
export const editBlock = (modal) => <EditBlock key={modal?.id} {...modal}/>;
export const editData = (modal) => <EditData key={modal?.id} {...modal}/>;

export const hookSection = (modal) => <ArrangeSection key={modal?.id} {...modal} arrange='hook'/>;
export const hookPage = (modal) => <ArrangePage key={modal?.id} {...modal} arrange='hook'/>;
export const hookBlock = (modal) => <ArrangeBlock key={modal?.id} {...modal} arrange='hook'/>;
export const hookData = (modal) => <ArrangeData key={modal?.id} {...modal} arrange='hook'/>;

export const moveSection = (modal) => <ArrangeSection key={modal?.id} {...modal} arrange='move'/>;
export const movePage = (modal) => <ArrangePage key={modal?.id} {...modal} arrange='move'/>;
export const moveBlock = (modal) => <ArrangeBlock key={modal?.id} {...modal} arrange='move'/>;
export const moveData = (modal) => <ArrangeData key={modal?.id} {...modal} arrange='move'/>;

export const copySection = (modal) => <ArrangeSection key={modal?.id} {...modal} arrange='copy'/>;
export const copyPage = (modal) => <ArrangePage key={modal?.id} {...modal} arrange='copy'/>;
export const copyBlock = (modal) => <ArrangeBlock key={modal?.id} {...modal} arrange='copy'/>;
export const copyData = (modal) => <ArrangeData key={modal?.id} {...modal} arrange='copy'/>;

export const hookSectionIn = (modal) => <GatherSection key={modal?.id} {...modal} gather='hook'/>;
export const hookPageIn = (modal) => <GatherPage key={modal?.id} {...modal} gather='hook'/>;
export const hookBlockIn = (modal) => <GatherBlock key={modal?.id} {...modal} gather='hook'/>;
export const hookDataIn = (modal) => <GatherData key={modal?.id} {...modal} gather='hook'/>;

export const moveSectionIn = (modal) => <GatherSection key={modal?.id} {...modal} gather='move'/>;
export const movePageIn = (modal) => <GatherPage key={modal?.id} {...modal} gather='move'/>;
export const moveBlockIn = (modal) => <GatherBlock key={modal?.id} {...modal} gather='move'/>;
export const moveDataIn = (modal) => <GatherData key={modal?.id} {...modal} gather='move'/>;

export const copySectionIn = (modal) => <GatherSection key={modal?.id} {...modal} gather='copy'/>;
export const copyPageIn = (modal) => <GatherPage key={modal?.id} {...modal} gather='copy'/>;
export const copyBlockIn = (modal) => <GatherBlock key={modal?.id} {...modal} gather='copy'/>;
export const copyDataIn = (modal) => <GatherData key={modal?.id} {...modal} gather='copy'/>;

export const modifyText = (modal) => <ModifyText key={modal?.id} {...modal}/>;
