import { useRecoilValue } from 'recoil';
import { View, Pressable } from 'react-native';
import { s, store } from '../../../root';
import { Svg } from '../../../modules/basics/components';

const Icon = ({ style=[], color, onPress=null, svg, noFlip }) => { 
    
    console.log('Icon svg', svg, ' color', color?.color);
    
    const isRtl = useRecoilValue(store.layout.isRTL);
    const isFlip = isRtl && !noFlip;
    
    const flip = {transform: isFlip ? [{scaleX: -1}] : []};

    if (!onPress) return (
        <View style={[...style, flip, s.row_cXcY]}>
            <Svg svg={svg} color={color?.color}/>   
        </View>
    );

    return (
        <View style={[...style, flip, s.row_cXcY]}>
            <Pressable onPress={onPress}>
                <Svg svg={svg} color={color?.color}/>   
            </Pressable>
        </View>
    );
};
export default Icon;