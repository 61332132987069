import { s, use } from '../../../../root';
import { Textline } from '../../../../modules/basics/components';

const AgentInline = ({ label }) => {

    const c = use.useColor();

    const style = [s.row_sXcY, s.Xr, {fontWeight:'bold'}];

    return <Textline style={style} color={c.onMainN1} WL text={label}/>
}; 
export default AgentInline;