import { View } from 'react-native';
import { s, use } from '../../../../root';
import { Icon, Picture } from '../../../../modules/basics/components';

const PictureFull = ({ src, placeholder, aspect, onPress }) => {

    console.log('aspect > ', aspect)

    const isVertical = aspect < 1;

    const size = isVertical ? s.Yr : s.Xr

    const c = use.useColor();

    const style = {
        box:        [s.row_cXsY, s.XYc, s.space.p8],
        pictureBox: [s.row_eXsY, size, {aspectRatio:aspect}],
        picture:    [s.row, size, s.radius.r16, {aspectRatio:aspect}],
        iconBox:    [s.row_cXcY, s.XY32, {position:'absolute', zIndex:1, marginInlineStart:'auto'}],
        icon:       [s.XY24, s.radius.r16, c.mainN1_o],
    };

    return (
        <View style={style.box}>
            <View style={style.pictureBox}>
                <View style={style.iconBox}>
                    <Icon style={style.icon} color={c.onMainN1} svg='confirmFalse_16' onPress={onPress}/>
                </View>
                <Picture 
                    src={src}
                    placeholder={placeholder}
                    style={style.picture}
                />
            </View>
        </View>
    );
    
};
export default PictureFull;