import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const fetchBook = gql`

    ${fragment.book.item}
    ${fragment.section.hook}
    ${fragment.section.item}
    ${fragment.page.hook}
    ${fragment.page.item}

    query fetchBook ($input: id!) {
        fetchBook(input: $input) {
            ...bookItemFields
            childHooks {
                ...sectionHookFields
                item {
                    ...sectionItemFields
                    childHooks {
                        ...pageHookFields 
                        item {
                            ...pageItemFields
                        }
                    }
                }
            }
        }
    }
`;
export default fetchBook;