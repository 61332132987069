import { v4 as getUUID } from 'uuid';
import { store } from '../../../root';

export const add = (operation) => {

    const operationId = getUUID();
    store.book.operation[operationId] = { operationId, ...operation }
    return operationId;

};

export const remove = (operationId) => delete store.book.operation[operationId];