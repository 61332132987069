import { View } from 'react-native';
import { useRecoilValue } from 'recoil';
import { s, use, store } from '../../../root';
import { IconLink } from '../../../modules/basics/menus';
import { Textline } from '../../../modules/basics/components';

const Titlebar = ({ id, backTo, title, actions, isDisabled }) => {

    const c = use.useColor();

    const popover = useRecoilValue(store.popover.popover);
    const isPopover = popover.id && id === popover.id;

    const style = {
        toolbar:    [s.col, s.Y42, s.radius.t16, isPopover ? c.toolbarC1 : c.toolbarN1],
        body:       [s.row, s.Y40],
        one:        [s.row_sXcY, s.X96, s.space.mE8],
        icon:       [s.X48Y40],
        text:       [{fontWeight:'bold'}],
        middle:     [s.row_cXcY, s.Xr],
        two:        [s.row_eXcY, s.X96, s.space.mE8],
        line:       [s.Y2, s.Xc, isPopover? c.selectorC1 : c.popoverN1],
    };

    return (
        <View style={style.toolbar}>
            <View style={style.body}>
                <View style={style.one}>
                    <IconLink 
                        style={style.icon} 
                        color={c.onMainN1} 
                        svg='arrowFullLeft_16' 
                        linkTo={backTo} 
                        isDisabled={isDisabled}
                    />
                </View>
                <View style={style.middle}>
                    <Textline style={style.text} color={c.onMainN1} WL text={title}/>
                </View>
                <View style={style.two}>{actions}</View>
            </View>
            <View style={style.line}></View>
        </View>
    );       
};
export default Titlebar;