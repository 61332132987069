import { useState } from 'react';
import { PopoverItem } from '../../../../app/book/suits.js';
import { RemoveBook, RemoveSection, RemovePage, RemoveBlock, RemoveData } from '../../../../app/book/operations.js';

// import RemoveBook from '../../operations/Remove/Book.js';
// import RemoveSection from '../../operations/Remove/Section.js';
// import RemovePage from '../../operations/Remove/Page.js';
// import RemoveBlock from '../../operations/Remove/Block.js';
// import RemoveData from '../../operations/Remove/Data.js';

const Remove = ({ name, svg, main, second, isNavigate, isLine }) => {

    const [isRemove, setIsRemove] = useState(false)
    const onRemove = () => setIsRemove(true)

    const remove = {
        removeBook: <RemoveBook isNavigate={isNavigate}/>,
        removeSection: <RemoveSection isNavigate={isNavigate}/>,
        removePage: <RemovePage isNavigate={isNavigate}/>,
        removeBlock: <RemoveBlock isNavigate={isNavigate}/>,
        removeData: <RemoveData isNavigate={isNavigate}/>,
    };

    if (isRemove) return remove[name]
    else return <PopoverItem onPress={onRemove} svg={svg} main={main} second={second} isLine={isLine}/>
};
export default Remove;