import { s,use } from '../../../../root';
import { Icon, Textline, Toolbar as BasicToolbar } from '../../../../modules/basics/components';

const Toolbar = ({ typename, onBack=()=>{}, selection={}, isDisabled }) => {

    const c = use.useColor();

    const { selected, deselect } = selection;

    const onDeselect = () => deselect();

    const color = isDisabled ? c.onMainN1_d : c.onMainN1
    const selectionColor = selected ? c.onMainC1 : c.onMainN1_d

    const style = {
        icon: [s.X48Y40],
        text: [s.toolbar],
        select: [s.X32Y40],
    }

    const icon = <Icon style={style.icon} color={color} svg='arrowFullLeft_16' onPress={onBack}/>
    const text = <Textline style={style.text} color={color} WL text={`WL_book_${typename}Back`}/>
    const selectIcon = <Icon style={style.select} color={selectionColor} svg='confirmTrue_16_fill_24' onPress={onDeselect}/>

    return <BasicToolbar one={icon} middle={text} two={selectIcon}/>    
    
}; 
export default Toolbar;