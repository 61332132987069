import { View, ScrollView } from 'react-native';
import { s, abs, api } from '../../../../root';
import { SelectorToolbar, SelectorEmpty, SelectorFirst } from '../../../../app/book/suits.js';
import { ThrowErrorModal } from '../../../../modules/error/throw';
import Section from './Section.js';

const Sections = ({ modalId, selection, params, navigate }) => {

    const { typename, setId, bookItemId } = params;
    const { deselect } = selection

    const onBack = () => {
        deselect()
        navigate({ 
            typename:'Book', setId 
        })
    }

    const toolbar = <SelectorToolbar onBack={onBack} typename='Book' selection={selection}/>
    const first = <SelectorFirst typename={typename} itemId={bookItemId} selection={selection}/>

    const sectionList = {typename, type:'list', id:bookItemId, fetchId:bookItemId};
    const fetchList = api.book.useFetch(sectionList);
    const list = fetchList();

    if (abs.isError(list)) return <ThrowErrorModal name='bookSelector' data={{data:sectionList, params, modalId}} />
    if (abs.isEmpty(list)) return <SelectorEmpty typename={typename} toolbar={toolbar} first={first}/>
    if (!abs.isArray(list)) return null;

    return (<>
        {toolbar}
        <View style={[s.scrollView]}>
        <ScrollView style={[s.scrollView]}>
            {first}
            {list.map((current, index) => {
                const sectionHookId = current.hookId;
                const sectionItemId = current.itemId;
                const hook = {typename, type:'hook', id:sectionHookId, fetchId:bookItemId};
                const item = {typename, type:'item', id:sectionItemId, fetchId:bookItemId};
                return (
                    <Section
                        modalId={modalId}
                        selection={selection}
                        params={{...params, sectionHookId, sectionItemId}}
                        section={{list:sectionList, hook, item}}
                        navigate={navigate}
                        position={index+1}
                        key={current.hookId} 
                    />
                );
            })}
        </ScrollView>
        </View>
    </>);
}; 
export default Sections;