import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { use, store } from '../../../../root';
import { ErrorButton } from '../../../../app/book/suits.js';

const SelectorCleanup = () => {

    const [error, setError] = useRecoilState(store.error.modal);
    const { modalId, params } = error;
    const modalAppend = use.modal.useModalAppend(modalId, {navParams:params});

    useEffect(()=>{
        modalAppend();
        setError({});
    })

    return <ErrorButton text='WL_error_cleanup' isMain/>

};
export default SelectorCleanup;