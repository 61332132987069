import NetInfo from '@react-native-community/netinfo';
import { publicRoutes } from './routes.js';

import * as user from '../modules/user/abs.js';

import * as home from '../app/home/abs.js';
import * as book from '../app/book/abs.js';
import * as picture from '../app/picture/abs.js';

const abs = {

    user,

    home,
    book,
    picture,

    up: (string) => {return string[0].toUpperCase() + string.slice(1)},
    low: (string) => {return string[0].toLowerCase() + string.slice(1)},
    
    isPublic: (url) => publicRoutes.includes(url.split('/')[1]),

    isEmpty: (list) => Array.isArray(list) && !list.length,
    isArray: (list) => Array.isArray(list),

    isError: (element) => element==='error',

    isNetwork: async () => { 
        const result = await NetInfo.fetch(); 
        return result.isConnected 
    },

    isAuth: async () => await user.refetchToken(),

    isInvalid: (element) => element===null || element===undefined || element==='error' || element==='retry',

    errorThrow: (error, stack) => {throw new Error(`\nError:\n${error}\nStack:\n${stack}`)},
    errorCatch: (path, stack) => {console.error(`\nCatch:\n${path}\nStack:\n${stack}`);  return 'error'},

    time: () => {
        const today = new Date();
        const zerify = (num) => `${num<10 ? 0:''}${num}`;
        const hours = zerify(today.getHours())
        const minutes = zerify(today.getMinutes())
        const seconds = zerify(today.getSeconds())
        return `${hours}:${minutes}:${seconds}`
    },
 
};
export default abs;