import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const copyData = gql`

    ${fragment.data.hook}
    ${fragment.data.item}

    mutation copyData ($input: arrange!) {
        copyData(input: $input) {
            ...dataHookFields 
            item {
                ...dataItemFields
            } 
        }
    }
`;

export default copyData;