import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateBookLogo = gql`

    ${fragment.book.item}

    mutation updateBookLogo ($input: editLogo!) {
        updateBookLogo(input: $input) {
            ...bookItemFields
        }
    }
`;

export default updateBookLogo;