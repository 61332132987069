import { View } from 'react-native';
import { useRecoilValue } from 'recoil';
import { s, use, store } from '../../../root';
import { ErrorModal } from '../../../modules/error/pages';
import { Textline, Icon } from '../../../modules/basics/components';

const Modal = ({id, title, svg, children, actions, isCloseDisabled}) => {

    const c = use.useColor();

    const error = useRecoilValue(store.error.modal);
    const isError = error?.name;

    const setMargins = use.modal.useSetMargins(); 
    const margins = setMargins();

    const closeModal = use.modal.useCloseModal();
    const close = () => isCloseDisabled || isError ? () => {} : closeModal(id);
   
    const color = isCloseDisabled ? c.onBandN1_d : c.onBandN1; 

    const style = {
        overlay:    [s.col_cXcY, s.overlay, {zIndex:3}, c.overlayN1],
        modal:      [s.col, s.XYc, s.radius.t8, s.space.mT48B8, margins],
        layout:     [s.col, s.XYc],
        header:     [s.row_cXcY, s.Xr, s.Y48, s.radius.t8, c.bandN1],
        icon:       [s.XY48],
        title:      [s.row_sXcY, s.Xr, {fontWeight:'bold'}],
        body:       [s.col, s.XYc, c.mainN1],
        footer:     [s.row_cXcY, s.Xc, s.Y48, s.radius.b8, c.bandN1],
    };

    return (
        <View style={style.overlay}>
            <View style={style.modal}>
                <View style={style.layout}>
                    <View style={style.header}>
                        <Icon style={style.icon} color={c.onBandN1} svg={svg}/>
                        <Textline style={style.title} color={c.onBandN1} WL text={title}/>
                        <Icon style={style.icon} color={color} onPress={close} svg='confirmFalse_16'/>
                    </View>
                    <View style={style.body}>{isError ? <ErrorModal/> : children}</View>  
                    <View style={style.footer}>{actions}</View>
                </View>
            </View>
        </View>
    );
}; 
export default Modal;