import { abs, api } from '../../../../root';
import { SelectorItem } from '../../../../app/book/suits.js';
import { ThrowErrorModal } from '../../../../modules/error/throw';

const Data = ({ modalId, selection, params, data, navigate, position }) => {

    const { typename, blockHookId, blockItemId, dataHookId } = params;
    const { initial, selectable, select, deselect, setSelected, selected } = selection;
    
    const id = dataHookId;
    const invalidId = initial.isMove && initial.hookId === id;
    const isSelectable = selectable === 'Data' && !invalidId;
    const isSelected = selected === id;
    
    const onSelect = () => { 
        if (isSelectable) {
            if (isSelected) deselect()
            else {
                select({ ids:[dataHookId], origin_id:blockItemId, originHookId:blockHookId });
                setSelected(id)
            }
        };
    };

    const fetchItem = api.book.useFetch(data.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorModal name='bookSelector' data={{data:data.item, params, modalId}}/>

    const svg = abs.book.svg({typename, type:item.type});
    
    return (
        <SelectorItem 
            svg={svg} text={item.key ? `${item.key}: ${item.value}` : item.value} 
            onSelect={onSelect}
            isSelectable={isSelectable} isSelected={isSelected}
        />
    );
}; 
export default Data;