import { atom, selector } from 'recoil';
import * as localStorage from '../../platform/localStorage';

const store = {

    language: atom({
        key: 'language',
        default: selector({
            key: 'getLanguage',
            get: async () => {
                let result = await localStorage.get('language');
                if (!result) result = await localStorage.set('language', 'en');
                console.log('result inside language selector', result)
                return result;
            }
        }),
    }),

    isRTL: atom({
        key: 'isRTL',
        default: selector({
            key: 'getIsRTL',
            get: async () => {
                let result = await localStorage.get('isRTL');
                if (!result) result = await localStorage.set('isRTL', 'false');
                console.log('result inside isRTL selector', result)
                return result === 'true' ? true : false;
            }
        }),
    }),

    isDark: atom({
        key: 'isDark',
        default: selector({
            key: 'getIsDark',
            get: async () => {
                let result = await localStorage.get('isDark');
                console.log('result 1 inside isDark selector', result)
                if (!result) result = await localStorage.set('isDark', 'false');
                console.log('result 2 inside isDark selector', result)
                return result === 'true' ? true : false;
            }
        }),
    }),

    isFixed: atom({
        key: 'isFixed',
        default: selector({
            key: 'getIsFixed',
            get: async () => {
                let result = await localStorage.get('isFixed');
                if (!result) result = await localStorage.set('isFixed', 'true');
                console.log('result inside isFixed selector', result)
                return result === 'true' ? true : false;
            }
        }),
    }),
    
    size: atom({key:'size', default:'1'}),
    isOversize: atom({key:'isOversize', default:false}),

    isSideOneOn: atom({key:'isSideOneOn', default:false}),
    isSideTwoOn: atom({key:'isSideTwoOn', default:false}),

    // agent: atom({key:'agent', default:''}),

    footer: atom({key:'footer', default:''}),

};
export default store;