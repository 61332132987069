import { Suspense, useState } from 'react';
import { LoadingSelector } from '../../../../app/book/suits.js';
import Books  from './Books';
import Sections  from './Sections';
import Pages  from './Pages';
import Blocks  from './Blocks';
import Datas  from './Datas';

const Positioner = ({ modalId, selection={}, initParams={} }) => {

    const [params, setParams] = useState(initParams);

    const navigate = (next) => {
        setParams(next);
    };

    console.log('XXX Params > ', params, selection)

    const element = {
        Book: <Books params={params} navigate={navigate} modalId={modalId}/>,
        Section: <Sections selection={selection} params={params} navigate={navigate} modalId={modalId}/>,
        Page: <Pages selection={selection} params={params} navigate={navigate} modalId={modalId}/>,
        Block: <Blocks selection={selection} params={params} navigate={navigate} modalId={modalId}/>,
        Data: <Datas selection={selection} params={params} navigate={navigate} modalId={modalId}/>,
    };

    return (
        <Suspense fallback={<LoadingSelector typename={params.typename}/>}>
            {element[params.typename]}
        </Suspense>
    );
}; 
export default Positioner;