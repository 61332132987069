import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { abs, api } from '../../../root';

const useInitialize = (setIsNetworkOk, setIsAuthOk) => {

    const user = useRecoilValue(api.user.user) || {};

    const initialize = useEffect(() => {

        const check = async () => {
            
            if (await abs.isNetwork()) setIsNetworkOk(true)
            if (user.id) setIsAuthOk(true)
        };
        check();

    }, [user]);

    return initialize;
};
export default useInitialize;