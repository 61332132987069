import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const fetchPage = gql`

    ${fragment.page.hook}
    ${fragment.page.item}
    ${fragment.block.hook}
    ${fragment.block.item}
    ${fragment.data.hook}
    ${fragment.data.item}

    query fetchPage ($input: id!) {
        fetchPage(input: $input) {
            ...pageHookFields
            item {
                ...pageItemFields
                childHooks {
                    ...blockHookFields
                    item {
                        ...blockItemFields
                        childHooks {
                            ...dataHookFields 
                            item {
                                ...dataItemFields
                            } 
                        }
                    }
                }
            }
        }
    }
`;
export default fetchPage;