import { View } from 'react-native';
import { s, use } from '../../../root';
import { Titlebar, Card } from '../../../modules/basics/components';

const BookPage = () => {

    const c = use.useColor();

    const style = {
        text:       [{fontWeight:'bold'}],
        icon:       [s.X48Y40],
    };

    return (
        <View>
            <Titlebar 
                backTo='/'
                title='WL_home_home'
                isDisabled
            />
            <Card 
                linkTo={`/creator`} 
                svg='creator_16' 
                title='WL_home_creator' 
                description='WL_home_creator_description' 
            />
            <Card 
                linkTo={`/domino`} 
                svg='domino_16' 
                title='WL_home_domino' 
                description='WL_home_domino_description' 
            />
            <Card 
                linkTo={`/language`} 
                svg='book_16' 
                title='WL_home_language' 
                description='WL_home_language_description' 
            />
            <Card 
                linkTo={`/preferences`} 
                svg='settings_16' 
                title='WL_home_preferences' 
                description='WL_home_preferences_description' 
            />
        </View>
    );
};
export default BookPage;