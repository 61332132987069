import { StyleSheet } from 'react-native';
import * as c from '../use/colors.js'

const style = StyleSheet.create({

    fixed: { position:'fixed' },
    overlay: { zIndex:1, position:'absolute', overflow:'hidden', height:'100%', width:'100%', flex:1 },
    input: { width:0, outlineStyle:'none' }, 
    logo: { borderWidth:2, borderStyle:'solid', borderRadius:1000 },
    toolbar: { 
        display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start',
        fontWeight:'bold', flex:1, 
     },
    button: { 
        display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', 
        marginInlineStart:4, marginInlineEnd:4, 
        paddingInlineStart:12, paddingInlineEnd:12, 
        borderRadius:8, alignSelf:'center',
    },
    confirm: { 
        display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', 
        marginInlineEnd:6, 
        paddingInlineStart:12, paddingInlineEnd:12, 
        borderRadius:8, alignSelf:'center',
    },
    textPod: { lineHeight:24, marginTop:8, marginBottom:8 },
    scrollView: { display:'flex', flexDirection:'column', flex:1, alignSelf:'stretch', height:0, borderBottomLeftRadius:16, borderBottomRightRadius:16 },
    pictureScrollView: { display:'flex', flexDirection:'row', flex:1, alignSelf:'stretch', height:0, borderBottomLeftRadius:16, borderBottomRightRadius:16 },

    // FLEX

    col: { display:'flex', flexDirection:'column' },
    row: { display:'flex', flexDirection:'row' },

    col_cXcY: { display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' },
    col_sXsY: { display:'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start' },
    col_eXeY: { display:'flex', flexDirection:'column', alignItems:'flex-end', justifyContent:'flex-end' },
    col_eXsY: { display:'flex', flexDirection:'column', alignItems:'flex-end', justifyContent:'flex-start' },
    col_sXcY: { display:'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'center' },

    col_eY: { display:'flex', flexDirection:'column', justifyContent:'flex-end' },

    row_eXsY: { display:'flex', flexDirection:'row', alignItems:'flex-start', justifyContent:'flex-end' },

    row_cXcY: { display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center' },
    row_sXcY: { display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start' },
    row_cXsY: { display:'flex', flexDirection:'row', alignItems:'flex-start', justifyContent:'center' },
    row_eXcY: { display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-end' },
    row_sXsY: { display:'flex', flexDirection:'row', alignItems:'flex-start', justifyContent:'flex-start' },
    row_eXeY: { display:'flex', flexDirection:'row', alignItems:'flex-end', justifyContent:'flex-end' },

    rowR_sXcY: { display:'flex', flexDirection:'row-reverse', alignItems:'center', justifyContent:'flex-start' },

    row_cY: { display:'flex', flexDirection:'row', alignItems:'center' },

    row_sX: { display:'flex', flexDirection:'row', justifyContent:'flex-start' },
    row_eX: { display:'flex', flexDirection:'row', justifyContent:'flex-end' },

    // SIZE
    X32Y20: { width:32, maxWidth:32, minWidth:32, height:20, maxHeight:20, minHeight:20 },
    X32Y40: { width:32, maxWidth:32, minWidth:32, height:40, maxHeight:40, minHeight:40 },
    X48Y24: { width:48, maxWidth:48, minWidth:48, height:24, maxHeight:24, minHeight:24 },
    X48Y32: { width:48, maxWidth:48, minWidth:48, height:32, maxHeight:32, minHeight:32 },
    X48Y40: { width:48, maxWidth:48, minWidth:48, height:40, maxHeight:40, minHeight:40 },

    XY16: { width:16, maxWidth:16, minWidth:16, height:16, maxHeight:16, minHeight:16 },
    XY20: { width:20, maxWidth:20, minWidth:20, height:20, maxHeight:20, minHeight:20 },
    XY24: { width:24, maxWidth:24, minWidth:24, height:24, maxHeight:24, minHeight:24 },
    XY32: { width:32, maxWidth:32, minWidth:32, height:32, maxHeight:32, minHeight:32 },
    XY40: { width:40, maxWidth:40, minWidth:40, height:40, maxHeight:40, minHeight:40 },
    XY48: { width:48, maxWidth:48, minWidth:48, height:48, maxHeight:48, minHeight:48 },
    XY64: { width:64, maxWidth:64, minWidth:64, height:64, maxHeight:64, minHeight:64 },

    Y2: { height:2, maxHeight:2, minHeight:2 },
    Y8: { height:8, maxHeight:8, minHeight:8 },
    Y16: { height:16, maxHeight:16, minHeight:16 },
    Y32: { height:32, maxHeight:32, minHeight:32 },
    Y40: { height:40, maxHeight:40, minHeight:40 },
    Y42: { height:42, maxHeight:42, minHeight:42 },
    Y48: { height:48, maxHeight:48, minHeight:48 },

    X48: { width:48, maxWidth:48, minWidth:48 },
    X64: { width:64, maxWidth:64, minWidth:64 },
    X96: { width:96, maxWidth:96, minWidth:96 },
    X104: { width:104, maxWidth:104, minWidth:104 },
    X320: { width:320, maxWidth:320, minWidth:320 },
    X312: { width:312, maxWidth:312, minWidth:312 },

    X: { width:'100%', maxWidth:'100%', minWidth:'100%' },
    Y: { height:'100%', maxHeight:'100%', minHeight:'100%' },
    XY: { width:'100%', maxWidth:'100%', minWidth:'100%', height:'100%', maxHeight:'100%', minHeight:'100%' },

    Xc: { alignSelf:'stretch' },
    Yc: { flex:1 },
    Xr: { flex:1 },
    Yr: { alignSelf:'stretch' },
    XYc: { flex:1, alignSelf:'stretch' }, 
    XYr: { flex:1, alignSelf:'stretch' }, 

    X104min: { minWidth:104 },
    X320max: { maxWidth:320 },
    X960max: { width:960, maxWidth:960 },
    X864max: { width:864, maxWidth:864 },

    // SPACE 

    space: { 

        between: { justifyContent:'space-between' },

        m4: { margin:4 },
        m8: { margin:8 },
        m16: { margin:16 },

        mS8: { marginInlineStart:8 },

        pS8: { paddingInlineStart:8 },
        pE8: { paddingInlineEnd:8 },

        mS16: { marginInlineStart:16 },
        mS48: { marginInlineStart:48 },
        mE4: { marginInlineEnd:4 },
        mE8: { marginInlineEnd:8 },
        mE12: { marginInlineEnd:12 },
        mE16: { marginInlineEnd:16 },
        mE48: { marginInlineEnd:48 },
        mE52: { marginInlineEnd:52 },
        mE56: { marginInlineEnd:56 },
        mX2: { marginInlineStart:2, marginInlineEnd:2 },
        mX4: { marginInlineStart:4, marginInlineEnd:4 },
        mX8: { marginInlineStart:8, marginInlineEnd:8 },
        mX16: { marginInlineStart:16, marginInlineEnd:16 },

        mX48: { marginInlineStart:48, marginInlineEnd:48 },

        mX8: { marginInlineStart:8, marginInlineEnd:8 },
        mS328E8: { marginInlineStart:328, marginInlineEnd:8 },
        mS328E8: { marginInlineStart:328, marginInlineEnd:8 },
        mS8E328: { marginInlineStart:8, marginInlineEnd:328 },
        mX328: { marginInlineStart:328, marginInlineEnd:328 },

        mX56: { marginInlineStart:56, marginInlineEnd:56 },
        mS376E56: { marginInlineStart:376, marginInlineEnd:56 },
        mS376E56: { marginInlineStart:376, marginInlineEnd:56 },
        mS56E376: { marginInlineStart:56, marginInlineEnd:376 },
        mX376: { marginInlineStart:376, marginInlineEnd:376 },

        mT4: { marginTop:8 },
        mB2: { marginBottom:2 },
        mB8: { marginBottom:8 },
        mB16: { marginBottom:16 },
        mT16: { marginTop:16 },

        mT48: { marginTop:48 },
        mB48: { marginBottom:8 },

        mT48B8: { marginTop:48, marginBottom:8 },

        mY8: { marginTop:8, marginBottom:8 },
        mY16: { marginTop:16, marginBottom:16 },
        mY40: { marginTop:40, marginBottom:40 },
        mY48: { marginTop:48, marginBottom:48 },
        mY64: { marginTop:64, marginBottom:64 },

        p8: { padding:8 },
        p12: { padding:12 },
        p16: { padding:16 },

        pY16: { paddingTop:16, paddingBottom:16 },

        pS16: { paddingInlineStart:16 },
        pE16: { paddingInlineEnd:16 },
        pE8: { paddingInlineEnd:8 },
        pS4: { paddingInlineStart:4 },
        pS8: { paddingInlineStart:8 },
        pX8: { paddingInlineStart:8, paddingInlineEnd:8 },
        pX12: { paddingInlineStart:12, paddingInlineEnd:12 },
        pX16: { paddingInlineStart:16, paddingInlineEnd:16 },

    },

    // RADIUS

    radius: {
        r8: { borderRadius:8 },
        r64: { borderRadius:64 },
        r16: { borderRadius:16 },
        r32: { borderRadius:32 },
        r40: { borderRadius:40 },
        t8: { borderTopLeftRadius:8, borderTopRightRadius:8 },
        t16: { borderTopLeftRadius:16, borderTopRightRadius:16 },
        b8: { borderBottomLeftRadius:8, borderBottomRightRadius:8 },
        b16: { borderBottomLeftRadius:16, borderBottomRightRadius:16 },

        s16: { borderTopLeftRadius:16, borderBottomLeftRadius:16 },
        e16: { borderTopRightRadius:16, borderBottomRightRadius:16 },

        te16: { borderTopRightRadius:16 },
        be16: { borderBottomRightRadius:16 },
    },

    colors: {

        // band (static)
        bandN1: { backgroundColor: c.grey128B(1) },
        barN1: { backgroundColor: c.grey128B(1) },
        mainN1: { backgroundColor: c.grey096B(1) },

        // band (static)
        onBandN1: { color: c.grey192W(1) },
        onBandN1_d: { color: c.grey192W(0.3) },
        onBandC1: { color: c.blue016W(1) },
        onBandC1_d: { color: c.blue016W(0.3) },

        // button (static)
        buttonN1: { backgroundColor: c.grey(1) },
        buttonN1_d: { backgroundColor: c.grey(0.3) },
        buttonC1: { backgroundColor: c.blue(1) },
        buttonC1_d: { backgroundColor: c.blue(0.3) },

        // button text (static)
        onButtonN1: { color: c.grey192W(1) },
        onButtonN1_d: { color: c.grey192W(0.3) },

    },

});
export default style;