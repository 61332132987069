import { useRecoilValue } from 'recoil';
import { api, abs } from '../../../root';
import { MenuLink } from '../../../modules/basics/menus';

const Books = ({ url }) => {

    const user = useRecoilValue(api.user.user);
    const userId = user?.id;

    return (
        <>
            <MenuLink 
                svg='back_16' 
                WL text='WL_book_creator_trail' 
                linkTo={`/creator`} 
            />
            <MenuLink 
                svg='overview_16' 
                WL text='WL_book_booksOverview' 
                linkTo={`/set/${userId}/books/overview`}
                isActive={abs.book.isUrlBooksOverview(url)}
            />
            <MenuLink 
                svg='book_16' 
                WL text='WL_book_myBooks' 
                linkTo={`/set/${userId}/books/list`}
                isActive={abs.book.isUrlBooksList(url)}
            />
            <MenuLink 
                svg='publication_16' 
                WL text='WL_book_myPublications' 
                linkTo={`/set/${userId}/books/publications`}
                isActive={abs.book.isUrlBooksPublications(url)}
            />
            <MenuLink 
                svg='bookmark_16' 
                WL text='WL_book_myBookmarks' 
                linkTo={`/set/${userId}/books/bookmarks`}
                isActive={abs.book.isUrlBooksBookmarks(url)}
            />
        </>
    );
}; 
export default Books;