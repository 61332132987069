import { v4 as getUUID } from 'uuid';
import { abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { PageLink } from '../../../../app/book/suits';

const Book = ({ svg, typename, params, book }) => {

    const fetchItem = api.book.useFetch(book.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorMain name='bookPage' data={book.item}/>

    const { setId, bookItemId } = params;

    const linkTo = `/set/${setId}/book/${bookItemId}/index`;
    const id = getUUID();
    const popover = abs.book.popover({
        header: {svg, title:item.title},
        popover: {id, typename, book, ...params, item, linkTo},
        options: {name: 'optionsBookItem'},
    });
    
    return <PageLink id={id} svg={svg} text={item.title} linkTo={linkTo} options={popover.options} />
    
};
export default Book;