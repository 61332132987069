import Constants from 'expo-constants';
import axios from 'axios';
import { abs, store } from '../../../../root';

const fetchSet = async (type, id, setId, isRefetch) => {

    const { MEDIA_API_BASE_URL } = Constants.expoConfig.extra

        // // DELAYER START
        //     const delayer = async () => {const start = Date.now(); while (Date.now() - start < 2000 ) {}; return 'error'}; 
        //     return await delayer()
        // // DELAYER END

    console.log(
        '\n fetchSet', 
        '\n type ', type, 
        '\n id ', id, 
        '\n setId ', setId, 
        '\n isRefetch ', isRefetch,
        '\n\n'
    );
    // return 'error'
    try {

        // if the cache does not contain the element or contains an invalid value, we need to fetch it
        if (isRefetch || abs.isInvalid(store.picture.cache[type][id])) {

            // check the connection, the token and if the fetchId is present
            if (!(await abs.isNetwork())) abs.errorThrow('if (!(await abs.isNetwork()))');
            const accessToken = await abs.isAuth();
            if (!accessToken) abs.errorThrow('if (!(await abs.isAuth()))');
            if (!setId) abs.errorThrow('if (!setId)');

            const input = {
                method:'GET',
                url:`${MEDIA_API_BASE_URL}/picture/list`,
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${accessToken}`
                },
            }

            console.log('inside get pictures > input : \n', input);

            const response = await axios(input);

            console.log('inside get pictures > response : \n', response);

            // if there is an error throw an error (if there is not a valid response)
            if (!abs.isArray(response?.data?.data)) abs.errorThrow('if (!abs.isArray(response?.data?.data))', response.errors);

            // extract the data in a variable
            const set = response?.data?.data;

            // create an empty list for the set
            store.picture.cache.list[setId] = [];

            for (const picture of set) {        

                // push itemId of the book into the set list
                store.picture.cache.list[setId].push({itemId: picture.id});   
                
                // clone the item
                store.picture.cache.item[picture.id] = abs.picture.clone.PictureItem(picture);

            };
        };

        // cleanup the operation in any case
        store.router.params = { };
        store.router.operation = '';
        store.router.operationId = '';

        console.log(
            '\n fetchSet return',
            '\n type ', type,
            '\n id ', id,
            '\n store.picture.cache ', store.picture.cache,
            '\n store.picture.cache[type][id] ', store.picture.cache[type][id],
            '\n\n'
        )

        // at this point data are in the cache in both cases
        return store.picture.cache[type][id];

    } catch (error) {return abs.errorCatch('App/app/picture/api/fetch/fetchSet', error)}

};

export default fetchSet;
