import { Text } from 'react-native';
import { use } from '../../../root';

const Textline = ({ style=[], color, text, lines=1, WL }) => {

    const t = use.useText();
    return (
        <Text style={[...style, color]} numberOfLines={lines} selectable={false}>{t(text, !WL)}</Text>
    );
};
export default Textline;