import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { api } from '../../../root';
import { AgentInline, AgentButton } from '../../../app/picture/suits.js'

const Write = ({ operationId, setResponse, isRemove }) => {

    const response = useRecoilValue(api.picture.Write(operationId))||null
    useEffect(()=> setResponse(response), [response])
    return isRemove ? <AgentInline label='WL_picture_remove'/> : <AgentButton label='WL_picture_operation'/>

};
export default Write;