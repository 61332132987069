import { useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { store } from '../../../../root';
import { ErrorButton } from '../../../../app/book/suits.js';

const MainCleanup = () => {

    const setError = useSetRecoilState(store.error.main);

    useEffect(()=>{setError({})})

    return <ErrorButton text='WL_error_cleanup' isMain/>

};
export default MainCleanup;