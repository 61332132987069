const wordlist = {

    'WL_layout_language' : {
        en:'Language',
        it:'Lingua',
    },

    'WL_layout_save_and_close' : {
        en:'SAVE AND CLOSE',
        it:'SALVA E CHIUDI',
    },

    'WL_layout_preferences' : {
        en:'Preferences',
        it:'Preferenze',
    },

    'WL_layout_find' : {
        en:'Find',
        it:'Trova',
    },
    
    'WL_layout_isRTL' : {
        en:'Text direction Left to Right',
        it:'Direzione del testo da sinistra a destra',
    },
    'WL_layout_isDark' : {
        en:'Dark Mode',
        it:'Modalitá scura',
    },
    'WL_layout_isFixed' : {
        en:'Fixed Mode',
        it:'Modalitá fissa',
    },
    

    'WL_layout_layout_close' : {
        en:'CLOSE',
        it:'CHIUDI',
    },
    'WL_layout_cancel' : {
        en:'CANCEL',
        it:'ANNULLA',
    },
    'WL_layout_save' : {
        en:'SAVE',
        it:'SALVA',
    },
};
export default wordlist;