import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { store } from '../../../root';

const ThrowErrorModal = ({name=null, data}) => {

    const url = useRecoilValue(store.router.url)
    const [error={}, setError] = useRecoilState(store.error.modal);

    useEffect(()=> {if(!error.name) setError({name, url, ...data})})
    return null;
};
export default ThrowErrorModal;