import gql from './gql/index.js';
import { apollo } from '../../../../clients';
import { abs, store } from '../../../../root';

const fetchPage = async (type, id, pageHookId, isRefetch) => {

    console.log(
        '\n fetchPage', 
        '\n type ', type, 
        '\n id ', id, 
        '\n pageHookId ', pageHookId, 
        '\n isRefetch ', isRefetch,
        '\n\n'
    );

    try { 

        // if the cache does not contain the element or contains an invalid value, we need to fetch it
        if (isRefetch || abs.isInvalid(store.book.cache[type][id])) {

            // check the connection, the token and if the fetchId is present
            if (!(await abs.isNetwork())) abs.errorThrow('if (!(await abs.isNetwork()))');
            if (!(await abs.isAuth())) abs.errorThrow('if (!(await abs.isAuth()))');
            if (!pageHookId) abs.errorThrow('if (!pageHookId)');
        
            // fetch the page
            const response = await apollo.query ({
                query: gql.fetchPage, 
                variables: {input: {id: pageHookId}}
            });

            // if there is an error throw an error
            if (response?.errors?.length) abs.errorThrow('if (response.errors?.length)', response.errors);

            // extract the data in a variable
            const page = response.data.fetchPage;

            // clone the page hook and item, add a bond between item and hook
            store.book.cache.hook[page.id] = abs.book.clone.PageHook(page);
            store.book.cache.item[page.item.id] = abs.book.clone.PageItem(page.item);
            abs.book.bond.add(page.item.id, page.id);

            // create an empty list for the blocks
            store.book.cache.list[page.item.id] = [];
            
            // take one by one each block of the page
            for (const block of page.item.childHooks) {

                // push hookId and itemId of the block into the page list
                store.book.cache.list[page.item.id].push({hookId: block.id, itemId: block.item.id});

                // clone the block hook and item, add a bond between item and hook
                store.book.cache.hook[block.id] = abs.book.clone.BlockHook(block);
                store.book.cache.item[block.item.id] = abs.book.clone.BlockItem(block.item)
                abs.book.bond.add(block.item.id, block.id);

                // create an empty list for the data
                store.book.cache.list[block.item.id] = [];
                
                // take one by one each data of the block
                for (const data of block.item.childHooks) {

                    // push hookId and itemId of the data into the block list
                    store.book.cache.list[block.item.id].push({hookId: data.id, itemId: data.item.id});

                    // clone the block hook and item, add a bond between item and hook
                    store.book.cache.hook[data.id] = abs.book.clone.DataHook(data);
                    store.book.cache.item[data.item.id] = abs.book.clone.DataItem(data.item);
                    abs.book.bond.add(data.item.id, data.id);
                };                  
            };
        };

        // cleanup the operation in any case
        store.router.params = { };
        store.router.operation = '';
        store.router.operationId = '';

        console.log(
            '\n fetchPage return',
            '\n type ', type,
            '\n id ', id,
            '\n store.book.cache ', store.book.cache,
            '\n store.book.cache[type][id] ', store.book.cache[type][id],
            '\n\n'
        )

        // at this point data are in the cache in both cases
        return store.book.cache[type][id];

    } catch (error) {return abs.errorCatch('App/app/book/api/fetch/fetchPage', error)}
};
export default fetchPage;
