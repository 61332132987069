import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updatePageValue = gql`

    ${fragment.page.item}

    mutation updatePageValue ($input: editValue!) {
        updatePageValue(input: $input) {
            ...pageItemFields
        }
    }
`;

export default updatePageValue;