import { View, ScrollView, FlatList } from 'react-native';
import { s, abs, api } from '../../../../root';
// import { ThrowErrorMain } from '../../../../modules/error/throw';
// import { Empty } from '../pods';
import { PictureFiller } from '../../../picture/suits.js';
import Picture from './Picture.js';

const Pictures = ({ svg, typename, params={}, picture={}, columns}) => {

    const fetch = api.picture.useFetch(picture.list);
    const list = fetch();

    // if (abs.isError(list)) return <ThrowErrorMain name='pictureMain' data={picture.list}/>
    // if (abs.isEmpty(list)) return <Empty typename={typename} svg={svg}/>
    if (!abs.isArray(list)) return null;

    const numColumns = columns<3 ? 3 : columns
    const numFillers = numColumns - (list.length % numColumns);

    const fillList = (numFillers) => {
        const result=[]
        for (let i=0; i < numFillers; i++) {
            result.push({isFiller:true})
          }
        return result
    }

    const { setId } = params;

    const renderItem = ({ item={} }) => {
        if (item.isFiller) return <PictureFiller/>
        const { itemId } = item;
        const current = {type:'item', id:itemId, fetchId:setId}
        return (
            <Picture 
                svg={svg}
                typename={typename}
                params={{ ...params, itemId }}
                picture={{list:picture.list, item:current}}
            />
        );
    };

    return (
        <FlatList 
            data={[...list,  ...fillList(numFillers)]}
            numColumns={numColumns} // >>>>>>>>>>>> se metto 7 e ce ne sono 4 divide lo spazio per 4 non ok da sistemare! (Grazie a Misha)
            renderItem={renderItem}
            key={numColumns}
            keyExtractor={item => item.itemId}
        />
    );
};
export default Pictures;