import { useEffect } from 'react';
import { abs } from '../../../root';
import { Button } from './pods';

const Network = ({ setIsNetworkOk, rerender }) => {

    useEffect(()=> {
        const check = async () => {
            if (await abs.isNetwork()) setIsNetworkOk(true)
        };
        check();
    });

    const onCheckNetwork = async () => {
        if (await abs.isNetwork()) setIsNetworkOk(true)
        else rerender();
    };

    return <Button onPress={onCheckNetwork} text='WL_error_network' isMain/>

};
export default Network;