import { Suspense, useState } from 'react';
import { ThrowErrorRemove } from '../../../../modules/error/throw';
import { AgentItem, AgentInline, AgentConfirm } from '../../../../app/book/suits.js';
import { Write, AgentRefetch } from '../../../../app/book/agents.js';

const AgentRemove = ({ operationId, action, typename, refetch }) => {

    const [response, setResponse] = useState();
    const isWritten = response==='success';

    console.log('remove response ', response)
 
    if (response==='error') return <ThrowErrorRemove name='bookRemove' data={{operationId, refetch}}/>
    return (
        <AgentItem svg='trash_16'>
            { operationId 
                ? <Suspense fallback={<AgentInline label={`WL_book_${typename}RemoveLoading`}/>}>
                    {isWritten 
                    ? <AgentRefetch refetch={refetch}/>
                    : <Write operationId={operationId} setResponse={setResponse} isRemove/>
                    }
                  </Suspense>
                : <AgentConfirm operation='remove' typename={typename} action={action}/>
            }
        </AgentItem>
    );
}; 
export default AgentRemove;