import { useRecoilState } from 'recoil';
import { store } from '../../../root';

const useModalAppend = (modalId, attribute) => {

    const [modals, setModals] = useRecoilState(store.modal.modals);

    const modalAppend = () => {        

        const newModals = modals.map( modal => {
            if (modal.id === modalId) return ({...modal, ...attribute})
        });

        console.log(
            'saaa modalAppend ', 
            '\nmodalId ', modalId, 
            '\nattribute ', attribute, 
            '\nmodals ', modals, 
            '\nnewModals ', newModals
        ),

        setModals(newModals);
    };

    return modalAppend;
};
export default useModalAppend;