import Constants from 'expo-constants';
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { store } from '../root';

const { MAIN_API_BASE_URL } = Constants.expoConfig.extra

const httpLink = new HttpLink({uri:MAIN_API_BASE_URL});

const authLink = setContext( async (_, {headers}) => {
    const token = store.user.accessToken;
    const authorization = token ? `Bearer ${token}` : '';
    console.log('xxx-apollo > setContext Date.now() ', Date.now(), ' authorization >', authorization)
    return { headers: { ...headers, authorization }};
});

const link = authLink.concat(httpLink);

console.log('xxx-apollo > new ApolloClient: link ', link)

const apollo = new ApolloClient({
  link,
  cache: new InMemoryCache({}),
  connectToDevTools: true,
  defaultOptions:{
    watchQuery: {fetchPolicy: 'no-cache', errorPolicy: 'ignore'},
    query: {fetchPolicy: 'no-cache', errorPolicy: 'all'},
  },
});
export default apollo;
