import { gql } from '@apollo/client';

const fragment = {

    book: {
        item: gql`
            fragment bookItemFields on Book {
                id
                title
                description
                cover
                logo
                metadata
                author_id
                signature
            }
        `,
    },

    section: {
        hook: gql`
            fragment sectionHookFields on Section {
                id
                position
                display
                signature
                author_id
                parent_id
                item_id
            }
        `,
        item: gql`
            fragment sectionItemFields on SectionItem {
                id
                value
                metadata
                author_id
                signature
            }
        `,
    },

    page: {
        hook: gql`
            fragment pageHookFields on Page {
                id
                position
                display
                signature
                author_id
                parent_id
                item_id
            }
        `,
        item: gql`
            fragment pageItemFields on PageItem {
                id
                value
                metadata
                author_id
                signature
            }
        `,
    },

    block: {
        hook: gql`
            fragment blockHookFields on Block {
                id
                position
                display
                signature
                author_id
                parent_id
                item_id
            }
        `,
        item: gql`
            fragment blockItemFields on BlockItem {
                id
                value
                metadata
                author_id
                signature
            }
        `,
    },

    data: {
        hook: gql`
            fragment dataHookFields on Data {
                id
                position
                display
                signature
                author_id
                parent_id
                item_id
            }
        `,
        item: gql`
            fragment dataItemFields on DataItem {
                id
                type
                key
                value
                metadata
                author_id
                signature
            }
        `,
    },
};
export default fragment;