import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const createBlock = gql`

    ${fragment.block.hook}
    ${fragment.block.item}

    mutation createBlock ($input: newElement!) {
        createBlock(input: $input) {
            ...blockHookFields 
            item {
                ...blockItemFields
            }       
        }
    }
`;

export default createBlock;
