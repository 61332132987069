const wordlist = {

    'WL_loader_backHome' : {
        en:'HOME PAGE',
        it:'HOME PAGE',
    },

    'WL_loader_login' : {
        en:'LOGIN',
        it:'ACCEDI',
    },
    'WL_loader_login_description' : {
        en:'This page might not exist or you need to be logged in to see its content',
        it:'Questa pagina potrebbe non esistere oppure hai bisogno di fare il Login per vederne il contenuto',
    },

    'WL_loader_network_no': {
        en:'CHECK CONNECTION',
        it:'CONTROLLA CONNESSIONE',
    },
    'WL_loader_network_no_description' : {
        en:'You are offline. Please connect to the network and retry.',
        it:'Sei Offline. Per favore, connettiti alla rete e riprova.',
    },

};
export default wordlist;