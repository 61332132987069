import { atomFamily, selectorFamily } from 'recoil';
import { store } from '../../../../root';
import { fetchSet, fetchBook, fetchPage } from '../../api';
// import writee from './write';

export const Book = atomFamily({
    key: 'book', 
    default: selectorFamily ({ 
        key: 'getBook',
        get: ([type, id]) => async () => {
            const { fetchId, isBook } = store.router.params;
            if (isBook) return await fetchBook(type, id, fetchId);
            else return await fetchSet(type, id, fetchId);
        },
    }),
    effects:[
        ({onSet, setSelf}) => {
            onSet(async(newValue) => {
                if (newValue==='retry') {
                    let value
                    const { type, id, fetchId, isBook } = store.router.params;
                    console.log('azz store.router.params ', store.router.params)
                    if (isBook) value = await fetchBook(type, id, fetchId);
                    else value = await fetchSet(type, id, fetchId);
                    console.log('azz value ', value)
                    setSelf(value)
                }
            })
        },
    ]
});

export const Section = atomFamily({
    key: 'section', 
    default: selectorFamily ({
        key: 'getSection',
        get: ([type, id]) => async () => {
            const { fetchId } = store.router.params;
            return await fetchBook(type, id, fetchId);
        }
    }),
    effects:[
        ({onSet, setSelf}) => {
            onSet(async(newValue) => {
                if (newValue==='retry') {
                    let value
                    const { type, id, fetchId } = store.router.params;
                    value = await fetchBook(type, id, fetchId);
                    setSelf(value)
                }
            })
        },
    ]
});

export const Page = atomFamily({
    key: 'page', 
    default: selectorFamily ({
        key: 'getPage',
        get: ([type, id]) => async () => {
            const { fetchId, isPage } = store.router.params;
            if (isPage) return await fetchPage(type, id, fetchId)
            else return await fetchBook(type, id, fetchId);
        }
    }),
    effects:[
        ({onSet, setSelf}) => {
            onSet(async(newValue) => {
                if (newValue==='retry') {
                    let value
                    const { type, id, fetchId, isPage } = store.router.params;
                    if (isPage) value = await fetchPage(type, id, fetchId)
                    else value = await fetchBook(type, id, fetchId);
                    setSelf(value)
                }
            })
        },
    ]
});

export const Block = atomFamily({
    key:'block', 
    default: selectorFamily ({
        key: 'getBlock',
        get: ([type, id]) => async () => {
            const { fetchId } = store.router.params;
            return await fetchPage(type, id, fetchId);
        }
    }),
    effects:[
        ({onSet, setSelf}) => {
            onSet(async(newValue) => {
                if (newValue==='retry') {
                    let value
                    const { type, id, fetchId } = store.router.params;
                    value = await fetchPage(type, id, fetchId);
                    setSelf(value)
                }
            })
        },
    ]
});

export const Data = atomFamily({
    key:'data', 
    default: selectorFamily ({
        key: 'getData',
        get: ([type, id]) => async () => {
            const { fetchId } = store.router.params;
            return await fetchPage(type, id, fetchId);
        }
    }),
    effects:[
        ({onSet, setSelf}) => {
            onSet(async(newValue) => {
                if (newValue==='retry') {
                    let value
                    const { type, id, fetchId } = store.router.params;
                    value = await fetchPage(type, id, fetchId);
                    setSelf(value)
                }
            })
        },
    ]
});

// export const write = atomFamily ({
//         key: 'write',
//         default: selectorFamily ({
//             key: 'getWrite',
//             get: (operationId) => async () => {
//                 return await writee(operationId);
//             }
//         }),
//         effects: operationId => [
//             ({onSet, setSelf}) => {
//                 onSet(async(newValue) => {
//                     if (newValue==='retry') {
//                         const value = await writee(operationId);
//                         setSelf(value)
//                     }
//                 })
//             },
//         ]
//     });

// export const rush = { };
    


    // picture: {

    //     list: atomFamily ({
    //         key: 'mediaList',
    //         default: selectorFamily ({
    //             key: 'getMediaList',
    //             get: (catalogId) => async () => await endpoint.list('picture', catalogId)
    //         }),
    //     }), 

    //     create: atomFamily ({
    //         key: 'mediaCreate',
    //         default: selectorFamily ({
    //             key: 'getMediaCreate',
    //             get: (operationId) => async () => await endpoint.create('picture', operationId)
    //         }),
    //     }),

    // },

    // pictures: atomFamily ({
    //     key: 'pictures',
    //     default: selectorFamily ({
    //         key: 'getPictures',
    //         get: (userId) => async () => await read.getPictures(userId)
    //     }),
    // }),

    // newPicture: atomFamily ({
    //     key: 'newPicture',
    //     default: selectorFamily ({
    //         key: 'getNewPicture',
    //         get: (file) => async () => {
    //             return await write (file);
    //         }
    //     }),
    // }),