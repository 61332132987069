import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateDataMetadata = gql`

    ${fragment.data.item}

    mutation updateDataMetadata ($input: editMetadata!) {
        updateDataMetadata(input: $input) {
            ...dataItemFields
        }
    }
`;

export default updateDataMetadata;