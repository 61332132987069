import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const createSection = gql`

    ${fragment.section.hook}
    ${fragment.section.item}

    mutation createSection ($input: newElement!) {
        createSection(input: $input) {
            ...sectionHookFields
            item {
                ...sectionItemFields
            }       
        }
    }
`;

export default createSection;
