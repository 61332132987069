import { View, ScrollView } from 'react-native';
import { v4 as getUUID } from 'uuid';
import { s, use, abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { PageTitlebar, PageElement } from '../../../../app/book/suits';
import Sections from './Sections.js';

const Book = ({ svg, typename, params, book }) => {

    const t = use.useText();

    const fetchItem = api.book.useFetch(book.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorMain name='bookPage' data={book.item}/>

    const { bookItemId } = params;
    
    const childTypename = abs.book.childOf(typename);
    const childSvg = abs.book.svg({typename, isChild:true});
    const childList = {typename:childTypename, type:'list', id:bookItemId, fetchId:bookItemId};

    const id = getUUID();
    const popover = abs.book.popover({
        header: {svg, title:item.title},
        popover: {id, typename, sectionPosition:0, ...params, item},
        options: {name:'optionsBookTitle', linkTo:abs.book.link('cover', params)},
        adder: {name:'adderSection'},
    });

    const titlebarId = id+'titlebar';
    const titlebar = abs.book.popover({
        header: {svg, title:t('WL_book_index')},
        popover: {id:titlebarId, typename, sectionPosition:0, ...params, item, navigateTo:abs.book.link('books', params)},
        options: {name:'optionsTitleBookIndex', linkTo:abs.book.link('cover', params)},
        adder: {name:'adderTitleBookIndex'},
    });

    if (!item?.id) return null
    return (
        <>
            <PageTitlebar 
                id={titlebarId} 
                title={`WL_book_index`} 
                backTo={abs.book.link('books', params)} 
                options={titlebar.options} adder={titlebar.adder}
            />
            <View style={[s.scrollView]}>
            <ScrollView style={[s.scrollView]}>
                <PageElement 
                    id={id} 
                    svg={svg} 
                    text={item.title} 
                    options={popover.options} 
                    adder={popover.adder}
                />
                <Sections
                    svg={childSvg}
                    typename={childTypename}
                    params={params}
                    section={{list:childList}}
                />
            </ScrollView>
            </View>
        </>
    );
}; 
export default Book;

