import { useRecoilValue, useSetRecoilState } from 'recoil';
import { store } from '../../../../root';
import { PopoverLink } from '../../../../app/book/suits.js';

const Open = ({ main, second, isLine }) => {

    // get the data needed from the popover
    const popover = useRecoilValue(store.popover.popover);
    const { linkTo } = popover;
    
    return <PopoverLink linkTo={linkTo} main={main} second={second} isLine={isLine}/>
};
export default Open;