import fetchSet from './fetchSet.js';
import fetchBook from './fetchBook.js';
import fetchSection from './fetchSection.js';
import fetchPage from './fetchPage.js';
import fetchBlock from './fetchBlock.js';
import fetchData from './fetchData.js';

const gql = {

    fetchSet,
    fetchBook,
    fetchSection,
    fetchPage,
    fetchBlock,
    fetchData,
    
};
export default gql;