import { atom } from 'recoil';

const store = {
    
    operation: {},

    cache: {
        list: {},
        hook: {},
        item: {},
        bond: {},
    },

    mode: atom({key:'mode', default:'write'}),
    view: atom({key:'view', default:'expert'}),
    properties: atom({key:'properties', default:'topic'}),
};
export default store;