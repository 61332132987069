import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateDataKey = gql`

    ${fragment.data.item}

    mutation updateDataKey ($input: editKey!) {
        updateDataKey(input: $input) {
            ...dataItemFields
        }
    }
`;

export default updateDataKey;