import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updatePage = gql`

    ${fragment.page.item}

    mutation updatePage ($input: editElement!) {
        updatePage(input: $input) {
            ...pageItemFields
        }
    }
`;

export default updatePage;