import { Suspense, useState } from 'react';
import { ThrowErrorModal } from '../../../modules/error/throw';
import { AgentButton } from '../../../app/picture/suits.js';
import { Write, AgentRefetch } from '../../../app/picture/agents.js';

const AgentWrite = ({ operationId, action, isDisabled, refetch }) => {

    const [response, setResponse] = useState();
    const isWritten = response==='success';
  
    if (response==='error') return <ThrowErrorModal name='pictureWrite' data={{operationId, refetch}}/>
    return (<>
        { operationId 
            ? <Suspense fallback={<AgentButton label='WL_picture_loading'/>}>
                {isWritten 
                ? <AgentRefetch refetch={refetch}/>
                : <Write operationId={operationId} setResponse={setResponse}/>
                }
              </Suspense>
            : <AgentButton label='WL_picture_save_and_close' action={action} isDisabled={isDisabled}/>
        }
    </>);
}; 
export default AgentWrite;