import { useRecoilValue } from 'recoil';
import { store } from '../../../root';

const useGetPopoverStyle = () => {

    // get the direction to calculate the position where to open the popover 
    const direction = useRecoilValue(store.layout.direction);
    // get the fontSize to calculate the position there to open the popover
    const fontSize = useRecoilValue(store.layout.fontSize);

    const getPopoverStyle = (coordinates, windowY) => {

        const {top, bottom, left, right} = coordinates;
        
        const position = (bottom - top)/2 + top;
        const midPage = windowY / 2;
        const topbar = 8*fontSize;
        const lowbar = 4.75*fontSize;
        const popoverWidth = 16*fontSize;
        const borderWidth = 0.125*fontSize;

        const result = {}
        
        if (direction === 'right-to-left') {
            result.left = right;
        } else {
            result.left = left - popoverWidth - borderWidth;
        };
        if (position > midPage) {
            result.maxHeight = windowY - topbar - (windowY - bottom);
            result.bottom = windowY - bottom;
        } else {
            result.maxHeight = windowY - lowbar - top;
            result.top = top;
        };
        return result;
    };
    return getPopoverStyle;
};
export default useGetPopoverStyle;