import { atom } from 'recoil';

const store = {
    
    operation: {},

    cache: {
        list: {},
        item: {},
    },

    // mode: atom({key:'pictureMode', default:'write'}),
    // view: atom({key:'pictureView', default:'expert'}),
    // attributes: atom({key:'pictureAttributes', default:'topic'}),
};
export default store;