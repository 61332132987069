import { Pressable } from 'react-native';
import { Textline, Icon } from '../../../modules/basics/components';
import { s, use } from '../../../root';

const Expander = ({ WL, text, isOn, isCurrent, onPress }) => {

    const c = use.useColor();

    const color = !isOn && isCurrent ? c.onBarC1 : c.onBarN1;
    const svg = isOn ? 'arrowChevronDown_16' : 'arrowChevronRight_16';

    const style = {
        expander:   [s.row_sXcY, s.Y40, s.space.mB2],
        icon:       [s.X48Y40],
        text:       [s.space.pE16, {lineHeight:24, fontWeight:'bold'}],
    };
    
    return ( 
        <Pressable style={style.expander} onPress={onPress}>
            <Icon style={style.icon} color={color} svg={svg} onPress={onPress}/>
            <Textline style={style.text} color={color} WL={WL} text={text}/>
        </Pressable>
    );
};
export default Expander;
