import { abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import Page from './Page.js';

const Pages = ({ svg, typename, params, page }) => {

    const fetchList = api.book.useFetch(page.list);
    const list = fetchList();

    if (abs.isError(list)) return <ThrowErrorMain name='bookPage' data={page.list}/>
    if (!abs.isArray(list)) return null;

    return (<>
        {list.map((current, index) => {
            const { bookItemId } = params;
            const pageHookId = current.hookId;
            const pageItemId = current.itemId;
            const hook = {typename, type:'hook', id:pageHookId, fetchId:bookItemId};
            const item = {typename, type:'item', id:pageItemId, fetchId:bookItemId};
            return (
                <Page
                    svg={svg}
                    typename={typename}
                    params={{...params, pageHookId, pageItemId}}
                    page={{list:page.list, hook, item}}
                    pagePosition={index+1}
                    key={current.hookId} 
                />
            );
        })}
    </>);
}; 
export default Pages;