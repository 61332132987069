import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { store, abs } from '../../../../root';
import { Modal } from '../../../../modules/modal/components';
import { AgentWrite } from '../../../../app/book/agents.js';
import { ModalTextField } from '../../../../app/book/suits.js';

const EditPage = ({id}) => {

    const typename = 'Page';

    const popover = useRecoilValue(store.popover.popover)
    const { pageItemId, item } = popover;

    const [operationId, setOperationId] = useState('');

    const [value, setValue] = useState(item.value);
    const [metadata, setMetadata] = useState(item.metadata);

    const save = () => {
        const input = { value, metadata, id:pageItemId };
        const operation = 'update';
        const itemId = pageItemId;
        const generatedId = abs.book.operation.add({typename, operation, input, itemId});
        setOperationId(generatedId);
    };

    const title = 'WL_book_PageUpdate';
    const svg = 'P_16_fill_24';

    const refetch = {refetch:'update', operationId, modalId:id};

    const actions = <AgentWrite operationId={operationId} action={save} refetch={refetch} isDisabled={!value}/>

    return (
        <Modal id={id} title={title} svg={svg} actions={actions}>
            <ModalTextField typename={typename} field={'value'} value={value} setValue={setValue}/>
            <ModalTextField typename={typename} field={'metadata'} value={metadata} setValue={setMetadata}/>
        </Modal>
    );
}; 
export default EditPage;