import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const fetchSection = gql`

    ${fragment.section.hook}
    ${fragment.section.item}
    ${fragment.page.hook}
    ${fragment.page.item}

    query fetchSection ($input: id!) {
        fetchSection(input: $input) {
            ...sectionHookFields
            item {
                ...sectionItemFields
                childHooks {
                    ...pageHookFields 
                    item {
                        ...pageItemFields
                    }
                }
            }
        }
    }
`;
export default fetchSection;