import { Route } from '../platform/router';

import { LanguagePage, PreferencesPage } from '../modules/layout/pages.js';

import { HomePage, CreatorPage, DominoPage } from '../app/home/pages.js';
import { LandingPage } from '../app/landing/pages.js';

import { BookList, BookCover, BookIndex, BookPage, BookOverview } from '../app/book/pages.js';
import { PictureList, PictureOverview } from '../app/picture/pages';

const routes = {

    private: 
        <>
            <Route path='/set/:set_id/books/overview' element={<BookOverview/>}/>
            <Route path='/set/:set_id/books/list' element={<BookList filter='list'/>}/>
            <Route path='/set/:set_id/books/publications' element={<BookList filter='publications'/>}/>
            <Route path='/set/:set_id/books/bookmarks' element={<BookList filter='bookmarks'/>}/>
            
            <Route path='/set/:set_id/book/:book_id/cover' element={<BookCover/>}/>
            <Route path='/set/:set_id/book/:book_id/index' element={<BookIndex/>}/>
            <Route path='/set/:set_id/book/:book_id/section/:section_id/:section_item_id/page/:page_id/:page_item_id' element={<BookPage/>}/>

            <Route path='/set/:set_id/pictures/overview' element={<PictureOverview/>}/>
            <Route path='/set/:set_id/pictures/list' element={<PictureList filter='list'/>}/>
            <Route path='/set/:set_id/pictures/publications' element={<PictureList filter='publications'/>}/>
            <Route path='/set/:set_id/pictures/bookmarks' element={<PictureList filter='bookmarks'/>}/>
        </>,

    public: 
        <>  
            <Route path='/' element={<HomePage/>}/>

            <Route path='/creator' element={<CreatorPage/>}/>
            <Route path='/domino' element={<DominoPage/>}/>

            <Route path='/language' element={<LanguagePage/>}/>
            <Route path='/preferences' element={<PreferencesPage/>}/>

            <Route path='/legal/cookies' element={<LandingPage/>}/>
            <Route path='/legal/privacy' element={<LandingPage/>}/>
            <Route path='/legal/terms' element={<LandingPage/>}/>
        </>
};
export default routes;

export const publicRoutes = [
    '', 
    'books', 
    'creator', 
    'domino',
    'language', 
    'preferences', 
    'legal',
];