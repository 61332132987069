import { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { api, abs, store } from '../../../root';
import { Network, Auth, Close } from '../../../modules/error/triggers.js';
import { RelaunchOperation, CleanupPage } from '../../../app/book/actions.js';
import { ErrorPage } from '../../../modules/error/pages'

const BookReorder = ({errorType}) => { 

    const [isNetworkOk, setIsNetworkOk] = useState(false);
    const [isAuthOk, setIsAuthOk] = useState(null);
    const [isIssueOk, setIsIssueOk] = useState(false);

    const user = useRecoilValue(api.user.user);

    useEffect(() => {
        const check = async () => { 
            if (await abs.isNetwork()) setIsNetworkOk(true)
            if (await abs.isAuth() && user.id) setIsAuthOk(true)
        };
        check();
    }, [user]);

    const url = useRecoilValue(store.router.url);
    const [error={}, setError] = useRecoilState(store.error[errorType]);
    useEffect(() => { if (url !== error.url) setError({}) })

    const [toggler, setToggler] = useState(false);
    const rerender = () => setToggler(!toggler)

    return (
        <ErrorPage source='bookRefetch'
            isNetworkOk={isNetworkOk}
            isAuthOk={isAuthOk}
            isIssueOk={isIssueOk}

            network = {<Network rerender={rerender} setIsNetworkOk={setIsNetworkOk}/>}
            auth = {<Auth setIsAuthOk={setIsAuthOk} setIsNetworkOk={setIsNetworkOk}/>}
            close = {<Close errorType={errorType}/>}
            action = {<RefetchOperation rerender={rerender} setIsIssueOk={setIsIssueOk}/>}
            cleanup = {<CleanupPage/>}
        />
    );
};
export default BookReorder;