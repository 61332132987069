import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { abs, store } from '../../../../root';
import { Modal } from '../../../../modules/modal/components';
import { SelectorArrange } from '../../../../app/book/selectors';
import { AgentWrite } from '../../../../app/book/agents.js';

const ArrangePage = ({ id, arrange, navParams={} }) => {

    const typename = 'Page';
    const popover = useRecoilValue(store.popover.popover)
    const { 
        pagePosition,
        setId, bookItemId, 
        sectionHookId, sectionItemId, 
        pageHookId 
    } = popover;

    const [operationId, setOperationId] = useState('');

    const [start, setStart] = useState();
    const [destination_id, setDestination_id] = useState();
    const [destinationHookId, setDestinationHookId] = useState();

    const [selected, setSelected] = useState('');

    const isMove = arrange==='move';

    const save = () => {
        const input = { ids:[pageHookId], origin_id:sectionItemId, start, destination_id };
        const typename = 'Page';
        const operation = arrange;
        const origin = {typename, type:'list', id:sectionItemId, fetchId:sectionHookId};
        const destination = {typename, type:'list', id:destination_id, fetchId:destinationHookId};
        const generatedId = abs.book.operation.add({typename, operation, input, origin, destination});
        setOperationId(generatedId);
    };

    const isDisabled = !start || !destination_id; 
    const title = 'WL_book_PageHook';
    const svg = 'P_16_fill_24';

    const deselect = () => {
        setStart('');
        setDestination_id('');
        setSelected('');
    };

    const refetch = {refetch:'arrange', operationId, modalId:id};

    const actions = <AgentWrite operationId={operationId} action={save} refetch={refetch} isDisabled={isDisabled}/>

    const initial = { isMove, itemId:sectionItemId, position:pagePosition };
    const initParams = navParams.typename ? navParams:{typename, setId, bookItemId, sectionHookId, sectionItemId};

    const select = (selected={}) => {
        setStart(selected.start);
        setDestination_id(selected.destination_id);
        setDestinationHookId(selected.destinationHookId);
    };

    const selection = { selectable:'Page', initial, select, deselect, selected, setSelected }; 

    return (
        <Modal id={id} title={title} svg={svg} actions={actions}>
            <SelectorArrange selection={selection} initParams={initParams} modalId={id}/>
        </Modal>
    );
}; 
export default ArrangePage;