import { useSetRecoilState } from 'recoil';
import { store } from '../../../root';
import { Icon } from '../../../modules/basics/components';

const Pop = ({ style=[], color, svg, popover }) => {

    const setPopover = useSetRecoilState(store.popover.popover);
    const onPress = () => setPopover(popover);

    return <Icon style={style} color={color} svg={svg} onPress={onPress}/>
};
export default Pop;