import { Open, Remove, New } from '../../app/picture/actions';

export const optionsPictureItem = 
    <>
        <Open main='WL_picture_open' second='WL_picture_the_Picture' isLine/>
        <Remove name='removePicture' main='WL_picture_remove' second='WL_picture_this_Picture' svg='trash_16'/>
    </> 

export const adderTitlePictureList = 
    <>
        <New name='newPicture' main='WL_picture_create' second='WL_picture_a_new_Picture' svg='plus_14_line_24'/>
    </>