import { v4 as getUUID } from 'uuid';
import { abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { PageElement } from '../../../../app/book/suits';
import Datas from './Datas.js';

const Block = ({ svg, typename, params={}, block={}, blockPosition }) => {

    const fetchItem = api.book.useFetch(block.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorMain name='bookPage' data={block.item}/>

    const { pageHookId, blockItemId } = params;

    const childTypename = abs.book.childOf(typename);
    const childSvg = abs.book.svg({typename, isChild:true});
    const childList = {typename:childTypename, type:'list', id:blockItemId, fetchId:pageHookId};

    const id = getUUID(); 
    const popover = abs.book.popover({
        header: {svg, title:item.value},
        popover: {id, typename, block, blockPosition, dataPosition:0, ...params, item},
        options: {name:'optionsBlock'},
        adder: {name:'adderBlockData'},
    });

    return (
        <>
            <PageElement id={id} svg={svg} text={item.value} options={popover.options} adder={popover.adder}/>
            <Datas
                svg={childSvg}
                typename={childTypename}
                params={params}
                data={{list:childList}}
            />
        </>
    );
}; 
export default Block;