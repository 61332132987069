const wordlist = {

    'WL_router_login' : {
        en:'LOGIN',
        it:'ACCEDI',
    },
    'WL_router_backHome' : {
        en:'HOME PAGE',
        it:'HOME PAGE',
    },

    'WL_router_login_description' : {
        en:'This page might not exist or you need to be logged in to see its content',
        it:'Questa pagina potrebbe non esistere oppure hai bisogno di fare il Login per vederne il contenuto',
    },


};
export default wordlist;