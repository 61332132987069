import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateSection = gql`

    ${fragment.section.item}

    mutation updateSection ($input: editElement!) {
        updateSection(input: $input) {
            ...sectionItemFields
        }
    }
`;

export default updateSection;