import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { View } from 'react-native';
import { Logo } from '../../../modules/basics/components';
import { s, api, use, store } from '../../../root';
import guestPicture from '../../../../assets/guest.png';
import userPicture from '../../../../assets/user.png';

WebBrowser.maybeCompleteAuthSession();

const Auth = () => {  

    const c = use.useColor();

    const user = useRecoilValue(api.user.user);
    const setUserbar = useSetRecoilState(store.user.userbar);
    
    const [request, result, promptAsync] = AuthSession.useAuthRequest(store.user.params, store.user.discovery);
    const onLogin = () => promptAsync();
    const tokenFetch = use.user.useTokenFetch(result, store.user.discovery);
    tokenFetch
    
    const logout = use.user.useLogout();
    const onLogout = async () => {
        await AuthSession.revokeAsync({ token: store.user.accessToken, ...store.user.params }, store.user.discovery);
        await AuthSession.revokeAsync({ token: store.user.refreshToken, ...store.user.params }, store.user.discovery);
        logout();
    };

    const onClickGuest = () => { setUserbar({isOn:true, action:onLogin})};
    const onClickUser = () => { if (request) setUserbar({isOn:true, action:onLogout})};

    const style = { 
        box:    [s.row_cXcY, s.XY48],
        logo:   [s.XY32],
    };

    return (
        <View style={style.box}>
            { user?.id 
              ? <Logo style={style.logo} color={c.line.logoC1} src={userPicture} onPress={onClickUser}/>
              : <Logo style={style.logo} color={c.line.logoN1} src={guestPicture} onPress={onClickGuest}/>
            }
        </View>
    );
}
export default Auth;