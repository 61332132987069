import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateBookDescription = gql`

    ${fragment.book.item}

    mutation updateBookDescription ($input: editDescription!) {
        updateBookDescription(input: $input) {
            ...bookItemFields
        }
    }
`;

export default updateBookDescription;