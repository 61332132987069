import hookSection from './hookSection.js';
import hookPage from './hookPage.js';
import hookBlock from './hookBlock.js';
import hookData from './hookData.js';

import moveSection from './moveSection.js';
import movePage from './movePage.js';
import moveBlock from './moveBlock.js';
import moveData from './moveData.js';

import copySection from './copySection.js';
import copyPage from './copyPage.js';
import copyBlock from './copyBlock.js';
import copyData from './copyData.js';

import createBook from './createBook.js';
import createSection from './createSection.js';
import createPage from './createPage.js';
import createBlock from './createBlock.js';
import createData from './createData.js';

import removeBook from './removeBook.js';
import removeSection from './removeSection.js';
import removePage from './removePage.js';
import removeBlock from './removeBlock.js';
import removeData from './removeData.js';

import updateBook from './updateBook.js';
import updateBookTitle from './updateBookTitle.js';
import updateBookDescription from './updateBookDescription.js';
import updateBookMetadata from './updateBookMetadata.js';
import updateBookCover from './updateBookCover.js';
import updateBookLogo from './updateBookLogo.js';

import updateSection from './updateSection.js';
import updateSectionValue from './updateSectionValue.js';
import updateSectionMetadata from './updateSectionMetadata.js';

import updatePage from './updatePage.js';
import updatePageValue from './updatePageValue.js';
import updatePageMetadata from './updatePageMetadata.js';

import updateBlock from './updateBlock.js';
import updateBlockValue from './updateBlockValue.js';
import updateBlockMetadata from './updateBlockMetadata.js';

import updateData from './updateData.js';
import updateDataValue from './updateDataValue.js';
import updateDataMetadata from './updateDataMetadata.js';
import updateDataKey from './updateDataKey.js';
import updateDataType from './updateDataType.js';

const gql = {

    hookSection,
    hookPage,
    hookBlock,
    hookData,

    moveSection,
    movePage,
    moveBlock,
    moveData,

    copySection,
    copyPage,
    copyBlock,
    copyData,

    createBook,
    createSection,
    createPage,
    createBlock,
    createData,

    removeBook,
    removeSection,
    removePage,
    removeBlock,
    removeData,

    updateBook,
    updateBookTitle,
    updateBookDescription,
    updateBookMetadata,
    updateBookCover,
    updateBookLogo,

    updateSection,
    updateSectionValue,
    updateSectionMetadata,

    updatePage,
    updatePageValue,
    updatePageMetadata,

    updateBlock,
    updateBlockValue,
    updateBlockMetadata,

    updateData,
    updateDataValue,
    updateDataMetadata,
    updateDataKey,
    updateDataType,
    
};
export default gql;