import { useState } from 'react';
import { abs } from '../../../../root';
import { Modal } from '../../../../modules/modal/components';
import { AgentWrite } from '../../../../app/book/agents.js';
import { ModalTextField } from '../../../../app/book/suits.js';

const EditAttribute = ({ id, typename, attribute, ids, item }) => {

    const { bookItemId, sectionItemId, pageItemId, blockItemId, dataItemId } = ids;

    const itemId = {
        Book:bookItemId,
        Section:sectionItemId,
        Page:pageItemId, 
        Block:blockItemId,
        Data:dataItemId,
    };

    const [operationId, setOperationId] = useState('');
    const [value, setValue] = useState(item[attribute]);

    const attributeInput = {
        title: { title:value },
        description: { description:value },
        metadata: { metadata:value },
        value: { value:value },
        cover: { cover:value },
        logo: { logo:value },
        key: { key:value },
        type: { type:value }
    };

    const save = () => {
        const input = {...attributeInput[attribute], id:itemId[typename]};
        const operation = 'update';
        const generatedId = abs.book.operation.add({typename, operation, input, attribute:abs.up(attribute), itemId:itemId[typename]});
        setOperationId(generatedId);
    };

    const title = `WL_book_${typename}${'Update'}${abs.up(attribute)}`;
    const svg = 'text_16';

    const refetch = {refetch:'update', operationId, modalId:id};

    const isDisabled = !value && (attribute==='title' || attribute==='value')
    const actions = <AgentWrite operationId={operationId} action={save} refetch={refetch} isDisabled={isDisabled}/>

    return (
        <Modal id={id} title={title} svg={svg} actions={actions}>
            <ModalTextField typename={typename} field={attribute} value={value} setValue={setValue}/>
        </Modal>
    );
}; 
export default EditAttribute;