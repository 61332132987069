import { View, ScrollView } from 'react-native';
import { v4 as getUUID } from 'uuid';
import { s, use, abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { PageTitlebar, PageElement } from '../../../../app/book/suits';
import Blocks from './Blocks.js';

const Page = ({ svg, typename, params={}, page={} }) => {

    const t = use.useText();

    const fetchList = api.book.useFetch(page.list);
    const list = fetchList();

    const fetchItem = api.book.useFetch(page.item);
    const item = fetchItem();

    if (abs.isError(list) || !abs.isArray(list)) return <ThrowErrorMain name='bookPage' data={page.list}/>
    if (abs.isError(item)) return <ThrowErrorMain name='bookPage' data={page.item}/>

    const { setId, bookItemId, pageHookId, pageItemId } = params;

    const childTypename = abs.book.childOf(typename);
    const childSvg = abs.book.svg({typename, isChild:true});
    const childList = {typename:childTypename, type:'list', id:pageItemId, fetchId:pageHookId};

    const pagePosition = list.findIndex(current => current.hookId === pageHookId)+1;

    const id = getUUID();
    const popover = abs.book.popover({
        header: {svg, title:item.value},
        popover: {id, typename, page, pagePosition, blockPosition:0, ...params, item},
        options: {name:'optionsPageTitle'},
        adder: {name:'adderBlock'},
    });

    const titlebarId = id+'titlebar';
    const titlebar = abs.book.popover({
        header: {svg, title:t('WL_book_Page')},
        popover: {id:titlebarId, typename, pagePosition, ...params, item, navigateTo:abs.book.link('index', params)},
        options: {name:'optionsTitleBookPage', linkTo:abs.book.link('index', params)},
        adder: {name:'adderTitleBookPage'},
    });

    if (!item?.id) return null
    return (
        <>
            <PageTitlebar
                id={titlebarId} 
                title={`WL_book_Page`} 
                backTo={abs.book.link('index', params)} 
                options={titlebar.options} adder={titlebar.adder}
            />
            <View style={[s.scrollView]}>  
            <ScrollView style={[s.scrollView]}> 
                <PageElement id={id} svg={svg} text={item.value} options={popover.options} adder={popover.adder}/>
                <Blocks
                    svg={childSvg}
                    typename={childTypename}
                    params={params}
                    block={{list:childList}}
                />
            </ScrollView>      
            </View>
        </>
    );
}; 
export default Page;