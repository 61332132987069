import { abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { MenuLink } from '../../../../modules/basics/menus';

const Page = ({ url, typename, params, page, sectionPosition, pagePosition }) => {

    const { pageHookId } = params;

    const current = abs.book.params(url);
    const isCurrentPage = current.pageHookId === pageHookId;

    const fetchItem = api.book.useFetch(page.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorMain name='bookPage' data={page.item}/>

    return (
        <MenuLink
            linkTo={abs.book.link('page', params)}
            text={item.value}
            textual={`${sectionPosition}.${pagePosition}`}
            isActive={isCurrentPage}
        />
    );
}; 
export default Page;