import { View, Text } from 'react-native';
import { Titlebar } from '../../../modules/basics/components';
import { abs } from '../../../root';

const DominoPage = () => {

    return (
        <View>
            <Titlebar 
                backTo='/'
                title='WL_home_domino'
            />
            <Text>This is the Domino Page</Text>
            {/* <Link 
                // linkTo={`/set/${userId}/books`} 
                // isActive={abs.book.isUrlCover(url)}
                svg='book_16' 
                WL text='WL_home_draft' 
            />
            <Link 
                // linkTo={`/set/${userId}/books`} 
                // isActive={abs.book.isUrlCover(url)}
                svg='book_16' 
                WL text='WL_home_publications' 
            />
            <Link 
                // linkTo={`/set/${userId}/books`} 
                // isActive={abs.book.isUrlCover(url)}
                svg='book_16' 
                WL text='WL_home_bookmarks' 
            /> */}
        </View>
    );
};
export default DominoPage;