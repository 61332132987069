import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateBook = gql`

    ${fragment.book.item}

    mutation updateBook ($input: editBook!) {
        updateBook(input: $input) {
            ...bookItemFields
        }
    }
`;

export default updateBook;