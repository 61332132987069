import basics from '../modules/basics/wordlist.js'; 
import cookies from '../modules/cookies/wordlist.js'; 
import layout from '../modules/layout/wordlist.js'; 
import loader from '../modules/loader/wordlist.js'; 
import user from '../modules/user/wordlist.js'; 
import router from '../modules/router/wordlist.js'; 

import alert from '../modules/alert/wordlist.js'; 
import errorWL from '../modules/error/wordlist.js'; 

import book from '../app/book/wordlist.js'; 
import home from '../app/home/wordlist.js'; 
import landing from '../app/landing/wordlist.js'; 
import picture from '../app/picture/wordlist.js'; 

const wordlist = {

    ...basics,
    ...cookies,
    ...layout,
    ...loader,
    ...user,
    ...router,

    ...alert,
    ...errorWL,

    ...book,
    ...home,
    ...landing,
    ...picture,

}; 
export default wordlist;