import { View } from 'react-native';
import { s } from '../../../../root';
import Item from './Item.js';

const SidebarContent = ({isTwo }) => {

    const style = { menu: [s.col, s.Yc, s.X] };

    return (
        <View style={style.menu}>
            <Item width='35%' isFlip={isTwo}/>
            <Item width='39%' isFlip={isTwo}/>
            <Item width='66%' isFlip={isTwo}/>
            <Item width='54%' isFlip={isTwo}/>
            <Item width='21%' isFlip={isTwo}/>
            <Item width='68%' isFlip={isTwo}/>
            <Item width='55%' isFlip={isTwo}/>
            <Item width='34%' isFlip={isTwo}/>
            <Item width='55%' isFlip={isTwo}/>
            <Item width='77%' isFlip={isTwo}/>
            <Item width='54%' isFlip={isTwo}/>
            <Item width='34%' isFlip={isTwo}/>
        </View>
    );
}; 
export default SidebarContent;