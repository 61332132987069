import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updateData = gql`

    ${fragment.data.item}

    mutation updateData ($input: editData!) {
        updateData(input: $input) {
            ...dataItemFields
        }
    }
`;

export default updateData;