import { store } from '../../../root';

const bond = { 

    add: (itemId, hookId) => {
        // if is not an array, create an array with hookId inside
        if (!Array.isArray(store.book.cache.bond[itemId])) store.book.cache.bond[itemId]=[hookId];
        // if it is an array and NOT includes the hookId, push the hookId
        else if (!store.book.cache.bond[itemId].includes(hookId)) store.book.cache.bond[itemId].push(hookId);
    },

    remove: (itemId, hookId) => {
        // if is not an array, return true so item will be deleted
        if (!Array.isArray(store.book.cache.bond[itemId])) return true;
        // put in nextBond all the hookId except the current one (it should exist and be different)
        const nextBond = store.book.cache.bond[itemId].filter(currentId => currentId !== hookId);
        // if nextBond is an empty array the hookId is the last one so return true and item will be deleted
        if (!nextBond.length) return true;
        // at this point set the bond with the remaining hookIds and return false
        store.book.cache.bond[itemId] = nextBond;
        return false;
    },

    replace: (temporary, permanent) => {

        console.log('store > ', store, '\ntemporary > ', temporary, '\npermanent > ', permanent)
        // put in additionalBonds any other hook except the current one (it should exist and be different)
        const additionalHooks = store.book.cache.bond[temporary.itemId].filter(currentId => currentId !== temporary.hookId);
        // if there was any, replace the itemId in each of them with the response.itemId
        if (additionalHooks.length) {
            for (const current of additionalHooks){
                store.book.cache.hook[current.hookId].item_id = permanent.itemId;
            };
        };
        // create the bond for response.item equal to response.hookId plus eventual additional hooks
        store.book.cache.bond[permanent.itemId] = [permanent.hookId, ...additionalHooks];
        // remove the bond with temporary ItemId
        delete store.book.cache.bond[temporary.itemId];
    },
};
export default bond;