// import book from '../app/book/footers';
// import home from '../app/home/footers';

const footers = {

    // ...book,
    // ...home,
    
};

export default footers;