import { useRecoilValue } from 'recoil';
import { store} from '../../../../root';

import { AgentRefetch } from '../../../../app/book/agents.js';

const RefetchOperation = ({errorType}) => {

    const error = useRecoilValue(store.error[errorType])||{};

    const { refetch } = error;

    return <AgentRefetch errorType={errorType} refetch={refetch}/>

};
export default RefetchOperation;