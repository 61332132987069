import { View, ScrollView } from 'react-native';
import { s, abs, api } from '../../../../root';
import { SelectorToolbar, SelectorEmpty } from '../../../../app/book/suits.js';
import { ThrowErrorModal } from '../../../../modules/error/throw';
import Book from './Book.js';

const Books = ({ modalId, params, navigate }) => {

    const { typename, setId } = params;

    const toolbar = <SelectorToolbar typename='Set' isDisabled/>

    const bookList = {typename, type:'list', id:setId, fetchId:setId};
    const fetchList = api.book.useFetch(bookList);
    const list = fetchList();

    if (abs.isError(list)) return <ThrowErrorModal name='bookSelector' data={{data:bookList, params, modalId}}/>
    if (abs.isEmpty(list)) return <SelectorEmpty typename={typename} toolbar={toolbar}/>
    if (!abs.isArray(list)) return null;

    return (<>
        {toolbar}
        <View style={[s.scrollView]}>
        <ScrollView style={[s.scrollView]}>
            {list.map(current => {
                const bookItemId = current.itemId;
                const item = {typename, type:'item', id:bookItemId, fetchId:setId};
                return (
                    <Book
                        modalId={modalId}
                        params={{...params, bookItemId}}
                        book={{list:bookList, item}}
                        navigate={navigate}
                        key={current.itemId} 
                    />
                );
            })}
        </ScrollView>
        </View>
    </>);
}; 
export default Books;