import { useRecoilValue } from 'recoil';
import { store, errors } from '../../../root';

const ErrorModal = () => {

    const modals = useRecoilValue(store.modal.modals)
    console.log('XXX modals inside error modal', modals)

    const error = useRecoilValue(store.error.modal);
    const { name } = error;

    console.log('inside error name:', name)
    
    if (!name) return null
    return errors[name];

}; 
export default ErrorModal;