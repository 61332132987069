import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const updatePageMetadata = gql`

    ${fragment.page.item}

    mutation updatePageMetadata ($input: editMetadata!) {
        updatePageMetadata(input: $input) {
            ...pageItemFields
        }
    }
`;

export default updatePageMetadata;