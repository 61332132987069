import { Field, Label, TextInput } from '../../../../modules/basics/inputs';

const TextField = ({ typename, field, value, setValue }) => {

    return (
        <Field>
            <Label title={`WL_book_${typename}_${field}`}/>
            <TextInput value={value} setValue={setValue} placeholder={`WL_book_${typename}_${field}Placeholder`}/> 
        </Field>
    );
}; 
export default TextField;