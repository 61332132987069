import Constants from 'expo-constants';
import { v4 as getUUID } from 'uuid';
import { View } from 'react-native';
import { env, abs, api, use } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { PictureItem } from '../../../../app/picture/suits.js';

const Picture = ({ svg, typename, params, picture={} }) => {

    const t = use.useText();

    const { MEDIA_API_BASE_URL } = Constants.expoConfig.extra

    const fetch = api.picture.useFetch(picture.item);
    const item = fetch();

    // if (abs.isError(item)) return <ThrowErrorMain name='bookPage' data={book.item}/>

    const { setId, itemId } = params;

    const linkTo = `/set/${setId}/picture/${itemId}`;
    const id = getUUID();
    const popover = abs.book.popover({
        header: {svg, title:t('WL_picture_Picture')},
        popover: {id, typename, picture, ...params, item, linkTo},
        options: {name: 'optionsPictureItem'},
    });
    

    const path = item.path.split('TS').join('TL')
    const src = MEDIA_API_BASE_URL+'/'+path

    console.log(
        'picture ', picture,
        'src ', src,
    )

    return <PictureItem src={src} placeholder={item.blurhash} options={popover.options}/>
    
};
export default Picture;