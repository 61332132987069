import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as getUUID } from 'uuid';
import { View } from 'react-native';
import * as localStorage from '../../../platform/localStorage';
import { s, use, store } from '../../../root';
import { Modal } from '../../../modules/modal/components';
// import { Text, Slider, Icon, Button } from '../../../modules/basics/components';
import { Welcome as WelcomeContent } from '../components';
import Actions from './Actions';

const Welcome = ({id}) => {

    const c = use.useColor();

    const [modals, setModals] = useRecoilState(store.modal.modals);
    const closeModal = use.modal.useCloseModal();

    const language = useRecoilValue(store.layout.language);
    
    const [analytics, setAnalytics] = useRecoilState(store.cookies.analytics);
    const [marketing, setMarketing] = useRecoilState(store.cookies.marketing);

    const setConsent = useSetRecoilState(store.cookies.consent);
    const necessary = useRecoilValue(store.cookies.necessary);
    const tracking = useRecoilValue(store.cookies.tracking);

    const onSetLanguage = () => {
        const id = getUUID();
        setModals([...modals, {id, name:'language'}]);
    };

    const toggleCookiesNecessary = () => {};

    const toggleCookiesAnalytics = () => {
        setAnalytics(!analytics)
    };
    const toggleCookiesMarketing = () => {
        setMarketing(!marketing)
    };
    const toggleCookiesTracking = () => {};

    const selectAll = () => {
        setAnalytics(true)
        setMarketing(true)
    };

    const deselectAll = () => {
        setAnalytics(false)
        setMarketing(false)
    };

    const save = async () => {
        setConsent(true)
        localStorage.set('consent', 'true');
        localStorage.set('necessary', necessary);
        localStorage.set('analytics', analytics);
        localStorage.set('marketing', marketing);
        localStorage.set('tracking', tracking);
        closeModal(id);
    };

    const title = 'WL_cookies_welcome'
    const svg = 'placeholder_16'
    const actions = <Actions save={save}/>

    return (
        <Modal id={id} title={title} svg={svg} actions={actions} isCloseDisabled>
            <WelcomeContent 
                toggleCookiesNecessary={toggleCookiesNecessary}
                toggleCookiesAnalytics={toggleCookiesAnalytics}
                toggleCookiesMarketing={toggleCookiesMarketing}
                toggleCookiesTracking={toggleCookiesTracking}
                onSetLanguage={onSetLanguage}
                selectAll={selectAll}
                deselectAll={deselectAll}
                language={language}
                necessary={necessary}
                analytics={analytics}
                marketing={marketing}
                tracking={tracking}
                c={c}
            />
        </Modal>    
    );
}; 
export default Welcome;