import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const copySection = gql`

    ${fragment.section.hook}
    ${fragment.section.item}
    ${fragment.page.hook}
    ${fragment.page.item}

    mutation copySection ($input: arrange!) {
        copySection(input: $input) {
            ...sectionHookFields
            item {
                ...sectionItemFields
                childHooks {
                    ...pageHookFields 
                    item {
                        ...pageItemFields
                    }
                }
            }
        }
    }
`;
export default copySection;