import { useRecoilState } from 'recoil';
import { store } from '../../../../root';
import { PopoverItem } from '../../../../app/book/suits.js';

const Expand = ({ isLine }) => {

    const [popover, setPopover] = useRecoilState(store.popover.popover)
    const { isOn, setIsOn } = popover;

    const expand = () => {
        setIsOn(!isOn);
        setPopover({});
    };

    const main = isOn ? 'WL_book_collapse' : 'WL_book_expand'; 
    const svg = isOn ? 'expandedTrue_16' : 'expandedFalse_16';

    return <PopoverItem onPress={expand} svg={svg} main={main} second={'WL_book_the_BlockList'} isLine={isLine}/>

};
export default Expand;