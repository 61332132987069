import { View } from 'react-native';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from '../../../../platform/router';
import { s, use, store } from '../../../../root';
import { LoaderPage } from '../../../../modules/loader/pages';

const Loader = ({ limit, setIsLoaded }) => { 

    const c = s.colors

    // welcome (cookies)
    const welcome = use.cookies.useWelcome();
    welcome

    // load layout
    const language = useRecoilValue(store.layout.language);
    const isRTL = useRecoilValue(store.layout.isRTL);
    const isDark = useRecoilValue(store.layout.isDark);
    const isFixed = useRecoilValue(store.layout.isFixed);

    // load cookies
    const consent = useRecoilValue(store.cookies.consent);
    const necessary = useRecoilValue(store.cookies.necessary);
    const analytics = useRecoilValue(store.cookies.analytics);
    const marketing = useRecoilValue(store.cookies.marketing);
    const tracking = useRecoilValue(store.cookies.tracking);

    // load router
    const setUrl = useSetRecoilState(store.router.url)
    const url = useLocation().pathname
    store.router.path = url;
    useEffect(() => setUrl(url), [url]);

    console.log (
        'xxx',
        '\n language > ', language, 
        '\n isRTL > ', isRTL, 
        '\n isDark > ', isDark, 
        '\n isFixed > ', isFixed, 
        '\n consent > ', consent, 
        '\n necessary > ', necessary, 
        '\n analytics > ', analytics, 
        '\n marketing > ', marketing, 
        '\n tracking > ', tracking, 
        '\n url > ', url, 
    );

    useEffect(()=>{
        const delay = async () => {
            const delayer = async () => { 
                while (Date.now() < limit) {}; 
                return 
            }; 
            await delayer();
        };
        delay();
    });
        
    const style = [s.row_cXcY, s.XYr, s.radius.r16, c.mainN1];
    return( 
        <View style={style}>
            <LoaderPage setIsLoaded={setIsLoaded}/>
        </View>
    );
};
export default Loader;
    



// import {useRef, useEffect} from 'react';
// import {Animated, Text, View} from 'react-native';
  
// const AnimatedOp = useRef(new Animated.Value(0)).current;
       // Initial value for opacity: 0
    
    //   useEffect(() => {
    //     Animated.timing(AnimatedOp, {
    //       toValue: 1,
    //       duration: 3000,
    //       useNativeDriver: false,
    //     }).limit();
    //   }, [AnimatedOp]);
    


    // // const style = { display:'flex', flexDirection:'row', flex:1, alignSelf:'stretch', opacity:AnimatedOp }
    // return (
    //     <Animated.View style={{ width:'100%', height:'100%', opacity:AnimatedOp }}>
    //         <View style={style}>
    //             <Text>Ciao CIao</Text>
    //         </View>
    //     </Animated.View> 
    // ) 
