import { useRecoilValue } from 'recoil';
import { View } from 'react-native';
import { s, use, store } from '../../../root';

const Element = ({ id, logo, children, options, adder}) => {

    const c = use.useColor();

    const popover = useRecoilValue(store.popover.popover);
    const isPopover = popover.id && id === popover.id;

    const style = {
        element:    [s.col, {minHeight:42}, isPopover ? c.popoverC1 : c.popoverN1],
        body:       [s.row_sXsY],
        line:       [s.Y2, s.Xc, isPopover? c.selectorC1 : c.popoverN1],
        one:        [s.row],
        middle:     [s.row_sXcY, s.Xr, {width:0}],
        two:        [s.row, s.space.mE8],
    };

    return (
        <View style={style.element}>
            <View style={style.body}>
                <View style={style.one}>{logo}</View>
                <View style={style.middle}>{children}</View>
                <View style={style.two}>{options}{adder}</View>
            </View>
            <View style={style.line}></View>
        </View>
    );     
}; 
export default Element;