import { Modal } from '../../../modules/modal/components';
import { PreferencesPage } from '../../../modules/layout/pages';

const Preferences = ({ id }) => {

    const title = 'WL_layout_preferences';
    const svg = 'settings_16';

    return (
        <Modal id={id} title={title} svg={svg}>
            <PreferencesPage/>
        </Modal>
    );
}; 
export default Preferences;