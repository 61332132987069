import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { store, abs } from '../../../../root';
import { Modal } from '../../../../modules/modal/components';
import { AgentWrite } from '../../../../app/book/agents.js';
import { ModalTextField } from '../../../../app/book/suits.js';

const NewData = ({ id }) => {

    const typename = 'Data';

    const popover = useRecoilValue(store.popover.popover)
    const { blockHookId, blockItemId, dataPosition } = popover;

    const [operationId, setOperationId] = useState('');

    const [key, setKey] = useState('');
    const [value, setValue] = useState('');
    const [type, setType] = useState('');
    const [metadata, setMetadata] = useState('');

    const save = () => {
        const input = { type, key, value, metadata, start:dataPosition+1, parent_id:blockItemId };
        const operation = 'create';
        const list = {typename, type:'list', id:blockItemId, fetchId:blockHookId};
        const generatedId = abs.book.operation.add({typename, operation, input, list});
        setOperationId(generatedId);
    };

    const title = 'WL_book_DataCreate';
    const svg = 'placeholder_16';
    
    const refetch = {refetch:'reorder', operationId, modalId:id};

    const actions = <AgentWrite operationId={operationId} action={save} refetch={refetch} isDisabled={!value && !type}/>

    return (
        <Modal id={id} title={title} svg={svg} actions={actions}>
            <ModalTextField typename={typename} field={'type'} value={type} setValue={setType}/>
            <ModalTextField typename={typename} field={'key'} value={key} setValue={setKey}/>
            <ModalTextField typename={typename} field={'value'} value={value} setValue={setValue}/>
            <ModalTextField typename={typename} field={'metadata'} value={metadata} setValue={setMetadata}/>
        </Modal>
    );
}; 
export default NewData;