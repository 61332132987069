import { abs } from '../../../../root';
import Placeholder from './Placeholder';

const Empty = ({ typename, toolbar, first=null }) => {

    const svg = abs.book.svg({typename});
    const text = `WL_book_${typename}ListEmpty`;

    return (
        <>
            {toolbar}
            {first}
            <Placeholder svg={svg} text={text}/>
        </>
    );
}; 
export default Empty; 