import { Suspense } from 'react'; 
import { useRecoilState, useRecoilValue } from 'recoil';
import { View } from 'react-native';
import { s, use, store } from '../../../../root';
import { Icon } from '../../../../modules/basics/components';
import { IconLink } from '../../../../modules/basics/menus';
import Finder from '../Finder/index.js';

const Header = ({ auth, menuOne, menuTwo }) => { 

    const c = use.useColor();

    const [isSideOneOn, setIsSideOneOn] = useRecoilState(store.layout.isSideOneOn);
    const [isSideTwoOn, setIsSideTwoOn] = useRecoilState(store.layout.isSideTwoOn);

    const size = useRecoilValue(store.layout.size);
    const isFixed = useRecoilValue(store.layout.isFixed);
    
    const isOneColumn = size==='1';
    const isThreeColumn = size==='3';
    const isSideOneDisabled = (!isOneColumn && isFixed) || !menuOne;
    const isSideTwoDisabled = (isThreeColumn && isFixed) || !menuTwo;

    const onClickSideOne = () => isSideOneDisabled ? ()=>{} : setIsSideOneOn(!isSideOneOn);
    const onClickSideTwo = () => isSideTwoDisabled ? ()=>{} : setIsSideTwoOn(!isSideTwoOn);

    const colorSideOne = isSideOneDisabled ? c.onBandN1_d : c.onBandN1;
    const colorSideTwo = isSideTwoDisabled ? c.onBandN1_d : c.onBandN1;

    const style = {
        header: [s.row, s.X, s.sizeY48, c.bandN1],
        one:    [s.row_sX, s.space.pE8, !isOneColumn && isSideOneOn && s.X320],
        two:    [s.row_eX, s.space.pS8, isThreeColumn && isSideTwoOn && s.X320],
        icon:   [s.XY48],
    };

    return (
        <View style={style.header}>
            <View style={style.one}>
                <Icon style={style.icon} color={colorSideOne} svg='menu_16' onPress={onClickSideOne}/>
                <IconLink style={style.icon} color={c.onBandN1} svg='home_16' linkTo='/'/>
            </View>
            <Finder/>
            <View style={style.two}>
                <Suspense fallback={<></>}>{auth}</Suspense>
                <Icon style={style.icon} color={colorSideTwo} svg='menu_16' onPress={onClickSideTwo}/>
            </View>
        </View>
    );
};
export default Header;