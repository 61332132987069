import { Text } from 'react-native';
import { s, use } from '../../../root';

const TextTwin = ({ text={}, twin={} }) => {

    const t = use.useText();
    return (
        <>
            <Text style={[...text.style, text.color]} numberOfLines={1} selectable={false}>{t(text.text, !text.WL)}</Text>
            <Text> </Text>
            <Text style={[...twin.style, twin.color]} numberOfLines={1} selectable={false}>{t(twin.text, !twin.WL)}</Text>
        </>
    );
};
export default TextTwin;