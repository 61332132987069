import { s, use } from '../../../../root';
import { Textline, Button } from '../../../../modules/basics/components';

const Actions = ({ save }) => {

    const c = use.useColor();

    const style = {
        button: [s.button, s.Y32],
        text: [c.onBandN1, { fontWeight:'bold' }],
    };

    return (
        <Button style={style.button} color={c.buttonC1} onPress={save}>
            <Textline style={style.text} color={c.onButtonN1} WL text='WL_cookies_save_and_close'/>
        </Button>
    );
}; 
export default Actions;