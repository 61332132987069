import { abs, api } from '../../../../root';
import { SelectorItem } from '../../../../app/book/suits.js';
import { ThrowErrorModal } from '../../../../modules/error/throw';

const Page = ({ modalId, selection, params, navigate, page, position }) => {

    const { typename, sectionHookId, sectionItemId, pageHookId } = params;
    const { initial, selectable, select, deselect, setSelected, selected } = selection;

    const svg = abs.book.svg({typename});

    const id = pageHookId;
    const invalidId = initial.isMove && initial.hookId === id;
    const isSelectable = selectable === 'Page' && !invalidId;
    const isSelected = selected === id;

    const onSelect = () => { 
        if (isSelectable) {
            if (isSelected) deselect()
            else {
                select({ ids:[pageHookId], origin_id:sectionItemId, originHookId:sectionHookId });
                setSelected(id)
            }
        };
    };
    
    const onOpen = () => {
        if (['Block', 'Data'].includes(selectable)) navigate({...params, typename:'Block'})
    };

    const fetchItem = api.book.useFetch(page.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorModal name='bookSelector' data={{data:page.item, params, modalId}}/>

    return (
        <SelectorItem 
            svg={svg} text={item.value} 
            onSelect={onSelect} onOpen={onOpen}
            isSelectable={isSelectable} isSelected={isSelected}
        />
    );
}; 
export default Page;