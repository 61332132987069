import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { abs, api } from '../../../root';

const useLoader = (setIsNetworkOk, setIsAuthOk, setIsInitialized, setIsLoaded, url) => {

    const user = useRecoilValue(api.user.user) || {};

    const loader = useEffect(() => {

        const check = async () => {

            console.log(' XXX inside useLoader check', user)

            if (await abs.isNetwork()) {
                if (abs.isPublic(url)) setIsLoaded(true)
                else if (user.id) setIsLoaded(true)
                else {
                    setIsNetworkOk(true)
                    setIsAuthOk(false)
                    setIsInitialized(true)
                };
            } else {
                setIsNetworkOk(false)
                setIsAuthOk(false)
                setIsInitialized(true)
            }
        };
        check();

    }, [user]);

    return loader;
};
export default useLoader;