import { useState } from 'react';
import { use } from '../../../root';
import { Network, Auth } from '../../../modules/error/triggers.js';
import { ErrorPageMissing } from '../../../modules/error/pages'

const MissingPage = () => {

    const [isNetworkOk, setIsNetworkOk] = useState(false);
    const [isAuthOk, setIsAuthOk] = useState(false);

    const initialize = use.error.useInitialize(setIsNetworkOk, setIsAuthOk);
    initialize

    const [toggler, setToggler] = useState(false);
    const rerender = () => setToggler(!toggler)

    return (
        <ErrorPageMissing
            isNetworkOk={isNetworkOk}
            isAuthOk={isAuthOk}

            network = {<Network rerender={rerender} setIsNetworkOk={setIsNetworkOk}/>}
            auth = {<Auth setIsAuthOk={setIsAuthOk} setIsNetworkOk={setIsNetworkOk}/>}
        />
    );
};
export default MissingPage;