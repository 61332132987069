import * as user from '../modules/user/api.js';

import * as book from '../app/book/api.js';
import * as picture from '../app/picture/api.js';

const api = {

    user,

    book,
    picture,

};
export default api;