import { View } from 'react-native';
import { s, use } from '../../../root';
import { Textline, Icon } from '../../../modules/basics/components';

const Header = ({ svg, title, close }) => {

    const c = use.useColor();

    const style = {
        header: [s.col],
        body:   [s.row_cXcY, s.Y40, s.radius.t8, c.popoverN0],
        icon:   [s.X48Y40],
        title:  [s.row_sXcY, s.Xr, s.Y40, {fontWeight:'bold'}],
        line:   [s.Y2, s.Xc, c.dividerN2],
    };

    return (
        <View style={style.header}>
            <View style={style.body}>
                <Icon style={style.icon} color={c.onMainN1} svg={svg}/>
                <Textline style={style.title} color={c.onMainN1} text={title}/>
                <Icon style={style.icon} color={c.onMainN1} svg='confirmFalse_16' onPress={close}/>
            </View>
            <View style={style.line}></View>
        </View>
    );
}; 
export default Header;