import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useLocation } from '../../../../platform/router';
import { api, abs } from '../../../../root';

const InitializeUser = ({ setIsLoaded, setIsNetworkOk, setIsAuthOk, setIsError }) => {
    const user = useRecoilValue(api.user.user) || {};
    const url = useLocation().pathname
    useEffect(() => {
        const check = async () => {
            if (await abs.isNetwork()) {
                if (user.id) setIsLoaded(true)
                if (abs.isPublic(url)) setIsLoaded(true)
                else setIsNetworkOk(true); setIsAuthOk(false); setIsError(true)
            } else setIsNetworkOk(false); setIsAuthOk(false); setIsError(true)
        };
        check();
    }, [user]);
    return null
};
export default InitializeUser;