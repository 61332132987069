import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { View } from 'react-native';
import { s, store, abs } from '../../../../root';
import { LoadingMenu } from '../../../../app/book/suits.js';
import Book from './Book.js';

const Menu = ({ url }) => {

    const typename = 'Book';
    
    const error = useRecoilValue(store.error.main)
    const isError = error?.name

    const { setId, bookItemId } = abs.book.params(url);
    const params = {setId, bookItemId};
    const item = {typename, type:'item', id:bookItemId, fetchId:bookItemId, isBook:true};

    if (isError) return <LoadingMenu url={url} isError/>
    return (
        <View style={s.scrollView}>
            <Suspense fallback={<LoadingMenu url={url} setId={setId} bookItemId={bookItemId}/>}>
                <Book 
                    url={url}
                    typename={typename}
                    params={params}
                    book={{item}}
                />
            </Suspense>
        </View>
    );
}; 
export default Menu;