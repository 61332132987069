import { abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { PageEmpty } from '../../../../app/book/suits';
import Book from './Book.js';

const Books = ({ svg, typename, params={}, book={} }) => {

    const fetch = api.book.useFetch(book.list);
    const list = fetch();

    if (abs.isError(list)) return <ThrowErrorMain name='bookPage' data={book.list}/>
    if (abs.isEmpty(list)) return <PageEmpty typename={typename} svg={svg}/>
    if (!abs.isArray(list)) return null;

    return (<>
        {list.map (current => {
            const { setId } = params;
            const bookItemId = current.itemId;
            const item = {typename, type:'item', id:bookItemId, fetchId:setId};
            return ( 
                <Book 
                    svg={svg}
                    typename={typename}
                    params={{ ...params, bookItemId }}
                    book={{list:book.list, item}}
                    key={current.itemId}
                />
        )})}
    </>);       
};
export default Books;