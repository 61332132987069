import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { store, abs } from '../../../../root';
import { Modal } from '../../../../modules/modal/components';
import { AgentWrite } from '../../../../app/book/agents.js';
import { ModalTextField } from '../../../../app/book/suits.js';

const EditBook = ({id}) => {

    const typename = 'Book';

    const popover = useRecoilValue(store.popover.popover)
    const { bookItemId, item } = popover;

    const [operationId, setOperationId] = useState('');

    const [title, setTitle] = useState(item.title);
    const [description, setDescription] = useState(item.description);
    const [metadata, setMetadata] = useState(item.metadata);
    const [cover, setCover] = useState(item.cover);
    const [logo, setLogo] = useState(item.logo);

    const save = () => {
        const input = { cover, description, logo, metadata, title, id:bookItemId };
        const operation = 'update';
        const itemId = bookItemId;
        const generatedId = abs.book.operation.add({typename, operation, input, itemId});
        setOperationId(generatedId);
    };

    
    const modalTitle = 'WL_book_BookUpdate';
    const modalSvg = 'book_16_fill_24';

    const refetch = {refetch:'update', operationId, modalId:id};

    const actions = <AgentWrite operationId={operationId} action={save} refetch={refetch} isDisabled={!title}/>

    return (
        <Modal id={id} title={modalTitle} svg={modalSvg} actions={actions}>
            <ModalTextField typename={typename} field={'title'} value={title} setValue={setTitle}/>
            <ModalTextField typename={typename} field={'description'} value={description} setValue={setDescription}/>
            <ModalTextField typename={typename} field={'metadata'} value={metadata} setValue={setMetadata}/>
            <ModalTextField typename={typename} field={'cover'} value={cover} setValue={setCover}/>
            <ModalTextField typename={typename} field={'logo'} value={logo} setValue={setLogo}/>
        </Modal>
    );
}; 
export default EditBook;