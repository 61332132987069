import { useRecoilValue } from 'recoil';
import { View } from 'react-native';
import { s, store } from '../../../../root';
import { Icon } from '../pods';

const Header = () => { 

    const c = s.colors;

    const isSideOneOn = useRecoilValue(store.layout.isSideOneOn);
    const isSideTwoOn = useRecoilValue(store.layout.isSideTwoOn);

    const size = useRecoilValue(store.layout.size);
    
    const isOneColumn = size==='1';
    const isThreeColumn = size==='3';

    const style = {
        header: [s.row, s.X, s.sizeY48, c.onBandN1_d],
        finder: [s.row_cXcY, s.Xr],
        bar:    [s.row, s.Xr, s.Y32, s.radius.r16, c.mainN1],
        one:    [s.row_sX, s.space.pE8, !isOneColumn && isSideOneOn && s.X320],
        two:    [s.row_eX, s.space.pS8, isThreeColumn && isSideTwoOn && s.X320],
        icon:   [s.XY48, s.row_cXcY],
        box:    [s.row_cXcY, s.XY48],
        logo:   [s.XY32],
        circle: [s.XY32, s.radius.r16, c.mainN1]
    };

    return (
        <View style={style.header}>
            <View style={style.one}>
                <Icon style={style.icon} color={c.onBandN1_d} svg='menu_16'/>
                <Icon style={style.icon} color={c.onBandN1_d} svg='home_16'/>
            </View>
            <View style={style.finder}>
                <View style={style.bar}>
                </View>
            </View>
            <View style={style.two}>
                <View style={style.icon}>
                    <View style={style.circle}></View>
                </View>
                <Icon style={style.icon} color={c.onBandN1_d} svg='menu_16'/>
            </View>
        </View>
    );
};
export default Header;
