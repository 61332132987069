import { View, Pressable } from 'react-native';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from '../../../../platform/router';
import { TextTwin, Icon } from '../../../../modules/basics/components';
import { s, use, store } from '../../../../root';

const Link = ({ linkTo, main, second, isLine }) => {

    const navigate = useNavigate();

    // onPress close popover
    const setPopover = useSetRecoilState(store.popover.popover);
    const onPress = () => {
        navigate(linkTo)
        setPopover({});
    }

    const c = use.useColor();

    const style = {
        open: [s.col],
        body: [s.row_sXcY, s.Y40],
        line: [s.Y2, s.Xc, isLine ? c.dividerN1 : null],
        icon: [s.X48Y40],
        text: [s.row_sXcY, {fontWeight:'bold'}],
        twin: [s.row_sXcY, s.Xr],
    };

    const text = {style:style.text, color:c.onMainN1, WL:true, text:main};
    const twin = {style:style.twin, color:c.onMainN1_1, WL:true, text:second};

    return ( 
        <Pressable style={style.open} onPress={onPress}>
            <View style={style.body}>
                <Icon style={style.icon} color={c.onMainN1} svg='open_16'/>
                <TextTwin text={text} twin={twin}/>
            </View>
            <View style={style.line}></View>
        </Pressable>
    );
};
export default Link;