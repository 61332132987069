import { Pressable } from 'react-native';
import { useRecoilValue } from 'recoil';
import { useNavigate } from '../../../platform/router';
import { Icon } from '../../../modules/basics/components';
import { store } from '../../../root';

const IconLink = ({ style, color, linkTo, svg, isDisabled }) => {

    const error = useRecoilValue(store.error.main);
    const isError = error?.name;

    const navigate = useNavigate();

    const isInactive = isError || isDisabled;

    const onPress = () => {if(!isInactive) navigate(linkTo)};
    
    return ( 
        <Pressable onPress={onPress} style={isInactive ? {opacity:0.3} : null}>  
            <Icon style={style} color={color} svg={svg}/> 
        </Pressable>
    );
};
export default IconLink;