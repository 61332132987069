import { View } from 'react-native';
import { Suspense } from 'react';
import { s, use } from '../../../../root';

import { Modals } from '../../../../modules/modal/components';

import Header from './Header.js';
import Sidebar from './Sidebar.js';

import Loader from './Loader';

const Layout = ({ limit, setIsLoaded }) => {  

    const c = s.colors

    const setResize = use.layout.useSetResize();
    setResize

    const style = {
        root:   [s.fixed, s.col, s.XY, c.barN1],
        layout: [s.fixed, s.col, s.XY, s.space.pX8, c.barN1],
        body:   [s.row, s.X, s.Yc, s.space.between],
        main:   [s.col, s.XYr, s.radius.r16, c.mainN1],
        footer: [s.X, s.Y8, c.bandN1],
    };

    return (
        <View style={style.root}>  
            <Suspense fallback={<></>}>
                <Modals/>
            </Suspense>
            <View style={style.layout}>
                <Header/>
                <View style={style.body}>
                    <Sidebar isInternal isSidebarOne/> 
                    <View style={style.main}>
                        <Suspense fallback={<></>}>
                            <Loader limit={limit} setIsLoaded={setIsLoaded}/>
                        </Suspense>
                    </View>
                    <Sidebar isInternal/>
                </View>
                <View style={style.footer}></View>
            </View>
        </View>
    );
};
export default Layout; 

