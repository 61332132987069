import axios from 'axios';
import { abs, store } from '../../../../root';
 
const writeOperation = async (operationId) => {

    console.log('inside writeOperation');
   
    try {

        // if (!abs.isArray(store.picture.cache[type][id])) {
        
            // check the connection, the token and if the id is present
            if (!(await abs.isNetwork())) abs.errorThrow('if (!(await abs.isNetwork()))');
            const accessToken = await abs.isAuth();
            if (!accessToken) abs.errorThrow('if (!(await abs.isAuth()))');
            if (!operationId) abs.errorThrow('if (!operationId)');

            const { input={}, headers={} } = store.picture.operation[operationId]

            
            input.headers = { ...headers, 'Authorization': `Bearer ${accessToken}`}
            // 'content-type': 'multipart/form-data',
            
            console.log('inside writeOperation > input : \n', input, headers);

            const response = await axios(input);

            console.log('inside writeOperation > response : \n', response);

            // if (abs.isArray(response?.data?.data)) {
            //     store.picture.cache[type][id] = response.data.data;
            // };
        // };

       // console.log('inside writeOperation > api.media.cache[mediaType] : \n', store.picture.cache[type][id])

        return 'success'//store.picture.cache[type][id];

    } catch (error) {return abs.errorCatch('App/app/picture/api/write', error)}
};
export default writeOperation;
