import { Suspense, useState } from 'react';
import { v4 as getUUID } from 'uuid';
import { abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { PageElement, LoadingList } from '../../../../app/book/suits';
import Blocks from './Blocks.js';

const Page = ({ svg, typename, params={}, page={}, pagePosition }) => {

    const [isOn, setIsOn] = useState(false);

    const fetchItem = api.book.useFetch(page.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorMain name='bookPage' data={page.item}/>

    const { pageHookId, pageItemId } = params;

    const childTypename = abs.book.childOf(typename);
    const childSvg = abs.book.svg({typename, type:'block', isChild:true});
    const childList = {typename:childTypename, type:'list', id:pageItemId, fetchId:pageHookId};

    const id = getUUID();
    const popover = abs.book.popover({
        header: {svg, title:item.value},
        popover: {id, typename, page, pagePosition, blockPosition:0, ...params, item, isOn, setIsOn, isExpand:true},
        options: {name:'optionsPage', linkTo:abs.book.link('page', params)},
        adder: {name:'adderPageBlock'},
    });

    return (
        <>
            <PageElement 
                id={id} 
                svg={svg} 
                text={item.value} 
                options={popover.options} 
                adder={popover.adder}
            />
            {!isOn ? null : 
            <Suspense fallback={<LoadingList svg={childSvg} typename={childTypename} isOptions isAdder/>}>              
                <Blocks
                    svg={childSvg}
                    typename={childTypename}
                    params={params}
                    block={{list:childList}}
                />
            </Suspense>
            }
        </>
    );
}; 
export default Page;