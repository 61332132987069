import { View, Pressable } from 'react-native';
import { Icon, Textline } from '../../../../modules/basics/components';
import { s, use } from '../../../../root';

const Element = ({ svg, text, onOpen=()=>{}, onSelect=()=>{}, isSelectable, isSelected }) => {

    const c = use.useColor();

    const iconColor = isSelected ? c.onMainC1 : isSelectable ? c.onMainC1_d : c.onMainN1_d;
    const lineColor = isSelected ? c.selectorC1 : isSelectable ? c.selectorC1_d : c.selectorN1_d;

    const style = {
        element:    [s.row, { minHeight:40, position:'relative'  }],
        item:       [s.col, s.Xr, { minHeight:40, position:'relative' }],
        box:        [s.row_sXcY, { minHeight:40 }],
        data:       [s.Xr, { width:0 }],
        line:       [s.Xr, s.Y2, s.space.mX48, {zIndex:1}, lineColor],
        icon:       [s.X48Y40],
        text:       [s.textPod],
        selector:   [s.X48Y40, { position:'absolute', top:21 }],
        top:        [{position:'absolute', width:'100%', top:-19, height:40}],
    };

    return (
        <>
            <View style={style.element}>
                <Pressable style={style.item} onPress={onOpen}>
                    <View style={style.box}>
                        <Icon style={style.icon} color={c.onMainN1} svg={svg}/>
                        <View style={style.data}>
                            <Textline style={style.text} color={c.onMainN1} text={text}/>
                        </View>
                    </View>
                </Pressable>
                <View style={style.icon}>
                    <Icon style={style.selector} color={iconColor} svg='arrowChevronLeft_16' onPress={onSelect}/>
                </View>
            </View>
            <View style={style.line}>
                {isSelectable ? <Pressable style={style.top} onPress={onSelect}/> : null}
            </View>
        </>
    );
}; 
export default Element;