import { useState } from 'react';
import { TextInput as TextInputNative } from 'react-native';
import { use } from '../../../root';

const TextInput = ({value, setValue, placeholder}) => {

    const c = use.useColor();
    const t = use.useText();

    const [height, setHeight] = useState(24);
    const [border, setBorder] = useState(c.line.inputN1);

    const handleContentSize = event => {
        let newHeight = event.nativeEvent.contentSize.height;
        if (event.nativeEvent.contentSize.height < height) newHeight = height - 24;
        setHeight(newHeight);
    };

    const handleChange = event => setValue(event.target.value);
    const handleFocus = () => setBorder(c.line.inputC1);
    const handleBlur = () => setBorder(c.line.inputN1);

    const style = {
        lineHeight:24,
        outlineStyle:'none',
        paddingTop:4,
        paddingBottom:6,
        borderBottomWidth:2,
    };

    return (
        <TextInputNative 
            multiline
            value={value}
            style={[style, c.onMainN1, border, {height}]} 
            onContentSizeChange={handleContentSize}
            placeholder={t(placeholder)}
            placeholderTextColor={'rgba(128,128,128,0.75)'}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
        />
    );
}; 
export default TextInput;