import { useRecoilState, useSetRecoilState } from 'recoil';
import { store, use, abs } from '../../../root';
import { Button } from './pods';

const Close = ({ errorType, action=()=>{} }) => {

    const [error={}, setError] = useRecoilState(store.error[errorType]);
    const setPopover = useSetRecoilState(store.popover.popover);
    const closeModal = use.modal.useCloseModal();

    const { operationId, refetch={}} = error;

    const onClick = () => {
        
        if (errorType==='main'){
            setError({});
        };

        if (errorType==='modal'){
            closeModal(refetch.modalId);
        };

        action()
        setPopover({});
        abs.book.operation.remove(operationId);
    };

    return <Button onPress={onClick} text='WL_error_close'/>

};
export default Close;