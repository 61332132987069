import { v4 as getUUID } from 'uuid';
import { store } from '../../../root';

export const add = (operation) => {

    const operationId = getUUID();
    store.picture.operation[operationId] = { operationId, ...operation }
    return operationId;

};

export const remove = (operationId) => delete store.picture.operation[operationId];