import { Pressable, Image } from 'react-native';
import { s } from '../../../root';

const Logo = ({ style=[], color, src, onPress }) => {
    
    return (
        <Pressable onPress={onPress}>
            <Image style={[...style, color, s.logo]} source={src}/>  
        </Pressable>
    );
};
export default Logo;