import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { store, abs } from '../../../../root';
import { Modal } from '../../../../modules/modal/components';
import { AgentWrite } from '../../../../app/book/agents.js';
import { ModalTextField } from '../../../../app/book/suits.js';

const NewPage = ({ id, isNavigate }) => {

    const typename = 'Page';

    const popover = useRecoilValue(store.popover.popover)
    const { sectionHookId, sectionItemId, pagePosition, navigateTo } = popover;

    const path = isNavigate ? navigateTo : null;

    const [operationId, setOperationId] = useState('');

    const [value, setValue] = useState('');
    const [metadata, setMetadata] = useState('');

    const save = () => {
        const input = { value, metadata, start:pagePosition+1, parent_id:sectionItemId };
        const operation = 'create';
        const list = {typename, type:'list', id:sectionItemId, fetchId:sectionHookId};
        const generatedId = abs.book.operation.add({typename, operation, input, list});
        setOperationId(generatedId);
    };
    
    const title = 'WL_book_PageCreate';
    const svg = 'P_16_fill_24';
    
    const refetch = {refetch:'reorder', operationId, modalId:id, navigateTo:path};

    const actions = <AgentWrite operationId={operationId} action={save} refetch={refetch} isDisabled={!value}/>

    return (
        <Modal id={id} title={title} svg={svg} actions={actions}>
            <ModalTextField typename={typename} field={'value'} value={value} setValue={setValue}/>
            <ModalTextField typename={typename} field={'metadata'} value={metadata} setValue={setMetadata}/>
        </Modal>
    );
}; 
export default NewPage;