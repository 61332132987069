import { Suspense } from 'react';
import { Page, Button } from './pods';

const ErrorPage = ({ auth, network, close, action, cleanup, isNetworkOk, isAuthOk=null, isIssueOk, hasHome, source }) => {

    console.log(
        'ErrorPage source', source,
        'isNetworkOk ', isNetworkOk, 
        'isAuthOk ', isAuthOk, 
        'isIssueOk ', isIssueOk
    )
        
    const mainButton = !isNetworkOk ? network : !isAuthOk ? auth : !isIssueOk ? action : cleanup;
    
    const networkMessage = !isNetworkOk || isAuthOk==null ? 'WL_error_network_no' : 'WL_error_network_ok'
    const authMessage = !isNetworkOk || isAuthOk===null ? 'WL_error_auth_pending' : !isAuthOk ? 'WL_error_auth_no' : 'WL_error_auth_ok'
    const message = !isNetworkOk || isAuthOk===null  ? 'WL_error_network_message' : !isAuthOk ? 'WL_error_auth_message' : !isIssueOk ? 'WL_error_action' : 'WL_error_cleanup';

    return (
        <Page
            hasHome={hasHome}
            networkMessage={networkMessage}
            authMessage={authMessage}
            message={message}
        >
            {close}
            <Suspense fallback={<Button text='WL_error_loading' isMain/>}>
                {mainButton}
            </Suspense>
        </Page>
    );
};
export default ErrorPage;