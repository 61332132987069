import { useRecoilValue, useRecoilState } from 'recoil';
import { View, Pressable } from 'react-native';
import { s, api, use, store } from '../../../root';
import Header from './Header.js';
import Menu from './Menu.js';

const Userbar = () => {

    const c = use.useColor(); 

    const [userbar, setUserbar] = useRecoilState(store.user.userbar);
    const user = useRecoilValue(api.user.user);
    const url = useRecoilValue(store.router.url);

    const close = () => setUserbar({});
    const isGuest = !user?.id
    const username = isGuest ? 'GUEST' : user?.email;

    const style = {
        overlay:    [s.overlay, {zIndex:1}, s.rowR_sXcY, c.overlayN1],
        userbar:    [s.col, s.X320max, {flexGrow:10}, s.Y, c.barN1],
        space:      [s.row, s.X104min, s.Xr, s.Y],
        body:       [s.col, s.X, s.Yc, c.mainN1],
    };

    if (!userbar?.isOn) return null;
    return (
        <View style={style.overlay}>
            <View style={style.userbar}>
                <Header isGuest={isGuest} username={username} action={userbar.action} close={close}/>
                <View style={style.body}>
                    <Menu isGuest={isGuest} url={url}/>
                </View>
            </View>
            <Pressable style={style.space} onPress={close}></Pressable>
        </View>
    );
};
export default Userbar;