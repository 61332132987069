import { abs } from '../root';
import { Home } from '../app/home/menus.js';
import { Book, Books } from '../app/book/menus.js';

const menus = {

    one: (url, userId) => {

        if (userId && abs.book.isUrlBooks(url)) return <Books url={url}/> 
        if (userId && abs.book.isUrlBook(url)) return <Book url={url}/>
        return <Home url={url}/>

    },

    two: (url, user) => {

        return null

    },
};
export default menus;