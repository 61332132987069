import { Pressable } from 'react-native';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useNavigate } from '../../../platform/router';
import { Textline } from '../../../modules/basics/components';
import { s, use, store } from '../../../root';

const UserLink = ({ linkTo, WL, text, isActive }) => {

    const c = use.useColor();

    const navigate = useNavigate();

    const error = useRecoilValue(store.error.main);
    const isError = error?.name;

    const [userbar, setUserbar] = useRecoilState(store.user.userbar);

    const onPress = () => {
        if(userbar?.isOn) setUserbar({})
        if(!isError) navigate(linkTo)
    };

    const color = isActive ? c.onMainC1 : c.onMainN1;

    const style = {
        line:       [s.row_cXcY, s.Y40, s.space.mB2, isError ? {opacity:0.3} : null],
        icon:       [s.X48Y40],
        text:       [{lineHeight:24, fontWeight:'bold'}],
    };
    
    return ( 
        <Pressable style={style.line} onPress={onPress}>
            <Textline style={style.text} color={color} WL={WL} text={text}/>
        </Pressable>
    );
};
export default UserLink;