import { Titlebar, Element, Icon, Textline } from '../../../../modules/basics/components';
import { s, abs, use } from '../../../../root';

const LoadingPage = ({ params }) => {

    const c = use.useColor();

    const style = {
        logo:   [s.X48Y40],
        icon:   [s.X32Y40],
        text:   [s.textPod],
    };

    const pageLogo = <Icon style={style.logo} color={c.onMainN1_d} svg={abs.book.svg({typename:'Page'})}/>
    const blockLogo = <Icon style={style.logo} color={c.onMainN1_d} svg={abs.book.svg({typename:'Block'})}/>
    const options = <Icon style={style.icon} color={c.onMainN1_d} svg='optionsVertical_16'/>
    const adder = <Icon style={style.icon} color={c.onMainN1_d} svg='plus_14_line_24'/>

    return (
        <>
            <Titlebar
                title={`WL_book_Page`} 
                backTo={abs.book.link('index', params)} 
                actions={<>{options}{adder}</>}
            />
            <Element logo={pageLogo} options={options} adder={adder}>
                <Textline style={style.text} color={c.onMainN1_d} WL text='WL_book_PageLoading'/>
            </Element>
            <Element logo={blockLogo} options={options} adder={adder}>
                <Textline style={style.text} color={c.onMainN1_d} WL text='WL_book_BlockListLoading'/>
            </Element>
        </>
    );       
};
export default LoadingPage;