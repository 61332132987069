import { View } from 'react-native';
import { Textline } from '../../../../modules/basics/components';
import { s, use } from '../../../../root';

const Empty = ({ typename }) => {

    const c = use.useColor();

    const style = {
        line:   [s.row_sXcY, s.Y40, s.space.mB2],
        icon:   [s.X48Y40],
        text:   [s.space.mS48, s.space.pE16, {lineHeight:24, fontWeight:'bold'}],
    };
    
    return ( 
        <View style={style.line}>
            <Textline style={style.text} color={c.onBarN1_d} WL text={`WL_book_${typename}ListEmpty`}/>
        </View>
    );
};
export default Empty;