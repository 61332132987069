import { View } from 'react-native';
import { use } from '../../../root';
import { Modal } from '../../../modules/modal/components';
import { Textline } from '../../../modules/basics/components';

const Cookies = ({ id }) => {

    const t = use.useText();

    const title = t('WL_cookies_explained')
    const svg = 'placeholder_16'
    const actions = null

    return (
        <Modal id={id} title={title} svg={svg} actions={actions}>
            <View >
                <View>
                    <Textline WL text='WL_cookies_explained'/>
                </View>
            </View>
        </Modal>
    );
}; 
export default Cookies;