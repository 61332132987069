import { useRecoilState } from 'recoil';
import { v4 as getUUID } from 'uuid';
import { View } from 'react-native';
import { Icon, TextBox } from '../../../../modules/basics/components';
import { s, use, store } from '../../../../root';

const Attribute = ({ name, attribute, svg, text, modify, isDisabled }) => {

    const c = use.useColor();

    const { typename, ids, item } = modify

    const [modals, setModals] = useRecoilState(store.modal.modals)
    const edit = () => {
        const id = getUUID();
        setModals([...modals, { id, name, attribute, typename, ids, item }]);
    };

    const style = {
        element:    [s.col, {minHeight:42}, c.mainN1],
        body:       [s.row_sXsY],
        line:       [s.Y2, s.Xc, c.mainN1],
        one:        [s.row],
        middle:     [s.row_sXcY, s.Xr, {width:0}],
        two:        [s.row],
        text:       [s.textPod],
        icon:       [s.X48Y40],
        pop:        [s.X32Y40],
    };

    const color = isDisabled ? c.onMainN1_d : c.onMainN1;

    return (
        <View style={style.element}>
            <View style={style.body}>
                <View style={style.one}>
                    <Icon style={style.icon} color={c.onMainN1} svg={svg}/>
                </View>
                <View style={style.middle}>
                    <TextBox style={style.text} color={color} text={text}/>    
                </View>
                <View style={style.two}>
                    <Icon style={style.icon} color={c.onMainN1} svg='edit_16' onPress={edit}/>
                </View>
            </View>
            <View style={style.line}></View>
        </View>
    );     
}; 
export default Attribute;