import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { store, abs } from '../../../../root';
import { Modal } from '../../../../modules/modal/components';
import { AgentWrite } from '../../../../app/book/agents.js';
import { ModalTextField } from '../../../../app/book/suits.js';

const EditData = ({id}) => {

    const typename = 'Data';

    const popover = useRecoilValue(store.popover.popover)
    const { dataItemId, item } = popover;

    const [operationId, setOperationId] = useState('');

    const [type, setType] = useState(item.type);
    const [key, setKey] = useState(item.key);
    const [value, setValue] = useState(item.value);
    const [metadata, setMetadata] = useState(item.metadata);

    const save = () => {
        const input = { type, key, value, metadata, id:dataItemId };
        const operation = 'update';
        const itemId = dataItemId;
        const generatedId = abs.book.operation.add({typename, operation, input, itemId});
        setOperationId(generatedId);
    };

    const title = 'WL_book_DataUpdate';
    const svg = 'placeholder_16';

    const refetch = {refetch:'update', operationId, modalId:id};

    const actions = <AgentWrite operationId={operationId} action={save} refetch={refetch} isDisabled={!value && !type}/>


    return (
        <Modal id={id} title={title} svg={svg} actions={actions}>
            <ModalTextField typename={typename} field={'type'} value={type} setValue={setType}/>
            <ModalTextField typename={typename} field={'key'} value={key} setValue={setKey}/>
            <ModalTextField typename={typename} field={'value'} value={value} setValue={setValue}/>
            <ModalTextField typename={typename} field={'metadata'} value={metadata} setValue={setMetadata}/>
        </Modal>
    );
}; 
export default EditData;