import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { store } from '../../../root';

const ThrowErrorRemove = ({name=null, data={}}) => {

    const setPopover = useSetRecoilState(store.popover.popover);
    const url = useRecoilValue(store.router.url)
    const [error={}, setError] = useRecoilState(store.error.main);

    useEffect(()=> {
        if (!error.name) {
            setError({name, url, ...data})
            setPopover({})
        };
    })
    return null;
};
export default ThrowErrorRemove;