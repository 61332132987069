import { View, Pressable } from 'react-native';
import { s } from '../../../../root';
import { Svg } from '../../../../modules/basics/components';

const Icon = ({ style=[], color, onPress, svg }) => { 
    
    console.log('Icon noIsRTL svg', svg, ' color', color?.color);
    
    return (
        <View style={[...style, s.row_cXcY]}>
            <Pressable onPress={onPress}>
                <Svg svg={svg} color={color?.color}/>   
            </Pressable>
        </View>
    );
};
export default Icon;