import { useRecoilValue } from 'recoil';
import { Picture } from './atom.js';
import { store } from '../../../../root';

const useFetch = (data) => {

    const { type, id } = data;
    store.router.params = data;
    const value = useRecoilValue(Picture([type, id])); 
    const fetch = () => value
    return fetch;

};
export default useFetch;