import { useState, Suspense, useLayoutEffect } from 'react';
import { abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { Expander, MenuLink } from '../../../../modules/basics/menus';
import Pages from './Pages.js';

const Section = ({ url, typename, params, section, sectionPosition }) => {

    const { bookItemId, sectionHookId, sectionItemId } = params;

    const current = abs.book.params(url);
    const isCurrentSection = current.sectionHookId === sectionHookId;

    const [isOn, setIsOn] = useState(false);
    const onClickOnOff = () => setIsOn(!isOn);
    useLayoutEffect(() => {if (isCurrentSection) setIsOn(true)},[isCurrentSection, setIsOn]);     

    const fetchItem = api.book.useFetch(section.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorMain name='bookPage' data={section.item}/>

    const childTypename = abs.book.childOf(typename);
    const childList = {typename:childTypename, type:'list', id:sectionItemId, fetchId:bookItemId};

    return (
        <>
            <Expander 
                isOn={isOn} 
                isCurrent={isCurrentSection}
                onPress={onClickOnOff}
                text={`${sectionPosition}. ${item.value}`}
            />
            {!isOn ? null :
            <Suspense fallback={<MenuLink svg='placeholder_16' WL text='WL_book_PageListLoading'/>}>
                <Pages
                    url={url}
                    typename={childTypename}
                    params={params}
                    page={{list:childList}}
                    sectionPosition={sectionPosition}
                />
            </Suspense>
            }
        </>
    );
}; 
export default Section;

/// da finire ma sulla buona strada!!! 