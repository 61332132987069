import { View } from 'react-native';
import { Icon, Textline } from '../../../../modules/basics/components';
import { s, abs, use } from '../../../../root';

const Loading = ({ typename }) => {

    const c = use.useColor();

    const svg = abs.book.svg({typename});
    const text = `WL_book_${typename}ListLoading`;

    const style = {
        line:    [s.row, s.Y40],
        icon:    [s.X48Y40],
        text:    [s.row_sXcY, s.Xr, s.Y40],
    };

    return (
        <View style={style.line}>
            <Icon style={style.icon} color={c.onMainN1_d} svg={svg}/>
            <Textline style={style.text} color={c.onMainN1_d} WL text={text}/>
        </View>
    );       
}; 
export default Loading;