import { abs, api } from '../../../../root';
import { SelectorElement } from '../../../../app/book/suits.js';
import { ThrowErrorModal } from '../../../../modules/error/throw';

const Section = ({ modalId, selection, params, navigate, position, section }) => {

    const { typename, bookItemId } = params;
    const { initial, selectable, select, deselect, setSelected, selected } = selection;

    const svg = abs.book.svg({typename});
    
    const id = `${bookItemId}_${position}`;
    const invalidId = initial.isMove && [`${initial.itemId}_${initial.position}`, `${initial.itemId}_${initial.position-1}`].includes(id);
    const isSelectable = selectable === 'Section' && !invalidId;
    const isSelected = selected === id;

    const onSelect = () => { 
        if (isSelectable) {
            if (isSelected) deselect()
            else {
                select({ start:position+1, destination_id:bookItemId })
                setSelected(id)
            }
        };
    };
    
    const onOpen = () => {
        if (['Page', 'Block', 'Data'].includes(selectable)) {
            navigate({...params, typename:'Page'})
        };
    };

    const fetchItem = api.book.useFetch(section.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorModal name='bookSelector' data={{data:section.item, params, modalId}}/>

    return (
        <SelectorElement 
            svg={svg} text={item.value} 
            onSelect={onSelect} onOpen={onOpen}
            isSelectable={isSelectable} isSelected={isSelected}
        />
    );
}; 
export default Section;