import { useRecoilValue } from 'recoil';
import store from '../root/store.js';
import wordlist from '../root/wordlist.js';

const useText = () => {

    const language = useRecoilValue(store.layout.language);

    // REFACTORING
    // Remove ?_ in production

    const text = (text, isNotWL) => {
        if (isNotWL) return text
        else return wordlist[text]?.[language] || `?_${text}`
    };
    return text;
};
export default useText;