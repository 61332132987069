import { useRecoilValue } from 'recoil';
import { View } from 'react-native';
import { s, use, store, footers } from '../../../../root';

const Footer = () => { 

    const c = use.useColor();

    const footer = useRecoilValue(store.layout.footer);
    
    const style = [s.X, s.Y8, c.bandN1];

    if (footer) return footers[footer];
    return <View style={style}></View>
}
export default Footer;