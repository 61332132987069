import { View } from 'react-native';
import { s } from '../../../root';

const Field = ({ one, children, two }) => {

    const style = {
        field:  [s.row, s.space.mY8],
        one:    [s.X48],
        middle: [s.col, s.Xr],
        two:    [s.X48],
    };

    return (
        <View style={style.field}>
            <View style={style.one}>{one}</View>
            <View style={style.middle}>{children}</View>
            <View style={style.two}>{two}</View>
        </View>
    );
}; 
export default Field;