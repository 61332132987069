import { useRecoilValue } from 'recoil';
import { api, store} from '../../../../root';
import { ErrorButton } from '../../../../app/book/suits.js';

const SelectorRefetch = ({ rerender, setIsIssueOk }) => {

    const error = useRecoilValue(store.error.modal);

    const [refetchAtom, setAtom] = api.book.useRefetch(error.data);

    const onRefetch = async () => {

        const newAtom = await refetchAtom();
        if (newAtom==='error') return rerender();
        
        setAtom(newAtom);
        setIsIssueOk(true);
    };

    return <ErrorButton onPress={onRefetch} text='WL_error_refetch' isMain/>

};
export default SelectorRefetch;