import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { useLocation } from '../../../platform/router/';
import { api, abs, use, store } from '../../../root';
import { Button } from './pods';

WebBrowser.maybeCompleteAuthSession();

const Auth = ({ setIsAuthOk, setIsNetworkOk}) => {

    const user = useRecoilValue(api.user.user)||{};
    const url = useLocation().pathname;

    useEffect(()=> {
        const check = async () => {
            if (!(await abs.isNetwork())) setIsNetworkOk(false);
            if (user?.id) setIsAuthOk(true)
            if (abs.isPublic(url)) setIsAuthOk(true)
        };
        check();
    });

    const [request, result, promptAsync] = AuthSession.useAuthRequest(store.user.params, store.user.discovery);
    const tokenFetch = use.user.useTokenFetch(result, store.user.discovery);
    tokenFetch

    const onCheckLogin = async () => {
        if (await abs.isNetwork()) promptAsync();
        else setIsNetworkOk(false);
    };

    return <Button onPress={onCheckLogin} text='WL_error_auth' isMain/>

};
export default Auth;