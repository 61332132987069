import { v4 as getUUID } from 'uuid';
import { abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { PageData } from '../../../../app/book/suits';

const Data = ({ typename, params={}, data={}, dataPosition }) => {

    const fetchItem = api.book.useFetch(data.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorMain name='bookPage' data={data.item}/>

    const svg = abs.book.svg({typename, type:item.type});

    const id = getUUID();
    const popover = abs.book.popover({
        header: {svg, title:item.value},
        popover: {id, typename, data, dataPosition, ...params, item},
        options: {name:'optionsData'},
        adder: {name:'adderData'},
    });

    return <PageData id={id} svg={svg} item={item} options={popover.options} adder={popover.adder}/>
}; 
export default Data;