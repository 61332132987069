import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { View } from 'react-native';
import { s, abs, store } from '../../../../root';
import { LoadingCover } from '../../../../app/book/suits';
import Book from './Book.js';

const BookCover = () => {

    const typename = 'Book';
    const svg = abs.book.svg({typename});

    const url = useRecoilValue(store.router.url);

    const { setId, bookItemId } = abs.book.params(url);
    const params = { setId, bookItemId };
    const item = {typename, type:'item', id:bookItemId, fetchId:bookItemId, isBook:true};

    if (!bookItemId) return null;
    return (
        <View style={[s.scrollView]}>
            <Suspense fallback={<LoadingCover/>}>
                <Book
                    svg={svg}
                    typename={typename}
                    params={params}
                    book={{item}}
                    key={`book${bookItemId}`}
                />
            </Suspense>
        </View>
    );
};
export default BookCover;