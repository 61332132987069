import { View } from 'react-native';
import { s, use } from '../../../../root';
import { Icon } from '../../../../modules/basics/components';

const AgentItem = ({ svg, children }) => {

    const c = use.useColor();

    const style = { 
        item:       [s.col],
        body:       [s.row_sXcY, s.Y40],
        icon:       [s.X48Y40], 
        line:       [s.Y2, s.Xc],
    };

    return ( 
        <View style={style.item}>
            <View style={style.body}>
                <Icon style={style.icon} color={c.onMainN1} svg={svg}/>
                {children}
            </View>
            <View style={style.line}></View>
        </View>
    );
}; 
export default AgentItem;