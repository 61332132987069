import Page from './errors/Page.js';
import Selector from './errors/Selector.js';
import Write from './errors/Write.js';
import Refetch from './errors/Refetch.js';

export const bookPage = <Page/>;
export const bookSelector = <Selector/>;

export const bookWrite = <Write errorType='modal'/>;
export const bookRemove = <Write errorType='main'/>;

export const bookWriteRefetch = <Refetch errorType='modal'/>;
export const bookRemoveRefetch = <Refetch errorType='main'/>;
