import { useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { api, store} from '../../../../root';
import { ErrorButton } from '../../../../app/book/suits.js';

const RelaunchOperation = ({ errorType, rerender, setIsIssueOk }) => {

    const error = useRecoilValue(store.error[errorType]) || {};

    console.log('eeeeeee ', error)

    const [write, setWrite] = useRecoilState(api.book.Write(error.operationId))

    const onRelaunch = () => setWrite('retry')

    useEffect(()=>{
        if (write==='success') setIsIssueOk(true)
        else rerender()
    },[write])

    return <ErrorButton onPress={onRelaunch} text='WL_error_refetch' isMain/>

};
export default RelaunchOperation;