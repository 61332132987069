import { View, Text } from 'react-native';
import { useRecoilValue } from 'recoil';
import { abs, api } from '../../../root';
import { Titlebar, Card } from '../../../modules/basics/components';

const BookPage = () => {

    const user = useRecoilValue(api.user.user);
    const userId = user?.id;

    return (
        <View>
            <Titlebar 
                backTo='/'
                title='WL_home_creator'
            />
            <Card 
                isGuest={!userId}
                linkTo={`/set/${userId}/books/overview`}  
                svg='book_16' 
                title='WL_home_books' 
                description='WL_home_books_description' 
            />
            <Card 
                isGuest={!userId}
                linkTo={`/set/${userId}/pictures/overview`} 
                svg='picture_16' 
                title='WL_home_pictures' 
                description='WL_home_pictures_description' 
            />
        </View>
    );
};
export default BookPage;