import { atom } from 'recoil';

const modal = {

    url: atom({key:'modalUrl', default:''}),
    path:'',
    params:{},

    operation:'',
    operationId:'',

    modals: atom({key:'modal', default:[]}),

    isSideOneOn: atom({key:'isModalSideOneOn', default:false}),
    isSideTwoOn: atom({key:'isModalSideTwoOn', default:false}),
    
};
export default modal;
