import AsyncStorage from '@react-native-async-storage/async-storage';
import { errors } from '../../root';

const get = async (key) => {
    console.log('inside platform > localStorage > AsyncStorage > get > ', key)
    try { 
        const value = await AsyncStorage.getItem(key)
        return value
    } catch (error) {abs.errorCatch('modules layout api.localstorage.read', error)};
};

const set = async (key, value) => {
    console.log('inside platform > localStorage > AsyncStorage > set > ', key, value)
    try {
        await AsyncStorage.setItem(key, value);
        return value
    } catch (error) {abs.errorCatch('modules layout api.localstorage.write', error)}; 
};

const remove = async (key) => {
    console.log('inside platform > localStorage > AsyncStorage > remove > ', key)
    try {
        await AsyncStorage.removeItem(key);
        return true
    } catch (error) {abs.errorCatch('modules layout api.localstorage.write', error)}; 
};
    
export { get, set, remove };