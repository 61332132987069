import { RecoilRoot } from 'recoil';
import { ApolloProvider } from '@apollo/client';
import { RouterProvider } from './App/platform/router';
import { apollo } from './App/clients';
import { Router } from './App/modules/router/components';
import { Suspense } from 'react';
import { Fallback } from './App/modules/layout/components';
import Initial from './App/index.js';

const App = () => {
  return (
    <RecoilRoot>
      <ApolloProvider client={apollo}>
        <RouterProvider>
            <Initial/>
            {/* <Suspense fallback={<Fallback/>}>
                <Router/>
            </Suspense> */}
        </RouterProvider>
      </ApolloProvider>
    </RecoilRoot>
  )
};
export default App;