import { s, use, abs } from '../../../root';
import { Textline } from '../../../modules/basics/components';

const ErrorTime = ({ isLoader }) => {

    const t = use.useText();
    const c = use.useColor();

    const style = {lineHeight:20, fontSize:14}

    const errorMessage = `${t('WL_error_message')} ${abs.time()}`

    return <Textline style={[style]} color={isLoader ? c.onBandN1:c.onMainN1} text={errorMessage}/>
};
export default ErrorTime;