import { gql } from '@apollo/client';
import fragment from '../../fragment.js';

const createBook = gql`

    ${fragment.book.item}

    mutation createBook ($input: newBook!) {
        createBook(input: $input) {
            ...bookItemFields
        }
    }
`;

export default createBook;