import alert from '../modules/alert/store.js'; 
import basics from '../modules/basics/store.js'; 
import cookies from '../modules/cookies/store.js'; 
import error from '../modules/error/store.js'; 
import layout from '../modules/layout/store.js'; 
import modal from '../modules/modal/store.js'; 
import popover from '../modules/popover/store.js'; 
import router from '../modules/router/store.js'; 
import user from '../modules/user/store.js'; 

import book from '../app/book/store.js'; 
import picture from '../app/picture/store.js'; 

const store = {

    alert,
    basics,
    cookies,
    error,
    layout,
    modal,
    popover,
    user, 
    router,

    book,
    picture

};
export default store;