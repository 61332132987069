import { useRecoilValue } from 'recoil';
import { View } from 'react-native';
import { s, store } from '../../../../root';
import { SidebarContent } from '../pods';

const Sidebar = ({ isSidebarOne, isInternal }) => {

    const c = s.colors;

    const size = useRecoilValue(store.layout.size);
    const isSideOneOn = useRecoilValue(store.layout.isSideOneOn);
    const isSideTwoOn = useRecoilValue(store.layout.isSideTwoOn);

    const isOneColumn   = size === '1';
    const isThreeColumn = size === '3';

    if (!isOneColumn && !isInternal && isSidebarOne) return null
    if (isOneColumn && isInternal && isSidebarOne) return null

    if (isThreeColumn && !isInternal && !isSidebarOne) return null
    if (!isThreeColumn && isInternal && !isSidebarOne) return null

    if (isSidebarOne && !isSideOneOn) return null
    if (!isSidebarOne && !isSideTwoOn) return null

    const style = {
        overlay:    [s.overlay, {zIndex:1}, isSidebarOne ? s.col_sXsY : s.col_eXeY],
        external:   [s.col, s.X320, s.Y, isSidebarOne ? s.space.mS8 : s.space.mE8],
        internal:   [s.col, s.X320, s.Xr],
        header:     [isSidebarOne ? s.row_sXsY : s.row_eXeY, s.Y48],
        body:       [s.col, s.Yc, s.X, c.barN1],
        footer:     [isSidebarOne ? s.row_sXsY : s.row_eXeY, s.Y48],
        icon:       [s.XY48],
    };

    return (
        <View style={style.internal}> 
            <View style={style.body}>
                <SidebarContent isTwo={!isSidebarOne}/>
            </View>
        </View>
    );
};
export default Sidebar;