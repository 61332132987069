import * as AuthSession from 'expo-auth-session';
import { useRecoilState } from 'recoil'; 
import { useEffect } from 'react';
import { store } from '../../../root';
import useLogin from './useLogin.js';

const useTokenFetch = ( result, discovery ) => {

    const login = useLogin();
    const [error, setError] = useRecoilState(store.error.main)

    const tokenFetch = useEffect(() => {     

        const code = result?.params?.code;
        const tokenFetchAsync = async () => {
            const token = await AuthSession.exchangeCodeAsync({code, ...store.user.params}, discovery);
            login(token);
            if (error) setError({});
        };
        
        if (code) tokenFetchAsync();
        
    }, [result]);

    return tokenFetch;
};
export default useTokenFetch;