const wordlist = {

    'WL_cookies_welcome' : {
        en:'Welcome',
        it:'Benvenuto',
    },

    'WL_cookies_language' : {
        en:'Language',
        it:'Lingua',
    },

    'WL_cookies_save_and_close' : {
        en:'SAVE AND CLOSE',
        it:'SALVA E CHIUDI',
    },

    'WL_cookies_selectAll' : {
        en:'SELECT ALL',
        it:'SELEZIONA TUTTI',
    },
    'WL_cookies_deselectAll' : {
        en:'DESELECT ALL',
        it:'DESELEZIONA TUTTI',
    },

    'WL_cookies_cookies' : {
        en:'Cookies',
        it:'Cookies',
    },
    'WL_cookies_description' : {
        en:'We use necessary cookies to make our site work. We would like to set analytics cookies that help us make improvements by measuring how you use the site. If it is not too much, we would also like to set marketing cookies to remember the product categories you are interested in, in order to show you more appropriate advertisements. These will be set only if you accept.',
        it:'Utilizziamo i cookie necessari per far funzionare il sito. Vorremmo impostare i cookie analitici che ci aiutano ad introdurre miglioramenti misurando il modo in cui il sito viene utilizzato. Se non è troppo, vorremmo anche impostare cookie di marketing per ricordare le categorie di prodotti alle quali siete interessati, al fine di mostrarvi pubblicità più appropriate. Questi saranno impostati solo se accettati.',
    },

    'WL_cookies_necessary' : {
        en:'Necessary cookies (always on)',
        it:'Cookies necessari (sempre abilitati)',
    },
    'WL_cookies_necessary_description' : {
        en:'Necessary cookies enable core functionality such as security, network management, and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.',
        it:"I cookie necessari consentono di attivare funzionalità fondamentali come la sicurezza, la gestione della rete e l'accessibilità. È possibile disattivarli modificando le impostazioni del browser, ma ciò potrebbe influire sul funzionamento del sito web.",
    },

    'WL_cookies_analytics' : {
        en:'Analytics cookies',
        it:'Cookies per le statistiche di utilizzo',
    },
    'WL_cookies_analytics_description' : {
        en:'We would like to set analytics cookies to help us improve our website by collecting and reporting information on how you use it. The cookies collect information in a way that does not directly identify anyone. For more information on how these cookies work please see our "Cookies page".',
        it:'Vorremmo impostare dei cookie analitici per aiutarci a migliorare il nostro sito web raccogliendo e riportando informazioni sul suo utilizzo. I cookie raccolgono informazioni in modo da non identificare direttamente nessuno. Per ulteriori informazioni sul funzionamento di questi cookie, consultare la nostra pagina "Cookie".',
    },

    'WL_cookies_marketing' : {
        en:'Marketing Cookies',
        it:'Cookies per il marketing',
    },
    'WL_cookies_marketing_description' : {
        en:'We would like to set marketing cookies to remember the product categories you are interested in, in order to show you more appropriate advertisements. For more information on how these cookies work please see our "Cookies page".',
        it:'Vorremmo impostare dei cookie di marketing per ricordare le categorie di prodotti a cui siete interessati, al fine di mostrarvi pubblicità più appropriate. Per ulteriori informazioni sul funzionamento di questi cookie, consultare la nostra pagina "Cookie".',
    },

    'WL_cookies_behavioral' : {
        en:'Behavioral Targeting Cookies (always off)',
        it:'Cookies per il targeting comportamentale (sempre disabilitati)',
    },
    'WL_cookies_behavioral_description' : {
        en:"We don't use behavioral targeting cookies. Behavioral targeting is the presentation of content and marketing based on the previous choices of a user across websites.",
        it:'Non usiamo cookies di tracciamento del comportamento. Il targeting comportamentale è la presentazione di contenuti e marketing basata sulle scelte precedenti di un utente attraverso i siti web.',
    },

}; 
export default wordlist;


