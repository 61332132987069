import { s, use } from '../../../../root';
import { Link as BasicLink, Textline } from '../../../../modules/basics/components';

const Link = ({ linkTo, text }) => {

    const c = use.useColor();

    const style = {
        button:         [s.button, s.Y32],
        text:           [{fontWeight:'bold'}],
    };

    return ( 
        <BasicLink style={style.button} color={c.buttonN1} linkTo={linkTo}>
            <Textline style={style.text} color={c.onButtonN1} WL text={text}/>
        </BasicLink>
    );
};
export default Link;