import { useRecoilValue } from 'recoil';
import { View, TextInput } from 'react-native';
import { s, use, store } from '../../../../root';
import { Icon } from '../../../../modules/basics/components';

const Finder = () => {

    const t = use.useText();
    const c = use.useColor();

    const isFixed = useRecoilValue(store.layout.isFixed);
    const isOversize = useRecoilValue(store.layout.isOversize);

    const onChangeText = () => {

    };

    const style = {
        finder: [s.row_cXcY, s.Xr], //, s.space.mX16],
        bar:    [s.row, isFixed && isOversize ? s.X960max : s.Xr, c.mainN1, s.radius.r16],
        icon:   [s.X48Y32],
        input:  [s.Xr, s.Y32, c.mainN1, c.onMainN1, s.input],
    };

    return (
        <View style={style.finder}>
            <View style={style.bar}>
                <Icon style={style.icon} color={c.onMainN1} svg='magnifier_16'/>
                <TextInput style={style.input} onChangeText={onChangeText} placeholder={t('WL_layout_find')}/>
                <Icon style={style.icon} color={c.onMainN1} svg='confirmFalse_16'/>
            </View>
        </View>
    );
};

export default Finder;