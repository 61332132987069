import { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { use, store } from '../../../root';
import { Network, Auth, Close } from '../../../modules/error/triggers.js';
import { RefetchSelector, CleanupSelector } from '../../../app/book/actions.js';
import { ErrorPage } from '../../../modules/error/pages'

const BookSelector = () => {

    const [isNetworkOk, setIsNetworkOk] = useState(false);
    const [isAuthOk, setIsAuthOk] = useState(null);
    const [isIssueOk, setIsIssueOk] = useState(false);

    const initialize = use.error.useInitialize(setIsNetworkOk, setIsAuthOk);
    initialize

    const url = useRecoilValue(store.router.url);
    const [error={}, setError] = useRecoilState(store.error.main);
    useEffect(() => { if (url !== error.url) setError({}) })

    const [toggler, setToggler] = useState(false);
    const rerender = () => setToggler(!toggler)

    return (
        <ErrorPage source='selectors'
            isNetworkOk={isNetworkOk}
            isAuthOk={isAuthOk}
            isIssueOk={isIssueOk}

            network = {<Network rerender={rerender} setIsNetworkOk={setIsNetworkOk}/>}
            auth = {<Auth setIsAuthOk={setIsAuthOk} setIsNetworkOk={setIsNetworkOk}/>}
            close = {<Close errorType='modal'/>}
            action = {<RefetchSelector rerender={rerender} setIsIssueOk={setIsIssueOk}/>}
            cleanup = {<CleanupSelector/>}
        />
    );
};
export default BookSelector;