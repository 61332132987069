import { abs, api } from '../../../../root';
import { SelectorItem } from '../../../../app/book/suits.js';
import { ThrowErrorModal } from '../../../../modules/error/throw';

const Section = ({ modalId, selection, params, navigate, section, position }) => {

    const { typename, bookItemId, sectionHookId } = params;
    const { initial, selectable, select, deselect, setSelected, selected } = selection;

    const svg = abs.book.svg({typename});
    
    const id = sectionHookId;
    const invalidId = initial.isMove && initial.hookId === id;
    const isSelectable = selectable === 'Section' && !invalidId;
    const isSelected = selected === id;

    const onSelect = () => { 
        if (isSelectable) {
            if (isSelected) deselect()
            else {
                select({ ids:[sectionHookId], origin_id:bookItemId });
                setSelected(id)
            }
        };
    };
    
    const onOpen = () => {
        if (['Page', 'Block', 'Data'].includes(selectable)) navigate({...params, typename:'Page'})
    };

    const fetchItem = api.book.useFetch(section.item);
    const item = fetchItem();
    if (abs.isError(item)) return <ThrowErrorModal name='bookSelector' data={{data:section.item, params, modalId}}/>

    return (
        <SelectorItem 
            svg={svg} text={item.value} 
            onSelect={onSelect} onOpen={onOpen}
            isSelectable={isSelectable} isSelected={isSelected}
        />
    );
}; 
export default Section;