import { View } from 'react-native';
import { Icon, Textline } from '../../../../modules/basics/components';
import { s, use } from '../../../../root';

const Placeholder = ({ svg, text }) => {

    const c = use.useColor();

    const style = {
        box:        [s.row_sXcY, { minHeight:40 }],
        data:       [s.Xr, { width:0 }],
        icon:       [s.X48Y40],
        text:       [s.textPod],
    };

    return (
        <View style={style.box}>
            <Icon style={style.icon} color={c.onMainN1_d} svg={svg}/>
            <View style={style.data}>
                <Textline style={style.text} color={c.onMainN1_d} text={text}/>
            </View>
        </View>
    );
}; 
export default Placeholder;