import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { api } from '../../../root';
import { AgentInline, AgentButton } from '../../../app/book/suits.js'

const Write = ({ operationId, setResponse, isRemove }) => {

    const response = useRecoilValue(api.book.Write(operationId))||null
    useEffect(()=> setResponse(response), [response])
    return isRemove ? <AgentInline label='WL_book_remove'/> : <AgentButton label='WL_book_operation'/>

};
export default Write;