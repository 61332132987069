import { useRecoilValue, useRecoilState } from 'recoil';
import { View, Pressable } from 'react-native';
import { s, use, store } from '../../../../root';
import { Icon } from '../../../../modules/basics/components';

const Sidebar = ({isSidebarOne, isInternal, menu}) => {

    const c = use.useColor();

    const size = useRecoilValue(store.layout.size);
    const [isSideOneOn, setIsSideOneOn] = useRecoilState(store.layout.isSideOneOn);
    const [isSideTwoOn, setIsSideTwoOn] = useRecoilState(store.layout.isSideTwoOn);

    const isOneColumn   = size === '1';
    const isThreeColumn = size === '3';

    if (!isOneColumn && !isInternal && isSidebarOne) return null
    if (isOneColumn && isInternal && isSidebarOne) return null

    if (isThreeColumn && !isInternal && !isSidebarOne) return null
    if (!isThreeColumn && isInternal && !isSidebarOne) return null

    if (isSidebarOne && !isSideOneOn) return null
    if (!isSidebarOne && !isSideTwoOn) return null

    const setSideOff = () => isSidebarOne ? setIsSideOneOn(false) : setIsSideTwoOn(false);

    const style = {
        overlay:    [s.overlay, {zIndex:1}, isSidebarOne ? s.row_sXsY : s.rowR_sXcY, c.overlayN1],
        external:   [s.col, s.X320max, {flexGrow:10}, s.Y, isSidebarOne ? s.space.pS8 : s.space.pE8, c.barN1],
        space:      [s.row, s.X104min, s.Xr, s.Y],
        internal:   [s.col, s.X320, s.Xr],
        header:     [isSidebarOne ? s.row_sXsY : s.row_eXeY, s.Y48],
        body:       [s.col, s.Yc, s.X],
        iconBox:    [s.row_cXcY, s.XY48],
        icon:       [s.XY40, s.radius.r32, c.bandN1]
    };

    return (
        <>{
            isInternal ?
            <View style={style.internal}> 
                <View style={style.body}>{menu}</View>
            </View>
            :
            <View style={style.overlay}>
                <View style={style.external}>
                    <View style={style.header}>
                        <View style={style.iconBox}>
                            <Icon style={style.icon} color={c.onBandN1} svg='menu_16' onPress={setSideOff}/>
                        </View>
                    </View>
                    <View style={style.body}>{menu}</View>
                </View>
                <Pressable style={style.space} onPress={setSideOff}></Pressable>
            </View>
        }</>
    );
};
export default Sidebar;