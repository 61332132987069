const wordlist = { 

    'WL_book_PicturesOverview' : {
        en:'Overview',
        it:'Panoramica',
    },

    'WL_book_myPictures' : {
        en:'My Pictures',
        it:'Le mie immagini',
    },

    'WL_book_myPictures_description' : {
        en:'All your pictures are here',
        it:'Tutte le tue immagini sono qua',
    },

    'WL_picture_pictures_list' : {
        en:'Pictures List',
        it:'Lista delle Immagini',
    },

    'WL_picture_open' : {
        en:'Open',
        it:'Apri',
    },

    'WL_picture_remove' : {
        en:'Remove',
        it:'Rimuovi',
    },

    'WL_picture_create' : {
        en:'Create',
        it:'Crea',
    },

    'WL_picture_the_Picture' : {
        en:'the picture',
        it:"l'immagine",
    },

    'WL_picture_this_Picture' : {
        en:'this picture',
        it:'questa immagine',
    },

    'WL_picture_a_new_Picture' : {
        en:'a new picture',
        it:'una nuova immagine',
    },

    'WL_picture_save_and_close' : {
        en:'SAVE AND CLOSE',
        it:"SALVA E CHIUDI",   
    },



    'WL_picture_Picture_create' : {
        en:'Create a picture',
        it:"Crea un'immagine",
    },

    'WL_picture_Picture_remove_loading' : {
        en:'Removing the picture',
        it:"Rimuovendo l'immagine",   
    },

    'WL_picture_loading' : {
        en:'OPERATION IN PROCESS',
        it:'OPERAZIONE IN CORSO',
    },

    'WL_picture_operation_ok' : {
        en:'OPERATION COMPLETED',
        it:'OPERAZIONE COMPLETATA',
    },

    'WL_picture_confirm' : {
        en:'CONFIRM',
        it:'CONFERMA',
    },

    'WL_picture_to_remove' : {
        en:'to remove',
        it:'per rimuovere',
    },

    'WL_picture_removed' : {
        en:'Picture removed',
        it:'Immagine rimossa',
    },

    'WL_picture_Picture' : {
        en:'Picture',
        it:'Immagine',
    },
    

};
export default wordlist;