import flag from './flags/index.js';

const countries = {

    gb: {
        flag: flag.GB,
        code:'GB',
        name: 'WL_greatBritain'
    },

    it: {
        flag: flag.IT,
        code:'IT',
        name: 'WL_italy'
    },

};
export default countries;