import { useState, useEffect } from 'react';
import { abs } from '../../../root';
import { Network, Auth } from '../../../modules/error/triggers.js';
import { User, Loaded } from './triggers';
import { ErrorPage } from '../../../modules/error/pages';

const LoaderPage = ({ setIsLoaded }) => {

    const [isNetworkOk, setIsNetworkOk] = useState(false);
    const [isAuthOk, setIsAuthOk] = useState(null);

    const [isInitializeUser, setIsInitializeUser] = useState(false);

    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const check = async () => {
            if (await abs.isNetwork()) {setIsNetworkOk(true); setIsInitializeUser(true)}
            else setIsError(true)
        };
        check();
    });

    const [toggler, setToggler] = useState(false);
    const rerender = () => setToggler(!toggler)

    // ensure there is a connection before initializing the user
    if (!isError && !isInitializeUser) return null
    if (!isError && isInitializeUser) {
        return (
            <User
                setIsLoaded={setIsLoaded} 
                setIsNetworkOk={setIsNetworkOk} 
                setIsAuthOk={setIsAuthOk} 
                setIsError={setIsError}
            />
        );
    };
    return (
        <ErrorPage source='loader'
            isNetworkOk={isNetworkOk}
            isAuthOk={isAuthOk}

            network = {<Network rerender={rerender} setIsNetworkOk={setIsNetworkOk}/>}
            auth = {<Auth setIsAuthOk={setIsAuthOk} setIsNetworkOk={setIsNetworkOk}/>}
            action = {<Loaded setIsLoaded={setIsLoaded}/>}
            hasHome
        />
    );
};
export default LoaderPage;