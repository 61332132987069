// PURE COLORS 

export const white = (n) => `rgba(255,255,255,${n})`; 
export const black = (n) => `rgba(0,0,0,${n})`;
export const grey = (n) => `rgba(128,128,128,${n})`;
export const red = (n) => `rgba(192,64,64,${n})`;
export const green = (n) => `rgba(64,192,64,${n})`;
export const blue = (n) => `rgba(64,128,192,${n})`;
export const orange = (n) => `rgba(192,128,64,${n})`;
export const pink = (n) => `rgba(192,64,128,${n})`;

// COLOR GREY with BLACK >>>>> STATIC COLORS

export const grey016B = (n) => `rgba(120,120,120,${n})`;
export const grey032B = (n) => `rgba(112,112,112,${n})`;
export const grey048B = (n) => `rgba(104,104,104,${n})`;
export const grey064B = (n) => `rgba(96,96,96,${n})`;
export const grey080B = (n) => `rgba(88,88,88,${n})`;
export const grey096B = (n) => `rgba(80,80,80,${n})`;
export const grey112B = (n) => `rgba(72,72,72,${n})`;
export const grey128B = (n) => `rgba(64,64,64,${n})`;
export const grey144B = (n) => `rgba(56,56,56,${n})`;
export const grey160B = (n) => `rgba(48,48,48,${n})`;
export const grey176B = (n) => `rgba(40,40,40,${n})`;
export const grey192B = (n) => `rgba(32,32,32,${n})`;
export const grey208B = (n) => `rgba(24,24,24,${n})`;
export const grey224B = (n) => `rgba(16,16,16,${n})`;
export const grey240B = (n) => `rgba(8,8,8,${n})`;

// COLOR RED with BLACK >>>>> STATIC COLORS

export const red016B = (n) => `rgba(180,60,60,${n})`;
export const red032B = (n) => `rgba(168,56,56,${n})`;
export const red048B = (n) => `rgba(156,52,52,${n})`;
export const red064B = (n) => `rgba(144,48,48,${n})`;
export const red080B = (n) => `rgba(132,44,44,${n})`;
export const red096B = (n) => `rgba(120,40,40,${n})`;
export const red112B = (n) => `rgba(108,36,36,${n})`;
export const red128B = (n) => `rgba(96,32,32,${n})`;
export const red144B = (n) => `rgba(84,28,28,${n})`;
export const red160B = (n) => `rgba(72,24,24,${n})`;
export const red176B = (n) => `rgba(60,20,20,${n})`;
export const red192B = (n) => `rgba(48,16,16,${n})`;
export const red208B = (n) => `rgba(36,12,12,${n})`;
export const red224B = (n) => `rgba(24,8,8,${n})`;
export const red240B = (n) => `rgba(12,4,4,${n})`;

// COLOR GREEN with BLACK >>>>> STATIC COLORS

export const green016B = (n) => `rgba(60,180,60,${n})`;
export const green032B = (n) => `rgba(56,168,56,${n})`;
export const green048B = (n) => `rgba(52,156,52,${n})`;
export const green064B = (n) => `rgba(48,144,48,${n})`;
export const green080B = (n) => `rgba(44,132,44,${n})`;
export const green096B = (n) => `rgba(40,120,40,${n})`;
export const green112B = (n) => `rgba(36,108,36,${n})`;
export const green128B = (n) => `rgba(32,96,32,${n})`;
export const green144B = (n) => `rgba(28,84,28,${n})`;
export const green160B = (n) => `rgba(24,72,24,${n})`;
export const green176B = (n) => `rgba(20,60,20,${n})`;
export const green192B = (n) => `rgba(16,48,16,${n})`;
export const green208B = (n) => `rgba(12,36,12,${n})`;
export const green224B = (n) => `rgba(8,24,8,${n})`;
export const green240B = (n) => `rgba(4,12,4,${n})`;

// COLOR ONE with BLACK >>>>> STATIC COLORS

export const blue016B = (n) => `rgba(60,120,180,${n})`;
export const blue032B = (n) => `rgba(56,112,168,${n})`;
export const blue048B = (n) => `rgba(52,104,156,${n})`;
export const blue064B = (n) => `rgba(48,96,144,${n})`;
export const blue080B = (n) => `rgba(44,88,132,${n})`;
export const blue096B = (n) => `rgba(40,80,120,${n})`;
export const blue112B = (n) => `rgba(36,72,108,${n})`;
export const blue128B = (n) => `rgba(32,64,96,${n})`;
export const blue144B = (n) => `rgba(28,56,84,${n})`;
export const blue160B = (n) => `rgba(24,48,72,${n})`;
export const blue176B = (n) => `rgba(20,40,60,${n})`;
export const blue192B = (n) => `rgba(16,32,48,${n})`;
export const blue208B = (n) => `rgba(12,24,36,${n})`;
export const blue224B = (n) => `rgba(8,16,24,${n})`;
export const blue240B = (n) => `rgba(4,8,12,${n})`;

// COLOR TWO with BLACK >>>>> STATIC COLORS

export const orange016B = (n) => `rgba(180,120,60,${n})`;
export const orange032B = (n) => `rgba(168,112,56,${n})`;
export const orange048B = (n) => `rgba(156,104,52,${n})`;
export const orange064B = (n) => `rgba(144,96,48,${n})`;
export const orange080B = (n) => `rgba(132,88,44,${n})`;
export const orange096B = (n) => `rgba(120,80,40,${n})`;
export const orange112B = (n) => `rgba(108,72,36,${n})`;
export const orange128B = (n) => `rgba(96,64,32,${n})`;
export const orange144B = (n) => `rgba(84,56,28,${n})`;
export const orange160B = (n) => `rgba(72,48,24,${n})`;
export const orange176B = (n) => `rgba(60,40,20,${n})`;
export const orange192B = (n) => `rgba(48,32,16,${n})`;
export const orange208B = (n) => `rgba(36,24,12,${n})`;
export const orange224B = (n) => `rgba(24,16,8,${n})`;
export const orange240B = (n) => `rgba(12,8,4,${n})`;

// COLOR THREE with BLACK >>>>> STATIC COLORS

export const pink016B = (n) => `rgba(180,60,120,${n})`;
export const pink032B = (n) => `rgba(168,56,112,${n})`;
export const pink048B = (n) => `rgba(156,52,104,${n})`;
export const pink064B = (n) => `rgba(144,48,96,${n})`;
export const pink080B = (n) => `rgba(132,44,88,${n})`;
export const pink096B = (n) => `rgba(120,40,80,${n})`;
export const pink112B = (n) => `rgba(108,36,72,${n})`;
export const pink128B = (n) => `rgba(96,32,64,${n})`;
export const pink144B = (n) => `rgba(84,28,56,${n})`;
export const pink160B = (n) => `rgba(72,24,48,${n})`;
export const pink176B = (n) => `rgba(60,20,40,${n})`;
export const pink192B = (n) => `rgba(48,16,32,${n})`;
export const pink208B = (n) => `rgba(36,12,24,${n})`;
export const pink224B = (n) => `rgba(24,8,16,${n})`;
export const pink240B = (n) => `rgba(12,4,8,${n})`;

// COLOR GREY with WHITE >>>>> STATIC COLORS

export const grey016W = (n) => `rgba(136,136,136,${n})`;
export const grey032W = (n) => `rgba(144,144,144,${n})`;
export const grey048W = (n) => `rgba(152,152,152,${n})`;
export const grey064W = (n) => `rgba(160,160,160,${n})`;
export const grey080W = (n) => `rgba(168,168,168,${n})`;
export const grey096W = (n) => `rgba(176,176,176,${n})`;
export const grey112W = (n) => `rgba(184,184,184,${n})`;
export const grey128W = (n) => `rgba(192,192,192,${n})`;
export const grey144W = (n) => `rgba(200,200,200,${n})`;
export const grey160W = (n) => `rgba(208,208,208,${n})`;
export const grey176W = (n) => `rgba(216,216,216,${n})`;
export const grey192W = (n) => `rgba(224,224,224,${n})`;
export const grey208W = (n) => `rgba(232,232,232,${n})`;
export const grey224W = (n) => `rgba(240,240,240,${n})`;
export const grey240W = (n) => `rgba(248,248,248,${n})`;

// COLOR RED with WHITE >>>>> STATIC COLORS

export const red016W = (n) => `rgba(196,76,76,${n})`;
export const red032W = (n) => `rgba(200,88,88,${n})`;
export const red048W = (n) => `rgba(204,100,100,${n})`;
export const red064W = (n) => `rgba(208,112,112,${n})`;
export const red080W = (n) => `rgba(212,124,124,${n})`;
export const red096W = (n) => `rgba(216,136,136,${n})`;
export const red112W = (n) => `rgba(220,148,148,${n})`;
export const red128W = (n) => `rgba(224,160,160,${n})`;
export const red144W = (n) => `rgba(228,172,172,${n})`;
export const red160W = (n) => `rgba(232,184,184,${n})`;
export const red176W = (n) => `rgba(236,196,196,${n})`;
export const red192W = (n) => `rgba(240,208,208,${n})`;
export const red208W = (n) => `rgba(244,220,220,${n})`;
export const red224W = (n) => `rgba(248,232,232,${n})`;
export const red240W = (n) => `rgba(252,244,244,${n})`;

// COLOR GREEN with WHITE >>>>> STATIC COLORS

export const green016W = (n) => `rgba(76,196,76,${n})`;
export const green032W = (n) => `rgba(88,200,88,${n})`;
export const green048W = (n) => `rgba(100,204,100,${n})`;
export const green064W = (n) => `rgba(112,208,112,${n})`;
export const green080W = (n) => `rgba(124,212,124,${n})`;
export const green096W = (n) => `rgba(136,216,136,${n})`;
export const green112W = (n) => `rgba(148,220,148,${n})`;
export const green128W = (n) => `rgba(160,224,160,${n})`;
export const green144W = (n) => `rgba(172,228,172,${n})`;
export const green160W = (n) => `rgba(184,232,184,${n})`;
export const green176W = (n) => `rgba(196,236,196,${n})`;
export const green192W = (n) => `rgba(208,240,208,${n})`;
export const green208W = (n) => `rgba(220,244,220,${n})`;
export const green224W = (n) => `rgba(232,248,232,${n})`;
export const green240W = (n) => `rgba(244,252,244,${n})`;

// COLOR ONE with WHITE >>>>> STATIC COLORS

export const blue016W = (n) => `rgba(76,136,196,${n})`;
export const blue032W = (n) => `rgba(88,144,200,${n})`;
export const blue048W = (n) => `rgba(100,152,204,${n})`;
export const blue064W = (n) => `rgba(112,160,208,${n})`;
export const blue080W = (n) => `rgba(124,168,212,${n})`;
export const blue096W = (n) => `rgba(136,176,216,${n})`;
export const blue112W = (n) => `rgba(148,184,220,${n})`;
export const blue128W = (n) => `rgba(160,192,224,${n})`;
export const blue144W = (n) => `rgba(172,200,228,${n})`;
export const blue160W = (n) => `rgba(184,208,232,${n})`;
export const blue176W = (n) => `rgba(196,216,236,${n})`;
export const blue192W = (n) => `rgba(208,224,240,${n})`;
export const blue208W = (n) => `rgba(220,232,244,${n})`;
export const blue224W = (n) => `rgba(232,240,248,${n})`;
export const blue240W = (n) => `rgba(244,248,252,${n})`;

// COLOR TWO with WHITE >>>>> STATIC COLORS

export const orange016W = (n) => `rgba(196,136,76,${n})`;
export const orange032W = (n) => `rgba(200,144,88,${n})`;
export const orange048W = (n) => `rgba(204,152,100,${n})`;
export const orange064W = (n) => `rgba(208,160,112,${n})`;
export const orange080W = (n) => `rgba(212,168,124,${n})`;
export const orange096W = (n) => `rgba(216,176,136,${n})`;
export const orange112W = (n) => `rgba(220,184,148,${n})`;
export const orange128W = (n) => `rgba(224,192,160,${n})`;
export const orange144W = (n) => `rgba(228,200,172,${n})`;
export const orange160W = (n) => `rgba(232,208,184,${n})`;
export const orange176W = (n) => `rgba(236,216,196,${n})`;
export const orange192W = (n) => `rgba(240,224,208,${n})`;
export const orange208W = (n) => `rgba(244,232,220,${n})`;
export const orange224W = (n) => `rgba(248,240,232,${n})`;
export const orange240W = (n) => `rgba(252,248,244,${n})`;

// COLOR THREE with WHITE >>>>> STATIC COLORS

export const pink016W = (n) => `rgba(196,76,136,${n})`;
export const pink032W = (n) => `rgba(200,88,144,${n})`;
export const pink048W = (n) => `rgba(204,100,152,${n})`;
export const pink064W = (n) => `rgba(208,112,160,${n})`;
export const pink080W = (n) => `rgba(212,124,168,${n})`;
export const pink096W = (n) => `rgba(216,136,176,${n})`;
export const pink112W = (n) => `rgba(220,148,184,${n})`;
export const pink128W = (n) => `rgba(224,160,192,${n})`;
export const pink144W = (n) => `rgba(228,172,200,${n})`;
export const pink160W = (n) => `rgba(232,184,208,${n})`;
export const pink176W = (n) => `rgba(236,196,216,${n})`;
export const pink192W = (n) => `rgba(240,208,224,${n})`;
export const pink208W = (n) => `rgba(244,220,232,${n})`;
export const pink224W = (n) => `rgba(248,232,240,${n})`;
export const pink240W = (n) => `rgba(252,244,248,${n})`;

// COLOR GREY with BLACK >>>>> DYNAMIC COLORS

export const grey016b = (n, isDark) => isDark ? grey016W(n) : grey016B(n);
export const grey032b = (n, isDark) => isDark ? grey032W(n) : grey032B(n);
export const grey048b = (n, isDark) => isDark ? grey048W(n) : grey048B(n);
export const grey064b = (n, isDark) => isDark ? grey064W(n) : grey064B(n);
export const grey080b = (n, isDark) => isDark ? grey080W(n) : grey080B(n);
export const grey096b = (n, isDark) => isDark ? grey096W(n) : grey096B(n);
export const grey112b = (n, isDark) => isDark ? grey112W(n) : grey112B(n);
export const grey128b = (n, isDark) => isDark ? grey128W(n) : grey128B(n);
export const grey144b = (n, isDark) => isDark ? grey144W(n) : grey144B(n);
export const grey160b = (n, isDark) => isDark ? grey160W(n) : grey160B(n);
export const grey176b = (n, isDark) => isDark ? grey176W(n) : grey176B(n);
export const grey192b = (n, isDark) => isDark ? grey192W(n) : grey192B(n);
export const grey208b = (n, isDark) => isDark ? grey208W(n) : grey208B(n);
export const grey224b = (n, isDark) => isDark ? grey224W(n) : grey224B(n);
export const grey240b = (n, isDark) => isDark ? grey240W(n) : grey240B(n);

// COLOR RED with BLACK >>>>> DYNAMIC COLORS

export const red016b = (n, isDark) => isDark ? red016W(n) : red016B(n);
export const red032b = (n, isDark) => isDark ? red032W(n) : red032B(n);
export const red048b = (n, isDark) => isDark ? red048W(n) : red048B(n);
export const red064b = (n, isDark) => isDark ? red064W(n) : red064B(n);
export const red080b = (n, isDark) => isDark ? red080W(n) : red080B(n);
export const red096b = (n, isDark) => isDark ? red096W(n) : red096B(n);
export const red112b = (n, isDark) => isDark ? red112W(n) : red112B(n);
export const red128b = (n, isDark) => isDark ? red128W(n) : red128B(n);
export const red144b = (n, isDark) => isDark ? red144W(n) : red144B(n);
export const red160b = (n, isDark) => isDark ? red160W(n) : red160B(n);
export const red176b = (n, isDark) => isDark ? red176W(n) : red176B(n);
export const red192b = (n, isDark) => isDark ? red192W(n) : red192B(n);
export const red208b = (n, isDark) => isDark ? red208W(n) : red208B(n);
export const red224b = (n, isDark) => isDark ? red224W(n) : red224B(n);
export const red240b = (n, isDark) => isDark ? red240W(n) : red240B(n);

// COLOR GREEN with BLACK >>>>> DYNAMIC COLORS

export const green016b = (n, isDark) => isDark ? green016W(n) : green016B(n);
export const green032b = (n, isDark) => isDark ? green032W(n) : green032B(n);
export const green048b = (n, isDark) => isDark ? green048W(n) : green048B(n);
export const green064b = (n, isDark) => isDark ? green064W(n) : green064B(n);
export const green080b = (n, isDark) => isDark ? green080W(n) : green080B(n);
export const green096b = (n, isDark) => isDark ? green096W(n) : green096B(n);
export const green112b = (n, isDark) => isDark ? green112W(n) : green112B(n);
export const green128b = (n, isDark) => isDark ? green128W(n) : green128B(n);
export const green144b = (n, isDark) => isDark ? green144W(n) : green144B(n);
export const green160b = (n, isDark) => isDark ? green160W(n) : green160B(n);
export const green176b = (n, isDark) => isDark ? green176W(n) : green176B(n);
export const green192b = (n, isDark) => isDark ? green192W(n) : green192B(n);
export const green208b = (n, isDark) => isDark ? green208W(n) : green208B(n);
export const green224b = (n, isDark) => isDark ? green224W(n) : green224B(n);
export const green240b = (n, isDark) => isDark ? green240W(n) : green240B(n);

// COLOR ONE with BLACK >>>>> DYNAMIC COLORS

export const blue016b = (n, isDark) => isDark ? blue016W(n) : blue016B(n);
export const blue032b = (n, isDark) => isDark ? blue032W(n) : blue032B(n);
export const blue048b = (n, isDark) => isDark ? blue048W(n) : blue048B(n);
export const blue064b = (n, isDark) => isDark ? blue064W(n) : blue064B(n);
export const blue080b = (n, isDark) => isDark ? blue080W(n) : blue080B(n);
export const blue096b = (n, isDark) => isDark ? blue096W(n) : blue096B(n);
export const blue112b = (n, isDark) => isDark ? blue112W(n) : blue112B(n);
export const blue128b = (n, isDark) => isDark ? blue128W(n) : blue128B(n);
export const blue144b = (n, isDark) => isDark ? blue144W(n) : blue144B(n);
export const blue160b = (n, isDark) => isDark ? blue160W(n) : blue160B(n);
export const blue176b = (n, isDark) => isDark ? blue176W(n) : blue176B(n);
export const blue192b = (n, isDark) => isDark ? blue192W(n) : blue192B(n);
export const blue208b = (n, isDark) => isDark ? blue208W(n) : blue208B(n);
export const blue224b = (n, isDark) => isDark ? blue224W(n) : blue224B(n);
export const blue240b = (n, isDark) => isDark ? blue240W(n) : blue240B(n);

// COLOR TWO with BLACK >>>>> DYNAMIC COLORS

export const orange016b = (n, isDark) => isDark ? orange016W(n) : orange016B(n);
export const orange032b = (n, isDark) => isDark ? orange032W(n) : orange032B(n);
export const orange048b = (n, isDark) => isDark ? orange048W(n) : orange048B(n);
export const orange064b = (n, isDark) => isDark ? orange064W(n) : orange064B(n);
export const orange080b = (n, isDark) => isDark ? orange080W(n) : orange080B(n);
export const orange096b = (n, isDark) => isDark ? orange096W(n) : orange096B(n);
export const orange112b = (n, isDark) => isDark ? orange112W(n) : orange112B(n);
export const orange128b = (n, isDark) => isDark ? orange128W(n) : orange128B(n);
export const orange144b = (n, isDark) => isDark ? orange144W(n) : orange144B(n);
export const orange160b = (n, isDark) => isDark ? orange160W(n) : orange160B(n);
export const orange176b = (n, isDark) => isDark ? orange176W(n) : orange176B(n);
export const orange192b = (n, isDark) => isDark ? orange192W(n) : orange192B(n);
export const orange208b = (n, isDark) => isDark ? orange208W(n) : orange208B(n);
export const orange224b = (n, isDark) => isDark ? orange224W(n) : orange224B(n);
export const orange240b = (n, isDark) => isDark ? orange240W(n) : orange240B(n);

// COLOR THREE with BLACK >>>>> DYNAMIC COLORS

export const pink016b = (n, isDark) => isDark ? pink016W(n) : pink016B(n);
export const pink032b = (n, isDark) => isDark ? pink032W(n) : pink032B(n);
export const pink048b = (n, isDark) => isDark ? pink048W(n) : pink048B(n);
export const pink064b = (n, isDark) => isDark ? pink064W(n) : pink064B(n);
export const pink080b = (n, isDark) => isDark ? pink080W(n) : pink080B(n);
export const pink096b = (n, isDark) => isDark ? pink096W(n) : pink096B(n);
export const pink112b = (n, isDark) => isDark ? pink112W(n) : pink112B(n);
export const pink128b = (n, isDark) => isDark ? pink128W(n) : pink128B(n);
export const pink144b = (n, isDark) => isDark ? pink144W(n) : pink144B(n);
export const pink160b = (n, isDark) => isDark ? pink160W(n) : pink160B(n);
export const pink176b = (n, isDark) => isDark ? pink176W(n) : pink176B(n);
export const pink192b = (n, isDark) => isDark ? pink192W(n) : pink192B(n);
export const pink208b = (n, isDark) => isDark ? pink208W(n) : pink208B(n);
export const pink224b = (n, isDark) => isDark ? pink224W(n) : pink224B(n);
export const pink240b = (n, isDark) => isDark ? pink240W(n) : pink240B(n);

// COLOR GREY with WHITE >>>>> DYNAMIC COLORS

export const grey016w = (n, isDark) => isDark ? grey016B(n) : grey016W(n);
export const grey032w = (n, isDark) => isDark ? grey032B(n) : grey032W(n);
export const grey048w = (n, isDark) => isDark ? grey048B(n) : grey048W(n);
export const grey064w = (n, isDark) => isDark ? grey064B(n) : grey064W(n);
export const grey080w = (n, isDark) => isDark ? grey080B(n) : grey080W(n);
export const grey096w = (n, isDark) => isDark ? grey096B(n) : grey096W(n);
export const grey112w = (n, isDark) => isDark ? grey112B(n) : grey112W(n);
export const grey128w = (n, isDark) => isDark ? grey128B(n) : grey128W(n);
export const grey144w = (n, isDark) => isDark ? grey144B(n) : grey144W(n);
export const grey160w = (n, isDark) => isDark ? grey160B(n) : grey160W(n);
export const grey176w = (n, isDark) => isDark ? grey176B(n) : grey176W(n);
export const grey192w = (n, isDark) => isDark ? grey192B(n) : grey192W(n);
export const grey208w = (n, isDark) => isDark ? grey208B(n) : grey208W(n);
export const grey224w = (n, isDark) => isDark ? grey224B(n) : grey224W(n);
export const grey240w = (n, isDark) => isDark ? grey240B(n) : grey240W(n);

// COLOR RED with WHITE >>>>> DYNAMIC COLORS

export const red016w = (n, isDark) => isDark ? red016B(n) : red016W(n);
export const red032w = (n, isDark) => isDark ? red032B(n) : red032W(n);
export const red048w = (n, isDark) => isDark ? red048B(n) : red048W(n);
export const red064w = (n, isDark) => isDark ? red064B(n) : red064W(n);
export const red080w = (n, isDark) => isDark ? red080B(n) : red080W(n);
export const red096w = (n, isDark) => isDark ? red096B(n) : red096W(n);
export const red112w = (n, isDark) => isDark ? red112B(n) : red112W(n);
export const red128w = (n, isDark) => isDark ? red128B(n) : red128W(n);
export const red144w = (n, isDark) => isDark ? red144B(n) : red144W(n);
export const red160w = (n, isDark) => isDark ? red160B(n) : red160W(n);
export const red176w = (n, isDark) => isDark ? red176B(n) : red176W(n);
export const red192w = (n, isDark) => isDark ? red192B(n) : red192W(n);
export const red208w = (n, isDark) => isDark ? red208B(n) : red208W(n);
export const red224w = (n, isDark) => isDark ? red224B(n) : red224W(n);
export const red240w = (n, isDark) => isDark ? red240B(n) : red240W(n);

// COLOR GREEN with WHITE >>>>> DYNAMIC COLORS

export const green016w = (n, isDark) => isDark ? green016B(n) : green016W(n);
export const green032w = (n, isDark) => isDark ? green032B(n) : green032W(n);
export const green048w = (n, isDark) => isDark ? green048B(n) : green048W(n);
export const green064w = (n, isDark) => isDark ? green064B(n) : green064W(n);
export const green080w = (n, isDark) => isDark ? green080B(n) : green080W(n);
export const green096w = (n, isDark) => isDark ? green096B(n) : green096W(n);
export const green112w = (n, isDark) => isDark ? green112B(n) : green112W(n);
export const green128w = (n, isDark) => isDark ? green128B(n) : green128W(n);
export const green144w = (n, isDark) => isDark ? green144B(n) : green144W(n);
export const green160w = (n, isDark) => isDark ? green160B(n) : green160W(n);
export const green176w = (n, isDark) => isDark ? green176B(n) : green176W(n);
export const green192w = (n, isDark) => isDark ? green192B(n) : green192W(n);
export const green208w = (n, isDark) => isDark ? green208B(n) : green208W(n);
export const green224w = (n, isDark) => isDark ? green224B(n) : green224W(n);
export const green240w = (n, isDark) => isDark ? green240B(n) : green240W(n);

// COLOR ONE with WHITE >>>>> DYNAMIC COLORS

export const blue016w = (n, isDark) => isDark ? blue016B(n) : blue016W(n);
export const blue032w = (n, isDark) => isDark ? blue032B(n) : blue032W(n);
export const blue048w = (n, isDark) => isDark ? blue048B(n) : blue048W(n);
export const blue064w = (n, isDark) => isDark ? blue064B(n) : blue064W(n);
export const blue080w = (n, isDark) => isDark ? blue080B(n) : blue080W(n);
export const blue096w = (n, isDark) => isDark ? blue096B(n) : blue096W(n);
export const blue112w = (n, isDark) => isDark ? blue112B(n) : blue112W(n);
export const blue128w = (n, isDark) => isDark ? blue128B(n) : blue128W(n);
export const blue144w = (n, isDark) => isDark ? blue144B(n) : blue144W(n);
export const blue160w = (n, isDark) => isDark ? blue160B(n) : blue160W(n);
export const blue176w = (n, isDark) => isDark ? blue176B(n) : blue176W(n);
export const blue192w = (n, isDark) => isDark ? blue192B(n) : blue192W(n);
export const blue208w = (n, isDark) => isDark ? blue208B(n) : blue208W(n);
export const blue224w = (n, isDark) => isDark ? blue224B(n) : blue224W(n);
export const blue240w = (n, isDark) => isDark ? blue240B(n) : blue240W(n);

// COLOR TWO with WHITE >>>>> DYNAMIC COLORS

export const orange016w = (n, isDark) => isDark ? orange016B(n) : orange016W(n);
export const orange032w = (n, isDark) => isDark ? orange032B(n) : orange032W(n);
export const orange048w = (n, isDark) => isDark ? orange048B(n) : orange048W(n);
export const orange064w = (n, isDark) => isDark ? orange064B(n) : orange064W(n);
export const orange080w = (n, isDark) => isDark ? orange080B(n) : orange080W(n);
export const orange096w = (n, isDark) => isDark ? orange096B(n) : orange096W(n);
export const orange112w = (n, isDark) => isDark ? orange112B(n) : orange112W(n);
export const orange128w = (n, isDark) => isDark ? orange128B(n) : orange128W(n);
export const orange144w = (n, isDark) => isDark ? orange144B(n) : orange144W(n);
export const orange160w = (n, isDark) => isDark ? orange160B(n) : orange160W(n);
export const orange176w = (n, isDark) => isDark ? orange176B(n) : orange176W(n);
export const orange192w = (n, isDark) => isDark ? orange192B(n) : orange192W(n);
export const orange208w = (n, isDark) => isDark ? orange208B(n) : orange208W(n);
export const orange224w = (n, isDark) => isDark ? orange224B(n) : orange224W(n);
export const orange240w = (n, isDark) => isDark ? orange240B(n) : orange240W(n);

// COLOR THREE with WHITE >>>>> DYNAMIC COLORS

export const pink016w = (n, isDark) => isDark ? pink016B(n) : pink016W(n);
export const pink032w = (n, isDark) => isDark ? pink032B(n) : pink032W(n);
export const pink048w = (n, isDark) => isDark ? pink048B(n) : pink048W(n);
export const pink064w = (n, isDark) => isDark ? pink064B(n) : pink064W(n);
export const pink080w = (n, isDark) => isDark ? pink080B(n) : pink080W(n);
export const pink096w = (n, isDark) => isDark ? pink096B(n) : pink096W(n);
export const pink112w = (n, isDark) => isDark ? pink112B(n) : pink112W(n);
export const pink128w = (n, isDark) => isDark ? pink128B(n) : pink128W(n);
export const pink144w = (n, isDark) => isDark ? pink144B(n) : pink144W(n);
export const pink160w = (n, isDark) => isDark ? pink160B(n) : pink160W(n);
export const pink176w = (n, isDark) => isDark ? pink176B(n) : pink176W(n);
export const pink192w = (n, isDark) => isDark ? pink192B(n) : pink192W(n);
export const pink208w = (n, isDark) => isDark ? pink208B(n) : pink208W(n);
export const pink224w = (n, isDark) => isDark ? pink224B(n) : pink224W(n);
export const pink240w = (n, isDark) => isDark ? pink240B(n) : pink240W(n);