import { store } from '../../root';

import * as clone from './abs/clone.js';
import * as operation from './abs/operation.js';

import bond from './abs/bond.js';
import generate from './abs/generate.js';
import popover from './abs/popover.js';

export { bond, clone, generate, operation, popover };

export const childOf = (typename) => {

    const element = {
        Set: 'Book',
        Book: 'Section',
        Section:'Page',
        Page:'Block',
        Block: 'Data',
        Data: null,
    };
    return element[typename];
};

export const parentOf = (typename) => {

    const element = {
        Set: null,
        Book: 'Set',
        Section:'Book',
        Page:'Section',
        Block: 'Page',
        Data: 'Block',
    };
    return element[typename];
};

export const svg = ({typename, type='', isChild}) => {

    if (isChild) typename=childOf(typename);

    const svgs = {
        Book: 'book_16',
        Section: 'S_16_line_24',
        Page: 'P_16_fill_24',
        Block: 'B_16_line_24',
        Data: 'D_16',

        bookBook: 'book_16',
        blockBlock: 'block_16',

        textData: 'text_16',
        codeData: 'code_16',
        mathData: 'math_16',
        linkData: 'link_16',
        urlData: 'placeholder_16',
        pictureData: 'picture_16',
        motionData: 'placeholder_16',
        audioData: 'placeholder_16',
        videoData: 'placeholder_16', 

    };
    return svgs[`${type}${typename}`] || 'placeholder_16_fill_24'; 
};

export const isUrlBooks = (url) => url.split('/')[3]==='books';
export const isUrlBooksOverview = (url) => url.split('/')[4] === 'overview';
export const isUrlBooksList= (url) => url.split('/')[4] === 'list';
export const isUrlBooksPublications = (url) => url.split('/')[4] === 'publications';
export const isUrlBooksBookmarks = (url) => url.split('/')[4] === 'bookmarks';

export const isUrlBook = (url) => url.split('/')[3] === 'book';
export const isUrlCover = (url) => url.split('/')[5] === 'cover';
export const isUrlIndex = (url) => url.split('/')[5] === 'index';
export const isUrlPage = (url) => url.split('/')[8] === 'page';

export const params = (url) => {

    const urlSplit = url.split('/');
    
    const result = {
        url,
        isBooks: urlSplit[3] === 'books',
        isPictures: urlSplit[3] === 'pictures',
        isBook: urlSplit[3] === 'book',
        isCover: urlSplit[5] === 'cover',
        isIndex: urlSplit[5] === 'index',
        isPage: urlSplit[8] === 'page',
    };
    
    result.setId = urlSplit[2];
    
    if (result.isBook) {
        result.bookItemId = urlSplit[4];
        if (result.isPage) {
            result.sectionHookId = urlSplit[6];
            result.sectionItemId = urlSplit[7];
            result.pageHookId = urlSplit[9];
            result.pageItemId = urlSplit[10];
        };
    };
    return result;
};

export const link = (type, params) => {

    const { 
        setId, 
        bookItemId, 
        sectionHookId, 
        sectionItemId,
        pageHookId, 
        pageItemId,
        // blockHookId, 
        // blockItemId,
        // dataHookId, 
        // dataItemId,
    } = params;

    const links = {
        overview: `/set/${setId}/books/overview`, 
        books: `/set/${setId}/books/list`, 
        publications: `/set/${setId}/books/publications`, 
        bookmarks: `/set/${setId}/books/bookmarks`, 
        cover: `/set/${setId}/book/${bookItemId}/cover`,
        index: `/set/${setId}/book/${bookItemId}/index`,
        page: `/set/${setId}/book/${bookItemId}/section/${sectionHookId}/${sectionItemId}/page/${pageHookId}/${pageItemId}`,
    };

    return links[type];

};














export const isFetchSet = () => store.router.operation === 'fetchSet';
export const isFetchBook = () => store.router.operation === 'fetchBook';
export const isFetchPage = () => store.router.operation === 'fetchPage';

export const isReadFetchBook = (url) => isFetchBook(url) || !isFetchSet(url) && isUrlBook(url);
