import { View } from 'react-native';
import { s, use } from '../../../../root';
import { Pop } from '../../../../modules/popover/components'
import { Picture } from '../../../../modules/basics/components';

const PictureItem = ({ src, placeholder, options }) => {

    const c = use.useColor();

    const style = {
        box:        [s.row_eXsY, {flex:1, aspectRatio:1, border:0}],
        picture:    {flex:1, aspectRatio:1},
        popBox:     [s.row_cXcY, s.X48Y40, {position:'absolute', zIndex:1, marginInlineStart:'auto'}],
        pop:        [s.XY24, s.radius.r16, c.mainN1_o],
    };

    return (
        <View style={style.box}>
            <View style={style.popBox}>
                <Pop style={style.pop} color={c.onMainN1} svg='optionsVertical_16' popover={options}/>
            </View>
            <Picture 
                src={src}
                placeholder={placeholder}
                style={style.picture}
                // transition={}
            />
        </View>
    );
    
};
export default PictureItem;