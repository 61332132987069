import { Titlebar, Icon } from '../../../../modules/basics/components';
import { Pop } from '../../../../modules/popover/components';
import { s, use } from '../../../../root';

const PodTitlebar = ({ id, title, backTo, options, adder }) => {

    const c = use.useColor();

    const style = {
        logo: [s.X48Y40],
        icon: [s.X32Y40],
    };

    // const one = <Icon style={style.logo} color={c.onMainN1} svg='checkboxFalse_16'/>
    const popOptions = <Pop style={style.icon} color={c.onMainN1} svg='optionsVertical_16' popover={options}/>
    const popAdder = <Pop style={style.icon} color={c.onMainN1} svg='plus_14_line_24' popover={adder}/>

    return (
        <Titlebar 
            id={id} 
            backTo={backTo} 
            title={title}
            actions={<>{options?.name ? popOptions:null}{adder?.name ? popAdder:null}</>}
        />)
};
export default PodTitlebar;