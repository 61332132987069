import { View, Pressable } from 'react-native';

const Button = ({ style=[], color, onPress, children }) => {
    
    return (
        <Pressable onPress={onPress}>
            <View style={[...style, color]}>{children}</View>
        </Pressable>
    );
};
export default Button;