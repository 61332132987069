import { useSetRecoilState } from 'recoil';
import { FlatList, Pressable } from 'react-native';
import { s, use, store } from '../../../root';
import { Textline } from '../../../modules/basics/components';
import * as localStorage from '../../../platform/localStorage';

const Language = ({ close }) => {

    const c = use.useColor();

    const setLanguage = useSetRecoilState(store.layout.language);
    const setIsRTL = useSetRecoilState(store.layout.isRTL);

    const langs = Object.keys(store.basics.languages);

    const style = {
        page: [s.col, s.Yc],
        item: [s.row_sXcY, s.Y48, s.space.pS16],
        text: [s.row_sXcY, { fontWeight:'bold' }],
    };

    const renderItem = ({ item }) => {

        const lang = store.basics.languages[item];

        const onSelectLanguage = () => {
            localStorage.set('language', lang.code);
            localStorage.set('isRTL', lang.isRTL);
            setLanguage(lang.code);
            setIsRTL(lang.isRTL);
            if (close) close()
        };

        return (
            <Pressable style={style.item} key={item} onPress={onSelectLanguage}>
                <Textline style={style.text} color={c.onMainN1} WL text={lang.name}/>
            </Pressable>
        );
    };

    return <FlatList style={style.page} data={langs} renderItem={renderItem}/>
}; 
export default Language;