import { Outlet } from '../../../../platform/router';
import { useRecoilValue } from 'recoil';
import { View } from 'react-native';

import { s, api, use, store, menus } from '../../../../root';
import { Alert } from '../../../../modules/alert/components';
import { Popover } from '../../../../modules/popover/components';
import { Modals } from '../../../../modules/modal/components';
import { Userbar, Auth } from '../../../../modules/user/components';
import { ErrorMain } from '../../../../modules/error/pages';

import Agent from '../Agent';

import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';

const Layout = () => {  

    const c = use.useColor();
    
    const isRTL = useRecoilValue(store.layout.isRTL);
    const direction = isRTL ? 'rtl' : 'ltr';

    const isFixed = useRecoilValue(store.layout.isFixed);
    const isOversize = useRecoilValue(store.layout.isOversize);
    const url = useRecoilValue(store.router.url);

    const user = useRecoilValue(api.user.user);
    const error = useRecoilValue(store.error.main);
    const isError = error?.name;

    const setResize = use.layout.useSetResize();
    setResize

    const welcome = use.cookies.useWelcome();
    welcome

    const menuOne = menus.one(url,user?.id);
    const menuTwo = menus.two(url,user?.id);

    const style = {
        root:   [s.fixed, s.col, s.XY, c.barN1],
        layout: [s.fixed, s.col, s.XY, s.space.pX8, c.barN1],
        body:   [s.row, s.X, s.Yc, s.space.between],
        main:   [s.col, s.XYr, s.radius.r16, c.mainN1, isFixed && isOversize ? s.X960max : null],
    };

    return (
        <View dir={direction} style={style.root}>  
            <Agent/>
            <Alert/>
            <Popover/>
            <Modals/>
            <Userbar/>
            <Sidebar menu={menuOne} isSidebarOne/>
            <Sidebar menu={menuTwo}/>
            <View style={style.layout}>
                <Header auth={<Auth/>} menuOne={menuOne} menuTwo={menuTwo}/>
                <View style={style.body}>
                    <Sidebar menu={menuOne} isInternal isSidebarOne/> 
                    <View style={style.main}>
                        {isError ? <ErrorMain/> : <Outlet/>}
                    </View>
                    <Sidebar menu={menuTwo} isInternal/>
                </View>
                <Footer/>
            </View>
        </View>
    );
};
export default Layout;