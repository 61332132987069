import { Modal } from '../../../modules/modal/components';
import { LanguagePage } from '../../../modules/layout/pages';

const Language = ({id}) => {

    const title = 'WL_layout_language';
    const svg = 'language_16'; 

    return (
        <Modal id={id} title={title} svg={svg}>
            <LanguagePage close={close}/>
        </Modal>
    )
}; 
export default Language;