import * as AuthSession from 'expo-auth-session';
import jwt_decode from 'jwt-decode';
import { abs, store } from '../../../root';
import * as localStorage from '../../../platform/localStorage';

const refetchToken = async () => {

    try {

        const { accessToken, refreshToken } = store.user;

        if (accessToken) {
            const date = (Date.now()/1000) | 0;
            const accessTokenDecoded = jwt_decode(accessToken);
            const accessTokenTimeLeft = accessTokenDecoded.exp - date;
            if (accessTokenTimeLeft > 20) return accessToken;
        };

        if (refreshToken) {

            const date = (Date.now()/1000) | 0;
            const refreshTokenDecoded = jwt_decode(refreshToken);
            const refreshTokenTimeLeft = refreshTokenDecoded.exp - date;

            if (refreshTokenTimeLeft > 20) {

                if (!(await abs.isNetwork())) abs.errorThrow('if (!(await abs.isNetwork()))');
                const token = await AuthSession.refreshAsync({refreshToken, ...store.user.params}, store.user.discovery);

                await localStorage.set('accessToken', token.accessToken);
                await localStorage.set('refreshToken', token.refreshToken);
                store.user.accessToken = token.accessToken;
                store.user.refreshToken = token.refreshToken;
                return token.accessToken;

            };
        };

        abs.errorThrow('(accessTokenTimeLeft < 20) && (refreshTokenTimeLeft < 20)');

    } catch (error) {

        // the error returns null because it will be thrown in the operation
        
        abs.errorCatch('App/modules/user/abs/refetchToken', error);

        if (await abs.isNetwork()) {

            await localStorage.remove('accessToken');
            await localStorage.remove('refreshToken');
            store.user.accessToken = '';
            store.user.refreshToken = '';
            return null

        } else return null

    };
};

export default refetchToken;