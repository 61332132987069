import { useRecoilValue } from 'recoil';
import { s, store } from '../../../root';

const useSetMargin = () => {

    const size = useRecoilValue(store.layout.size);
    const isSideOneOn = useRecoilValue(store.layout.isSideOneOn);
    const isSideTwoOn = useRecoilValue(store.layout.isSideTwoOn);

    const setMargin = () => {        

        const isSize1 = size === '1';
        const isSize2 = size === '2';
        const isSize3 = size === '3';

        if (isSize1) return s.space.mX56;

        if (isSize2 && isSideOneOn) return s.space.mS376E56;
        if (isSize2 && !isSideOneOn) return s.space.mX56;

        if (isSize3 && isSideOneOn && isSideTwoOn) return s.space.mX376;
        if (isSize3 && isSideOneOn && !isSideTwoOn) return s.space.mS376E56;
        if (isSize3 && !isSideOneOn && isSideTwoOn) return s.space.mS56E376;
        if (isSize3 && !isSideOneOn && !isSideTwoOn) return s.space.mX56;

    };

    return setMargin;
};
export default useSetMargin;