import { abs, api } from '../../../../root';
import { ThrowErrorMain } from '../../../../modules/error/throw';
import { PageEmpty } from '../../../../app/book/suits';
import Block from './Block.js';

const Blocks = ({ svg, typename, params, block }) => {

    const fetchList = api.book.useFetch(block.list);
    const list = fetchList();

    if (abs.isError(list)) return <ThrowErrorMain name='bookPage' data={block.list}/>
    if (abs.isEmpty(list)) return <PageEmpty typename={typename} svg={svg} isOptions isAdder/>
    if (!abs.isArray(list)) return null;

    return (<>
        {list.map((current, index) => {
            const { pageHookId } = params;
            const blockHookId = current.hookId;
            const blockItemId = current.itemId;
            const hook = {typename, type:'hook', id:blockHookId, fetchId:pageHookId};
            const item = {typename, type:'item', id:blockItemId, fetchId:pageHookId};
            return (
                <Block
                    svg={svg}
                    typename={typename}
                    params={{...params, blockHookId, blockItemId}}
                    block={{list:block.list, hook, item}}
                    blockPosition={index+1}
                    key={current.hookId} 
                />
            );
        })}
    </>);
}; 
export default Blocks;