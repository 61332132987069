import { View, Pressable } from 'react-native';
import { s, abs, use } from '../../../root';
import { Textline } from '../../../modules/basics/components';
import { UserLink } from '../../../modules/basics/menus';

const Menu = ({ isGuest, url }) => {

    const c = use.useColor();

    const onCreateAccount = () => {
        console.log('create account')
    };

    const onManageAccount = () => {
        console.log('manage account')
    };

    const style = {
        menu:   [s.col, s.Xc],
        item:   [s.row_cXcY, s.Y40, s.Xc],
        text:   [{fontWeight:'bold'}],
    };

    return (
        <View style={style.menu}>
            {isGuest 
            ?
            <Pressable style={style.item} onPress={onCreateAccount}>
                <Textline style={style.text} color={c.onMainN1} WL text='WL_user_createAccount'/>
            </Pressable>
            :
            <Pressable style={style.item} onPress={onManageAccount}>
                <Textline style={style.text} color={c.onMainN1} WL text='WL_user_manageAccount'/>
            </Pressable>
            }
            <UserLink isOnMain
                svg='book_16' 
                WL text='WL_user_cookies' 
                linkTo={`/legal/cookies`} 
                isActive={abs.user.isUrlCookies(url)}
            />
            <UserLink isOnMain
                svg='book_16' 
                WL text='WL_user_privacyPolicy' 
                linkTo={`/legal/privacy`} 
                isActive={abs.user.isUrlPrivacy(url)}
            />
            <UserLink isOnMain
                svg='book_16' 
                WL text='WL_user_termsOfService' 
                linkTo={`/legal/terms`} 
                isActive={abs.user.isUrlTerms(url)}
            />
        </View>
    );
}; 
export default Menu;







// fare is active