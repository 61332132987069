import { abs } from '../../../../root';
import { MenuLink } from '../../../../modules/basics/menus';
import { MenuEmpty } from '../../../../app/book/suits.js';

const LoadingBook = ({ url, setId, bookItemId, isError }) => {

    return (
        <>
            <MenuLink 
                svg='back_16' 
                WL text='WL_book_myBooks' 
                linkTo={`/set/${setId}/books/overview`} 
            />
            <MenuLink 
                svg='book_16' 
                WL text={'WL_book_cover'}
                linkTo={`/set/${setId}/book/${bookItemId}/cover`} 
                isActive={abs.book.isUrlCover(url)}
            />
            <MenuLink 
                svg='index_16' 
                WL text='WL_book_index' 
                linkTo={`/set/${setId}/book/${bookItemId}/index`} 
                isActive={abs.book.isUrlIndex(url)}
            />
            {isError ? null : <MenuEmpty typename={'Section'}/>}
        </>
    );
}; 
export default LoadingBook;