import Constants from 'expo-constants';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { env, abs, store } from '../../../../root';
import { AgentRemove } from '../../../../app/picture/agents.js';

const RemovePicture = () => {

    const { MEDIA_API_BASE_URL } = Constants.expoConfig.extra

    // get the data needed from the popover
    const popover = useRecoilValue(store.popover.popover)
    const { picture, item } = popover;

    // operationId, when set, triggers the Agent and run the operation
    const [operationId, setOperationId] = useState('');

    // onPress, create the operation and set the operationId to render the Agent
    const remove = () => {
        const input = {
            method:'DELETE',
            url:`${MEDIA_API_BASE_URL}/picture/delete?ids=${item.id}`,
        };
        const source = { name:'popover' }
        const operation = 'remove';
        const generatedId = abs.picture.operation.add({operation, input, list:picture.list, source});
        setOperationId(generatedId);
    };

    const refetch = {refetch:'reorder', operationId, isRemove:true};
    
    return <AgentRemove operationId={operationId} action={remove} refetch={refetch}/>

    
};
export default RemovePicture;